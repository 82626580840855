import React from "react";

import "./ReportRowABTests.scss";

type ReportRowABTestsProps = {
  topAuthors: Array<any>;
  topTests: Array<any>;
};

const ReportRowABTests = ({ topAuthors, topTests }: ReportRowABTestsProps) => {
  return (
    <div className="report-row-ab-tests-container">
      <div className="title">AB tests</div>
      <div className="lists-container">
        <div className="authors-list">
          <p className="list-title">top 5 AB test authors</p>
          {topAuthors?.map((item) => {
            return (
              <div className="list-item">
                <div className="author-name">{item.name}</div>
                <div className="number-of-tests">
                  {item.testsNumber} tests
                  <div className="arrow-up green"></div>
                  <span>{item.growthPercentage}%</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="top-tests-list">
          <p className="list-title">top 5 AB tests (most increase)</p>
          {topTests?.map((item) => {
            return (
              <div className="list-item">
                <div className="title">{item.title}</div>
                <div className="views">
                  +{item.views} <span>views</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ReportRowABTests;
