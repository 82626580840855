import React, { useEffect, useState, useMemo, useRef } from "react";
import Button from "../common/Button";

type Props = {
  children: any;
};

const TableCell = ({ children: text, ...restOfProps }: Props) => {
  const [showMoreDisplayed, setShowMoreDisplayed] = useState(false);
  const [showFullContent, setShowFullContent] = useState(true);

  const [scrollTop, setScrollTop] = useState(0);

  const refTest = useRef<HTMLTableCellElement>(null);

  useEffect(() => {
    if (
      // @ts-ignore
      (React.isValidElement(text) && text.props?.children?.length > 4) ||
      (Array.isArray(text) && text.length > 3) ||
      text?.toLocaleString().length > 100
    ) {
      setShowMoreDisplayed(true);
    }
  }, [text]);

  const handleShowMoreClick = () => {
    setShowFullContent(!showFullContent);
  };

  const shortText = useMemo(() => {
    if (React.isValidElement(text)) {
      // @ts-ignore
      return { ...text, props: { ...text.props, children: text.props?.children?.toString().slice(0, 3) } };
    } else if (Array.isArray(text)) {
      return text.slice(0, 3);
    } else {
      return text?.toLocaleString().slice(0, 100) || "";
    }
  }, [text]);

  return (
    <>
      <td {...restOfProps} ref={refTest} style={{ overflowWrap: "break-word", position: "relative" }}>
        {showMoreDisplayed ? (
          showFullContent ? (
            text
          ) : (
            shortText
          )
        ) : (
          <div
            onScroll={() => {
              setScrollTop(refTest.current?.getBoundingClientRect().top || 0);
            }}
            style={{
              position: "sticky",
              top: scrollTop,
              zIndex: 1,
            }}
          >
            {text}
          </div>
        )}
        {/* {showMoreDisplayed && (
          <div style={{ marginTop: 15 }}>
            <Button
              variant="default"
              style={{ border: "1px solid gainsboro" }}
              onClick={() => {
                handleShowMoreClick();
              }}
            >
              {showFullContent ? "show less" : "show more"}
            </Button>
          </div>
        )} */}
      </td>
    </>
  );
};

export default TableCell;
