import React, { useState, useEffect, useMemo } from "react";
import MainNavigation from "../../components/navigation/MainNavigation";
import Table from "../../components/ui/table/Table";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import Button from "../../components/ui/common/Button";
import Modal from "../../components/ui/modal/Modal";
import { EditIcon, DeleteIcon } from "../../icons/Icons";
import { PlusIcon } from "../../icons/Icons";
import { fetchBrands } from "../../store/slices/brands";
import { fetchUmbrellaBrands, deleteUmbrellaBrand } from "../../store/slices/umbrellaBrands";
import EditUmbrellaBrandsModal from "../../components/modals/EditUmbrellaBrandsModal/EditUmbrellaBrandsModal";
import { CircleLoader } from "../../icons/Loaders";

const UmbrellaBrands = () => {
  const dispatch = useAppDispatch();
  const brands = useSelector((state: any) => state.brands.data);
  const umbrellaBrands = useSelector((state: any) => state.umbrellaBrands.data);
  const isLoading = useSelector((state: any) => state.umbrellaBrands.isLoading);
  
  const [editingUmbrellaBrand, setEditingUmbrellaBrand] = useState(false);
  const [umbrellaBrandEditData, setUmbrellaBrandEditData] = useState({});
  const [creatingNewUmbrellaBrand, setCreatingNewUmbrellaBrand] = useState(false);

  useEffect(() => {
    dispatch(fetchBrands());
    dispatch(fetchUmbrellaBrands());
  }, [dispatch]);

  useEffect(() => {
    document.title = "Admin - Umbrella Brands - Smartocto real-time";
  }, []);

  const handleEdit = (brand) => {
    setEditingUmbrellaBrand(true);
    setUmbrellaBrandEditData(brand);
  };

  const handleDelete = (brandId) => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteUmbrellaBrand(brandId));
    }
  };

  const columns = useMemo(() => {
    return [
      { title: "id", label: "Id" },
      { title: "name", label: "Name" },
      { title: "brands", label: "Brands" },
      { title: "actions", label: "" },
    ];
  }, []);

  const handleSetNewUmbrellaBrandCreation = () => {
    setCreatingNewUmbrellaBrand(true);
    setUmbrellaBrandEditData({});
    setEditingUmbrellaBrand(true);
  };

  const handleModalClose = () => {
    setCreatingNewUmbrellaBrand(false);
    setEditingUmbrellaBrand(false);
  };

  const dataWithActions = useMemo(() => {
    return umbrellaBrands.map((e: any, i: number) => {
      return {
        ...e,
        sort: { id: e.id, name: e.name, brands: Array.isArray(e.brands) ? e.brands.join(" ") : "" },
        brands: (
          <>
            {e.brands.map((brand) => {
              // check if brand exists in brands if not return brandname with " (inactive)"
              const brandExists = brands.find((b) => b.id === brand);
              if (!brandExists) {
                return brand + " -->(inactive) ";
              } else {
                return brand + " ";
              }
            })}
          </>
        ),
        actions: (
          <div className="table-actions-container">
            <Button
              data-testid={`editButton${i}`}
              size="small"
              title="Edit User"
              style={{ marginLeft: "10px", marginRight: "10px" }}
              variant="warning"
              onClick={() => {
                handleEdit(e);
              }}
            >
              <EditIcon />
            </Button>
            <Button
              data-testid={`editButton${i}`}
              size="small"
              title="Delete User"
              variant="danger"
              onClick={() => {
                handleDelete(e.id);
              }}
            >
              <DeleteIcon />
            </Button>
          </div>
        ),
      };
    });
  }, [umbrellaBrands, brands]);

  return (
    <>
      <Modal isOpen={editingUmbrellaBrand} onClose={handleModalClose}>
        <EditUmbrellaBrandsModal
          data={umbrellaBrandEditData}
          handleClose={handleModalClose}
          newUmbrellaBrand={creatingNewUmbrellaBrand}
        />
      </Modal>

      <MainNavigation />
      <div className="page-container-no-side-nav">
        <h1 className="admin-page-title">Umbrella Brands </h1>

        <div className="admin-page-header-btns">
          <Button
            style={{ marginLeft: 20, padding: "6px 8px" }}
            variant="success"
            onClick={() => {
              handleSetNewUmbrellaBrandCreation();
            }}
          >
            <PlusIcon width="16px" height="16px" />
          </Button>
        </div>

        {!isLoading ? (
          <div className="table-container">
            <Table showRowNumbers={true} rowData={dataWithActions} columnsData={columns} />
          </div>
        ) : (
          <div className="loader-container">
            <CircleLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default UmbrellaBrands;
