import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  isPosting: boolean;
  error: Object | null;
};

const inked = createSlice({
  name: "inked",
  initialState: {
    isPosting: true,
    error: null,
  },
  reducers: {
    saveInkedStart: (state: State) => {
      state.isPosting = true;
    },
    saveInkedSuccess: (state: State, action) => {
      state.isPosting = false;
    },
    saveInkedFailed: (state: State, action) => {
      state.isPosting = false;
      state.error = action.payload;
    },
  },
});

export default inked.reducer;

const { saveInkedStart, saveInkedSuccess, saveInkedFailed } = inked.actions;

export const saveNotificationAsInked =
  (id: string, action: string, dispatchFunction?: Function) => async (dispatch: Function) => {
    try {
      dispatch(saveInkedStart());
      const api = new Api();
      const result = await api.postNotificationAsInked(id, action);
      if (dispatchFunction) {
        dispatch(dispatchFunction(result));
      }
      dispatch(saveInkedSuccess(result));
    } catch (err) {
      dispatch(saveInkedFailed(err));
    }
  };
