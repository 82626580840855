import React, { ComponentPropsWithoutRef, useEffect, useState } from "react";
import "./TextArea.scss";

interface TextAreaProps extends ComponentPropsWithoutRef<"textarea"> {
  label?: string;
  children?: any;
  validateCodeEdit?: boolean;
}

const TextArea = ({ label, children, validateCodeEdit, ...restOfProps }: TextAreaProps) => {
  const [codeEditValid, setCodeEditValid] = useState(false);

  useEffect(() => {
    try {
      JSON.parse(children.substring(1, children.length - 1));
      setCodeEditValid(true);
    } catch {
      console.log();
      setCodeEditValid(false);
    }
  }, [children]);

  return (
    <div className="text-area-wrapper">
      <p>{label}</p>
      <textarea value={children || ""} {...restOfProps}></textarea>
      {validateCodeEdit && <div className={["code-edit-valid", `${codeEditValid && "true"}`].join(" ")}></div>}
    </div>
  );
};

export default TextArea;
