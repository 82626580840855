import React from "react";
import { CircleLoader } from "../../icons/Loaders";

import "./StoryValueEngine.scss";

type StoryValueEngineProps = {
  reach?: number,
  socialScore?: number,
  author?: string,
  section?: string,
  loading?: boolean,
};

const StoryValueEngine = ({ reach, socialScore, author, section, loading }: StoryValueEngineProps) => {
  return (
    <div className="story-value-engine">
      <h3>Story Value Engine</h3>
      <div className="story-value-engine-table">
        <div className="details-container-title">Predictions</div>
        <div className="details-container">
          <div className="section-title">Reach</div>
          {loading ? (
            <CircleLoader width="40px" height="40px" />
          ) : reach ? (
            <div className="section-data">{reach}</div>
          ) : (
            <div className="data-not-available">Not yet available</div>
          )}
        </div>
        <div className="details-container">
          <div className="section-title">Social Score</div>
          {loading ? (
            <CircleLoader width="40px" height="40px" />
          ) : socialScore ? (
            <div className="section-data">{socialScore}</div>
          ) : (
            <div className="data-not-available">Not yet available</div>
          )}
        </div>
        <div className="details-container">
          <div className="section-title">Author</div>
          {loading ? (
            <CircleLoader width="40px" height="40px" />
          ) : author ? (
            <div className="section-data">{author}</div>
          ) : (
            <div className="data-not-available">Not yet available</div>
          )}
        </div>
        <div className="details-container">
          <div className="section-title">Section</div>
          {loading ? (
            <CircleLoader width="40px" height="40px" />
          ) : section ? (
            <div className="section-data">{section}</div>
          ) : (
            <div className="data-not-available">Not yet available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoryValueEngine;
