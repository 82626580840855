import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../store";
// @ts-ignore
import smartoctoAiLogo from "../assets/images/smartocto_ai.png";
// @ts-ignore
import smartoctoAiBottomIllustration from "../assets/images/smartocto_ai_illustration.svg";
import MainNavigation from "../components/navigation/MainNavigation";
import SmartoctoAIActions from "../components/smartoctoAI/SmartoctoAIActions";
import SmartoctoAIOutput from "../components/smartoctoAI/SmartoctoAIOutput";
import SmartoctoAILoading from "../components/smartoctoAI/SmartoctoAILoading";
import { resetSmartoctoAIState } from "../store/slices/smartoctoAI";

import "./SmartoctoAI.scss";

const SmartoctoAI = () => {
  const dispatch = useAppDispatch();
  const { isLoading, data, error } = useSelector((state: any) => state.smartoctoAI);

  const [headlineInput, setHeadlineInput] = useState("");
  const [headlineError, setHeadlineError] = useState(false);
  const [headlineErrorText, setHeadlineErrorText] = useState("");

  const [articleInput, setArticleInput] = useState("");
  const [articleError, setArticleError] = useState(false);
  const [articleErrorText, setArticleErrorText] = useState("");

  const [outputType, setOutputType] = useState("");
  const [outputRewriterType, setOutputRewriterType] = useState("");

  useEffect(() => {
    document.title = "Smartocto AI";
  }, []);

  useEffect(() => {
    if (error) {
      setOutputType("error");
    }
  }, [error]);

  const calculateHeadlineClasses = useMemo(() => {
    if (headlineError) {
      return "headline-textarea headline-error-state";
    }
    return "headline-textarea";
  }, [headlineError]);

  const calculateArticleClasses = useMemo(() => {
    if (articleError) {
      return "article-textarea article-error-state";
    }
    return "article-textarea";
  }, [articleError]);

  const calculateOutputClasses = useMemo(() => {
    if (outputType === "error") {
      return "output-field output-error";
    }
    return "output-field";
  }, [outputType]);

  const removeErrors = useCallback(() => {
    setHeadlineError(false);
    setHeadlineErrorText("");
    setArticleError(false);
    setArticleErrorText("");
    if (outputType === "error") {
      setOutputType("");
    }
  }, [
    setArticleError,
    setArticleErrorText,
    setHeadlineError,
    setHeadlineErrorText,
    dispatch,
    resetSmartoctoAIState,
    setOutputType,
    outputType,
  ]);

  return (
    <div className="smartocto-ai-page">
      <MainNavigation />
      {isLoading ? (
        <SmartoctoAILoading />
      ) : (
        <div className="smartocto-ai-wrapper">
          <div className="header">
            <div className="logo">
              <img src={smartoctoAiLogo} alt="smartoctoai logo" />
            </div>
            <div className="subtitle">
              <p>
                where <span className="subtitle-bold"> impact </span>
                and <span className="subtitle-bold"> AI </span>
                really meet
              </p>
            </div>
          </div>
          <div className="main-wrapper">
            <div className="left-container">
              <h3>story headline / paragraph</h3>
              <textarea
                className={calculateHeadlineClasses}
                onClick={() => {
                  removeErrors();
                }}
                value={headlineInput}
                placeholder="Put in headline or paragraph here"
                onChange={(e) => {
                  setHeadlineInput(e.target.value);
                }}
              />
              {headlineError && <div className="headline-error">{headlineErrorText}</div>}

              <h3>story</h3>
              <textarea
                className={calculateArticleClasses}
                onClick={() => {
                  removeErrors();
                }}
                value={articleInput}
                placeholder="Paste your (full) draft article here"
                onChange={(e) => {
                  setArticleInput(e.target.value);
                }}
              />
              {articleError && <div className="article-error">{articleErrorText}</div>}

              <h3>output</h3>
              <div className={calculateOutputClasses} id="output-field">
                <SmartoctoAIOutput
                  outputType={outputType}
                  outputRewriterType={outputRewriterType}
                  output={{ ...data }}
                  loading={isLoading}
                />
              </div>
              <div id="output-field-bottom"></div>
            </div>

            <div className="right-container">
              <SmartoctoAIActions
                headlineText={headlineInput}
                articleText={articleInput}
                setOutputType={setOutputType}
                setOutputRewriterType={setOutputRewriterType}
                setHeadlineError={setHeadlineError}
                setHeadlineErrorText={setHeadlineErrorText}
                setArticleError={setArticleError}
                setArticleErrorText={setArticleErrorText}
              />
            </div>
          </div>

          <div className="bottom-illustration">
            <div className="illustration-container">
              <img src={smartoctoAiBottomIllustration} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmartoctoAI;
