import { createSlice } from "@reduxjs/toolkit";

type State = {
 isNavigationOpen: boolean;
};

const mobile = createSlice({
  name: "mobile",
  initialState: {
    isNavigationOpen: false
  },
  reducers: {
    toggleNavigation: (state: State) => {
      state.isNavigationOpen = !state.isNavigationOpen
    },
  },
});

export default mobile.reducer;

export const { toggleNavigation } = mobile.actions;
