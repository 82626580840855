import React from "react";
import { Link } from "react-router-dom";

const MailReports = () => {
  return (
    <div className="MailReports">
      <ul>
        <li>
          <Link to="/mailReports/smartifyDaily">Smartify Daily Report</Link>
        </li>
        <li>
          <Link to="/mailReports/smartifyWeekly">Smartify Weekly Report</Link>
        </li>
      </ul>
    </div>
  );
};

export default MailReports;
