import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SideNavigation.scss";
import NavigationListItem from "./NavigationListItem";
import NavigationFilters from "./NavigationFilters";
import { CircleLoader } from "../../icons/Loaders";
import { toggleNavigation } from "../../store/slices/mobile";
import { MenuIcon, NoDataIcon, CloseIcon } from "../../icons/Icons";

type Props = {
  items: any;
  title: string;
  filters: any;
  filtersMessage: string;
  isLoading?: boolean;
  fetchFunction?: Function;
  fetchFunctionForSections?: Function;
  hasSearch?: boolean;
  hasSectionFilter?: boolean;
  searchFunction?: Function;
  page?: string;
  searchTerm?: string;
  setSearchTerm?: Function;
  selectedFilter: string;
  setSelectedFilter: Function;
  selectedSections?: Array<string>;
  setSelectedSections?: Function;
};

const SideNavigation = ({
  items,
  title,
  filters,
  filtersMessage,
  isLoading = false,
  fetchFunction,
  fetchFunctionForSections,
  hasSearch = false,
  hasSectionFilter = false,
  searchFunction,
  page,
  searchTerm,
  setSearchTerm,
  selectedFilter,
  setSelectedFilter,
  selectedSections,
  setSelectedSections,
}: Props) => {
  const dispatch = useDispatch();
  const isMobileNavigationShown = useSelector((state: any) => state.mobile.isNavigationOpen);
  const videosSparkData = useSelector((state: any) => state.feed.videosSparkData);
  const storiesSparkData = useSelector((state: any) => state.feed.storiesSparkData);
  const bundlesSparkData = useSelector((state: any) => state.bundles.bundlesSparkData);
  
  const sparkData = useMemo(() => {
    if (!page) return {};
    if (page === "videos") return videosSparkData;
    if (page === "stories") return storiesSparkData;
    if (page === "bundles") return bundlesSparkData;
    return [];
  }, [page, videosSparkData, storiesSparkData, bundlesSparkData]);

  const filteredItems = useMemo(() => {
    if (fetchFunction || !selectedFilter) {
      return items;
    } else {
      return items.filter((item) => item.itemType === selectedFilter);
    }
  }, [items, selectedFilter, fetchFunction]);

  const noData = useMemo(() => {
    return filteredItems.length === 0;
  }, [filteredItems]);

  return (
    <div className={`side-nav-container ${isMobileNavigationShown ? "mobile-open" : "mobile-closed"}`}>
      <div className="so-navigation-filters-wrapper">
        <div
          className="mobile-menu-icon"
          onClick={() => {
            dispatch(toggleNavigation());
          }}
        >
          {isMobileNavigationShown ? (
            <div className="close-icon-container">
              <CloseIcon width="20px" height="20px" />
            </div>
          ) : (
            <MenuIcon width="40px" height="40px" />
          )}
        </div>

        <NavigationFilters
          title={title}
          filtersMessage={filtersMessage}
          filters={filters}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          fetchFunction={fetchFunction}
          fetchFunctionForSections={fetchFunctionForSections}
          hasSearch={hasSearch}
          hasSectionFilter={hasSectionFilter}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          searchFunction={searchFunction}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
        />
      </div>
      {isLoading ? (
        <div className="loading-container">
          <CircleLoader color="#fff" />
        </div>
      ) : (
        <div className="navigation-items-container">
          {noData ? (
            <div className="loading-container">
              <NoDataIcon width="80" height="80" color="gainsboro" />
            </div>
          ) : (
            <>
              {filteredItems.map((item: any) => {
                return (
                  <NavigationListItem
                    key={item.key || item.postId}
                    type={item.type}
                    pubDate={item.pubDate}
                    title={item.title}
                    description={item.description}
                    itemType={item.itemType}
                    itemData={item.itemData}
                    paid={item.paid}
                    secondaryPosts={item.secondaryPosts}
                    postId={item.postId}
                    postType={item.postType}
                    itemId={item.itemId}
                    inked={item.inked}
                    bundleStoryCount={item.bundleStoryCount}
                    sparkData={sparkData[item.postId] || []}
                    day={item.day}
                    page={page}
                  />
                );
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SideNavigation;
