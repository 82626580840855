import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<any>;
  isLoading: boolean;
  error: Object | null;

  bundleData: Object | null;
  isBundleLoading: boolean;
  bundleError: Object | null;

  bundlesSparkData: Object;

  bundleGraphData: Object | null;
  isBundleGraphDataLoading: boolean;
  bundleGraphDataError: Object | null;

  bundleGraphDataChannel: Object | null;
  isBundleGraphDataChannelLoading: boolean;
  bundleGraphDataChannelError: Object | null;

  isItemPosting: boolean;
  postingError: Object | null;

  isItemRemoving: boolean;
  removingError: Object | null;

  isBundleDeleting: boolean;
  deletingError: Object | null;
};

const bundles = createSlice({
  name: "bundles",
  initialState: {
    data: [],
    isLoading: true,
    error: null,

    bundleData: {},
    isBundleLoading: true,
    bundleError: null,

    bundlesSparkData: {},

    bundleGraphData: {},
    isBundleGraphDataLoading: true,
    bundleGraphDataError: null,

    bundleGraphDataChannel: {},
    isBundleGraphDataChannelLoading: true,
    bundleGraphDataChannelError: null,

    isItemPosting: false,
    postingError: null,

    isItemRemoving: false,
    removingError: null,

    isBundleDeleting: false,
    deletingError: null,
  },
  reducers: {
    fetchBundlesStart: (state: State) => {
      state.isLoading = true;
    },
    fetchBundlesSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchBundlesFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchBundlesSparkDataStart: (state: State) => {},
    fetchBundlesSparkDataSuccess: (state: State, action) => {
      state.bundlesSparkData = { ...state.bundlesSparkData, [action.payload.bundle_id]: action.payload.data[0].data };
    },
    fetchBundlesSparkDataFailed: (state: State, action) => {
      state.error = action.payload;
    },

    fetchSingleBundleStart: (state: State) => {
      state.isBundleLoading = true;
    },
    fetchSingleBundleSuccess: (state: State, action) => {
      state.isBundleLoading = false;
      state.bundleData = action.payload;
    },
    fetchSingleBundleFailed: (state: State, action) => {
      state.isBundleLoading = false;
      state.bundleError = action.payload;
    },

    fetchBundleGraphDataStart: (state: State) => {
      state.isBundleGraphDataLoading = true;
    },
    fetchBundleGraphDataSuccess: (state: State, action) => {
      state.isBundleGraphDataLoading = false;
      state.bundleGraphData = action.payload;
    },
    fetchBundleGraphDataFailed: (state: State, action) => {
      state.isBundleGraphDataLoading = false;
      state.bundleGraphDataError = action.payload;
    },

    fetchBundleGraphDataChannelStart: (state: State) => {
      state.isBundleGraphDataChannelLoading = true;
    },
    fetchBundleGraphDataChannelSuccess: (state: State, action) => {
      state.isBundleGraphDataChannelLoading = false;
      state.bundleGraphDataChannel = action.payload;
    },
    fetchBundleGraphDataChannelFailed: (state: State, action) => {
      state.isBundleGraphDataChannelLoading = false;
      state.bundleGraphDataChannelError = action.payload;
    },

    saveItemToBundleStart: (state: State) => {
      state.isItemPosting = true;
    },
    saveItemToBundleSuccess: (state: State, action) => {
      state.isItemPosting = false;
    },
    saveItemToBundleFailed: (state: State, action) => {
      state.isItemPosting = false;
      state.postingError = action.payload;
    },

    removeItemFromBundleStart: (state: State) => {
      state.isItemRemoving = true;
    },
    removeItemFromBundleSuccess: (state: State, action) => {
      state.isItemRemoving = false;
    },
    removeItemFromBundleFailed: (state: State, action) => {
      state.isItemRemoving = false;
      state.removingError = action.payload;
    },

    deleteBundleStart: (state: State) => {
      state.isBundleDeleting = true;
    },
    deleteBundleSuccess: (state: State, action) => {
      state.isBundleDeleting = false;
      state.data = state.data.filter((bundle) => bundle.bundle_id !== action.payload);
      state.bundleData = {};
      state.bundleGraphData = {};
      state.bundleGraphDataChannel = {};
    },
    deleteBundleFailed: (state: State, action) => {
      state.isBundleDeleting = false;
      state.deletingError = action.payload;
    },
  },
});

export default bundles.reducer;

const {
  fetchBundlesStart,
  fetchBundlesSuccess,
  fetchBundlesFailed,

  fetchBundlesSparkDataStart,
  fetchBundlesSparkDataSuccess,
  fetchBundlesSparkDataFailed,

  fetchSingleBundleStart,
  fetchSingleBundleSuccess,
  fetchSingleBundleFailed,

  fetchBundleGraphDataStart,
  fetchBundleGraphDataSuccess,
  fetchBundleGraphDataFailed,

  fetchBundleGraphDataChannelStart,
  fetchBundleGraphDataChannelSuccess,
  fetchBundleGraphDataChannelFailed,

  saveItemToBundleStart,
  saveItemToBundleSuccess,
  saveItemToBundleFailed,

  removeItemFromBundleStart,
  removeItemFromBundleSuccess,
  removeItemFromBundleFailed,

  deleteBundleStart,
  deleteBundleSuccess,
  deleteBundleFailed,
} = bundles.actions;

export const fetchBundles = () => async (dispatch: Function, getState: Function) => {
  try {
    dispatch(fetchBundlesStart());
    const api = new Api();
    const result = await api.getFeedBundlesItems();
    dispatch(fetchBundlesSuccess(result));
    if (result) {
      const state = getState();
      result.forEach((item) => {
        if (!state.bundles.bundlesSparkData[item.bundle_id]) {
          dispatch(fetchBundlesSparkData(item.bundle_id));
        }
      });
    }
  } catch (err) {
    dispatch(fetchBundlesFailed(err));
  }
};

export const fetchBundlesSparkData = (id: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchBundlesSparkDataStart());
    const api = new Api();
    const result = await api.getBundlesSparkData(id);
    dispatch(fetchBundlesSparkDataSuccess(result));
  } catch (err) {
    dispatch(fetchBundlesSparkDataFailed(err));
  }
};

export const fetchSingleBundle = (id: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchSingleBundleStart());
    const api = new Api();
    const result = await api.getBundle(id);
    dispatch(fetchSingleBundleSuccess(result));
  } catch (err) {
    dispatch(fetchSingleBundleFailed(err));
  }
};

export const fetchBundleGraphData = (id: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchBundleGraphDataStart());
    const api = new Api();
    const result = await api.getBundleGraphData(id, "story");
    dispatch(fetchBundleGraphDataSuccess(result));
  } catch (err) {
    dispatch(fetchBundleGraphDataFailed(err));
  }
};

export const fetchBundleGraphDataChannel = (id: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchBundleGraphDataChannelStart());
    const api = new Api();
    const result = await api.getBundleGraphData(id, "channel");
    dispatch(fetchBundleGraphDataChannelSuccess(result));
  } catch (err) {
    dispatch(fetchBundleGraphDataChannelFailed(err));
  }
};

export const saveItemToBundle = (bundleId, postId, updateShownBundles) => async (dispatch: Function) => {
  try {
    dispatch(saveItemToBundleStart());
    const api = new Api();
    const result = await api.postItemToBundle(postId, { bundle_id: bundleId, post_id: postId });
    dispatch(saveItemToBundleSuccess(result));
    updateShownBundles(result);
  } catch (err) {
    dispatch(saveItemToBundleFailed(err));
  }
};

export const createNewBundle = (bundleId, bundleName, postId, updateShownBundles) => async (dispatch: Function) => {
  try {
    dispatch(saveItemToBundleStart());
    const api = new Api();
    const result = await api.postItemToBundle(postId, {
      bundle_id: Number(bundleId),
      bundle_name: bundleName.toString(),
      post_id: postId.toString(),
    });
    dispatch(saveItemToBundleSuccess(result));
    updateShownBundles(result);
  } catch (err) {
    dispatch(saveItemToBundleFailed(err));
  }
};

export const removeItemFromBundle = (bundleId, postId, updateShownBundles) => async (dispatch) => {
  try {
    dispatch(removeItemFromBundleStart());
    const api = new Api();
    const result = await api.deleteStoryFromBundle(bundleId, postId);
    dispatch(removeItemFromBundleSuccess(result));
    updateShownBundles(result);
  } catch (err) {
    dispatch(removeItemFromBundleFailed(err));
  }
};

export const deleteBundle = (bundleId) => async (dispatch) => {
  try {
    dispatch(deleteBundleStart());
    const api = new Api();
    await api.deleteBundle(bundleId);
    dispatch(deleteBundleSuccess(bundleId));
  } catch (err) {
    dispatch(deleteBundleFailed(err));
  }
};
