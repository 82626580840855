import React from "react";
import MainNavigation from "../components/navigation/MainNavigation";
import { NoDataIcon } from "../icons/Icons";
import "./NoLongerAvailable.scss";

const Videos = () => {
  return (
    <div>
      <MainNavigation />
      <div className="container">
        <div className="data-not-available-wrapper">
          <div className="data-not-available-container">
            <div className="no-data-icon-container">
              <NoDataIcon color="#145456" width="100" height="100" />
            </div>
            <p className="no-items-message">
              Dear client,
              <br /> This page is no longer available.
              <p>
                Please contact{" "}
                <a href="mailto:support@smartocto.com" className="mailto-link">
                  <b>support@smartocto.com</b>
                </a>{" "}
                for further assistance.
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videos;
