import React, { useState } from "react";
import Input from "../../../ui/common/Input";
import TextArea from "../../../ui/common/TextArea";
import Button from "../../../ui/common/Button";
import "./StoriesSettingsModal.scss";
import ToggleSwitch from "../../../ui/toggleSwitch/ToggleSwitch";

const prepareChannels = (data) => {
  try {
    return JSON.stringify(JSON.parse(data.channels.value || data.channels.default), null, 2);
  } catch {
    return JSON.stringify(data.channels.value || data.channels.default, null, 2);
  }
};

const prepareReferers = (data) => {
  try {
    return JSON.stringify(JSON.parse(data.referersToChannels.value || data.referersToChannels.default), null, 2);
  } catch {
    return JSON.stringify(data.referersToChannels.value || data.referersToChannels.default, null, 2);
  }
};

const StoriesSettingsModal = ({ data, onSave, onClose }) => {
  const [channels, setChannels] = useState(prepareChannels(data));
  const [conversionExpectation, setConversionExpectation] = useState(
    data.conversionExpectation.value || data.conversionExpectation.default,
  );
  const [conversionFactor, setConversionFactor] = useState(
    data.conversionFactor.value || data.conversionFactor.default,
  );

  const [engagementExpectation, setEngagementExpectation] = useState(
    data.engagementExpectation.value || data.engagementExpectation.default,
  );

  const [engagementFactor, setEngagementFactor] = useState(
    data.engagementFactor.value || data.engagementFactor.default,
  );

  const [minimalPageviewsLimit, setMinimalPageviewsLimit] = useState(
    data.minimalPageviewsLimit.value || data.minimalPageviewsLimit.default,
  );

  const [pageviewExpectation, setPageviewExpectation] = useState(
    data.pageviewExpectation.value || data.pageviewExpectation.default,
  );

  const [pageviewFactor, setPageviewFactor] = useState(data.pageviewFactor.value || data.pageviewFactor.default);

  const [referersToChannels, setReferersToChannels] = useState(prepareReferers(data));

  const [showStoryKPI, setShowStoryKPI] = useState(data.showStoryKPI.value || data.showStoryKPI.default);

  const [sparklineFactor, setSparklineFactor] = useState(data.sparklineFactor.value || data.sparklineFactor.default);

  const [storyKpiPercentile, setStoryKpiPercentile] = useState(
    data.storyKpiPercentile?.value || data.storyKpiPercentile.default,
  );

  const handleSave = () => {
    onSave({
      channels: {
        default: data.channels.default,
        value: channels,
      },
      conversionExpectation: {
        default: data.conversionExpectation.default,
        value: conversionExpectation,
      },
      conversionFactor: {
        default: data.conversionFactor.default,
        value: conversionFactor,
      },
      engagementExpectation: {
        default: data.engagementExpectation.default,
        value: engagementExpectation,
      },
      engagementFactor: {
        default: data.engagementFactor.default,
        value: engagementFactor,
      },
      minimalPageviewsLimit: {
        default: data.minimalPageviewsLimit.default,
        value: minimalPageviewsLimit,
      },
      pageviewExpectation: {
        default: data.pageviewExpectation.default,
        value: pageviewExpectation,
      },
      pageviewFactor: {
        default: data.pageviewFactor.default,
        value: pageviewFactor,
      },
      referersToChannels: {
        default: data.referersToChannels.default,
        value: referersToChannels,
      },
      showStoryKPI: {
        default: data.showStoryKPI.default,
        value: showStoryKPI,
      },
      sparklineFactor: {
        default: data.sparklineFactor.default,
        value: sparklineFactor,
      },
      storyKpiPercentile: {
        default: data.storyKpiPercentile.default,
        value: storyKpiPercentile,
      },
    });
  };

  return (
    <div className="stories-settings-modal-wrapper">
      <div className="stories-settings-modal-container">
        <div>
          <h4>Stories Settings</h4>
          <p>Channels</p>
          <TextArea value={channels} className="code-textarea" onChange={(e) => setChannels(e.target.value)} />
          <p className="metric-title">Conversion</p>
          <div className="conversion-settings-container">
            <div>
              <Input
                id="conversionExpectation"
                label="Expectation"
                type="number"
                value={conversionExpectation}
                onChange={(e) => {
                  setConversionExpectation(e.target.valueAsNumber);
                }}
                separateLines
                fullWidth
              />
            </div>
            <div>
              <Input
                id="conversionFactor"
                label="Factor"
                type="number"
                value={conversionFactor}
                onChange={(e) => {
                  setConversionFactor(e.target.valueAsNumber);
                }}
                separateLines
                fullWidth
              />
            </div>
          </div>
          <p className="metric-title">Engagement</p>
          <div className="engagement-settings-container">
            <div>
              <Input
                id="engagementExpectation"
                label="Expectation"
                type="number"
                value={engagementExpectation}
                onChange={(e) => {
                  setEngagementExpectation(e.target.valueAsNumber);
                }}
                separateLines
                fullWidth
              />
            </div>
            <div>
              <Input
                id="engagementFactor"
                label="Factor"
                type="number"
                value={engagementFactor}
                onChange={(e) => {
                  setEngagementFactor(e.target.value);
                }}
                separateLines
                fullWidth
              />
            </div>
          </div>
          <p className="metric-title">Pageviews</p>
          <div className="pageviews-settings-container">
            <div>
              <Input
                id="minimalPageviewsLimit"
                label="Minimal Limit"
                type="number"
                value={minimalPageviewsLimit}
                onChange={(e) => {
                  setMinimalPageviewsLimit(e.target.value);
                }}
                separateLines
                fullWidth
              />
            </div>
            <div>
              <Input
                id="pageviewExpectation"
                label="Expectations"
                type="number"
                value={pageviewExpectation}
                onChange={(e) => {
                  setPageviewExpectation(e.target.value);
                }}
                separateLines
                fullWidth
              />
            </div>
            <div>
              <Input
                id="pageviewFactor"
                label="Factor"
                type="number"
                value={pageviewFactor}
                onChange={(e) => {
                  setPageviewFactor(e.target.value);
                }}
                separateLines
                fullWidth
              />
            </div>
          </div>
        </div>
        <div className="right-column">
          <p>Referers To Channels</p>
          <TextArea
            value={referersToChannels}
            className="code-textarea"
            onChange={(e) => {
              setReferersToChannels(e.target.value);
            }}
          />
          <div className="show-story-cpi-container">
            <p>Show Story KPI</p>
            <ToggleSwitch
              checked={showStoryKPI}
              onChange={(e) => {
                setShowStoryKPI(e);
              }}
            />
          </div>
          <Input
            id="sparklineFactor"
            label="Sparkline Factor"
            type="number"
            value={sparklineFactor}
            onChange={(e) => {
              setSparklineFactor(e.target.value);
            }}
            separateLines
            fullWidth
          />
          <Input
            id="storyKpiPercentile"
            label="Story KPI Percentile"
            type="number"
            value={storyKpiPercentile}
            onChange={(e) => {
              setStoryKpiPercentile(e.target.value);
            }}
            separateLines
            fullWidth
          />
        </div>
      </div>
      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            handleSave();
            onClose();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default StoriesSettingsModal;
