import React, { useMemo } from "react";

type DateTimeLongProps = {
  inputDateTime: string;
};

const DateTimeLong = ({ inputDateTime }: DateTimeLongProps) => {
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };
  const calculatedDateTime = useMemo(() => {
    const date = new Date(inputDateTime);
    // @ts-ignore
    return new Intl.DateTimeFormat("en-US", options).format(date);
  }, [inputDateTime]);

  return <>{calculatedDateTime}</>;
};

export default DateTimeLong;
