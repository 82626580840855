import React, { useRef, useEffect, useCallback, useState } from "react";
import * as d3 from "d3";
import { CircleLoader } from "../../icons/Loaders";
import "./ImpactRadarGraph.scss";

const ImpactRadarGraph = ({ data, effortData, impactData, startDate, endDate, selectedChannel, isLoading }) => {
  const svgRef = useRef();
  const [overlayWidth, setOverlayWidth] = useState(1780);
  const [overlayHight, setOverlayHight] = useState(800);

  const margin = { top: 50, right: 50, bottom: 50, left: 80 };
  const xAxisOffset = 30;
  const yAxisOffset = 30;
  const xAxisMargin = 10;
  const yAxisMargin = 10;
  const initialWidth = 1750 - margin.left - margin.right;
  const initialHeight = 700 - margin.top - margin.bottom;

  const initialSurroundingMargins = 45;
  const responsiveSurroundingMargins = 220;

  const maxCircleRadius = 24.7;

  const formatDate = useCallback(
    (increment) => {
      if (!startDate) return "";
      const dateFormatted = new Date(startDate?.getUTCFullYear(), startDate.getMonth(), startDate.getUTCDate());
      dateFormatted.setDate(dateFormatted.getDate() + increment);
      return dateFormatted;
    },
    [startDate],
  );

  const drawChart = useCallback(() => {
    const windowWidth = window.innerWidth;

    // Calculate width based on screen width in order to acheive responsive behaviour
    const width =
      windowWidth >= initialWidth + margin.left + margin.right + yAxisOffset + initialSurroundingMargins
        ? initialWidth
        : windowWidth - responsiveSurroundingMargins;

    // Used for responsive scaling of various elements on chart
    let widthBasedScaleSize = width / initialWidth;

    // Height adjusted to the scale of screen width in order to acheive responsive behaviour
    const height = initialHeight * widthBasedScaleSize;

    // Total SVG dimensions;
    const totalWidth = width + margin.left + margin.right + yAxisOffset;
    const totalHeight = height + margin.top + margin.bottom + xAxisOffset;

    // Chart data needs translating in order to leave space for x and y axis labels
    const chartDataTranslateX = margin.left + yAxisOffset + yAxisMargin;
    const chartDataTranslateY = (margin.top + xAxisOffset + xAxisMargin) * widthBasedScaleSize;

    // X axis adjustmets in ordred to have nice positioning
    const xAxisTranslateValue = height + xAxisMargin * widthBasedScaleSize + (10 * 2) / widthBasedScaleSize;

    const totalYScaleHeight = height + margin.top + margin.bottom;
    const verticalLinesHeight = height + xAxisMargin * widthBasedScaleSize + xAxisOffset + margin.top;

    setOverlayWidth(totalWidth);
    setOverlayHight(totalHeight);

    let maxEffort = d3.max(effortData, function (d) {
      return d.value;
    });
    let maxImpact = d3.max(impactData, function (d) {
      return d.value;
    });

    // Scales setup
    const xScale = d3
      .scaleLinear()
      .range([0, width])
      .domain([6, 23 + 6]);

    const yScale = d3.scaleLinear().range([0, totalYScaleHeight]).domain([startDate, endDate]);

    // Clear the chart before drawing a new one
    d3.select(svgRef.current).selectAll("*").remove();

    const validEffortData = effortData.filter((d) => !isNaN(d.date));
    const validImpactData = impactData.filter((d) => !isNaN(d.date));

    // Set up axes
    const xAxis = d3
      .axisBottom(xScale)
      .ticks(Math.min(23, parseInt(width / 30, 10)))
      .tickFormat((d) => {
        return String(d % 24).padStart(2, "0");
      });

    const tickValues = [];
    // setting up tick values for the y axis
    for (let index = 0; index < 7; index++) {
      tickValues.push(formatDate(index));
    }
    const yAxis = d3.axisLeft(yScale).tickFormat(d3.timeFormat("%a %d %b")).tickValues(tickValues).ticks(7);

    const svg = d3
      .select(svgRef.current)
      .attr("width", totalWidth)
      .attr("height", totalHeight)
      .append("g")
      .attr("transform", `translate(${chartDataTranslateX}, ${chartDataTranslateY})`);
    // Draw axes

    svg.append("g").attr("class", "x-axis").attr("transform", `translate(0,${xAxisTranslateValue})`).call(xAxis);

    if (startDate) {
      svg
        .append("g")
        .attr("class", "y-axis")
        .call(yAxis)
        .selectAll("text")
        .attr("x", "-25")
        .attr("class", "y-axis-label");
    }

    // Draw effort circles
    svg
      .selectAll("circle-effort")
      .data(validEffortData)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.hour))
      .attr("cy", (d) => {
        if (d.hour > 23 && d.hour < 30) {
          let yesterday = new Date(`${d.dateD.c.year}-${d.dateD.c.month}-${d.dateD.c.day}`);
          yesterday.setDate(yesterday.getDate() - 1);
          return yScale(yesterday) - 10;
        } else {
          return yScale(new Date(`${d.dateD.c.year}-${d.dateD.c.month}-${d.dateD.c.day}`)) - 10;
        }
      })
      .attr("r", 0)
      .attr("fill", "#1abcae")
      .attr("class", `color-${selectedChannel}-effort`)
      .transition()
      .duration(500)
      .delay((d) => d.hour * 10)
      .attr("r", (d) => {
        if (!d.value) return 0;
        else return (d.value / maxEffort) * maxCircleRadius * widthBasedScaleSize;
      })
      .selection();

    // Draw impact circles
    svg
      .selectAll("circle-impact")
      .data(validImpactData)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.hour))
      .attr("cy", (d) => {
        if (d.hour > 23 && d.hour < 30) {
          let yesterday = new Date(`${d.dateD.c.year}-${d.dateD.c.month}-${d.dateD.c.day}`);
          yesterday.setDate(yesterday.getDate() - 1);
          return yScale(yesterday) + 20 * widthBasedScaleSize;
        } else {
          return yScale(new Date(`${d.dateD.c.year}-${d.dateD.c.month}-${d.dateD.c.day}`)) + 20 * widthBasedScaleSize;
        }
      })
      .attr("r", 0)
      .attr("fill", "#4381a6")
      .attr("class", `color-${selectedChannel}-impact`)
      .transition()
      .duration(500)
      .delay((d) => d.hour * 10)
      .attr("r", (d) => {
        if (!d.value) return 0;
        else return (d.value / maxImpact) * maxCircleRadius * widthBasedScaleSize;
      })
      .selection();

    svg
      .selectAll(".x-axis line")
      .attr("stroke", "rgba(151,151,151,.3)")
      .attr("fill", "none")
      .attr("y0", -20)
      .attr("y2", function (d) {
        return -verticalLinesHeight;
      });

    svg.selectAll(".y-axis .domain").attr("stroke", "");
    svg.selectAll(".y-axis line").attr("stroke", "");
    svg.selectAll(".x-axis .domain").attr("stroke", "");
  }, [
    effortData,
    impactData,
    startDate,
    endDate,
    selectedChannel,
    formatDate,
    initialHeight,
    initialWidth,
    margin.bottom,
    margin.left,
    margin.right,
    margin.top,
  ]);

  useEffect(() => {
    drawChart();
  }, [drawChart]);

  useEffect(() => {
    window.addEventListener("resize", drawChart);

    return () => {
      window.removeEventListener("resize", drawChart);
    };
  }, [drawChart]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay" style={{ width: `${overlayWidth}px`, height: `${overlayHight}px` }}>
          <CircleLoader />
        </div>
      )}
      <svg ref={svgRef} style={{ overflow: "visible" }}></svg>
    </>
  );
};

export default ImpactRadarGraph;
