import React, { useCallback } from "react";
import Like from "../../icons/FacebookReactions/Like";
import Haha from "../../icons/FacebookReactions/Haha";
import Angry from "../../icons/FacebookReactions/Angry";
import Love from "../../icons/FacebookReactions/Love";
import Sad from "../../icons/FacebookReactions/Sad";
import Wow from "../../icons/FacebookReactions/Wow";
import { CircleLoader } from "../../icons/Loaders";
import { useTranslation } from "react-i18next";
import ToolTip from "../ui/common/ToolTip";
import QuestionMarkIcon from "../../icons/QuestionMarkIcon";
import useIsFeatureAllowed from "../../customHooks/useIsFeatureAllowed";
import { useSelector } from "react-redux";

import "./Numbers.scss";

type NumbersProps = {
  type?: string,
  loading: boolean,
  hideFacebookNumbers?: boolean,
};

const Numbers = ({ type, loading, hideFacebookNumbers = false }: NumbersProps) => {
  const { t } = useTranslation();
  const numbers = useSelector((state: any) => state.activeFeedItem.numbersData) || {};
  const isFeatureAllowed = useIsFeatureAllowed();

  const formatTotalReadingTime = (totalReadingTime) => {
    return totalReadingTime && totalReadingTime > 0
      ? Math.round((totalReadingTime / 3600) * 10) / 10 + " hrs"
      : "0 hrs";
  };

  const renderSocialCards = useCallback(() => {
    return (
      !hideFacebookNumbers && (
        <div className="numbers-section facebook-numbers">
          <div className="numbers-header">
            <h3>{t("story.numbers.facebook")}</h3>
            <h4>{t("story.reach.title")}</h4>
            <div className="numbers-details-container">
              <div className="numbers-details">
                <div className="numbers-details-title">{t("story.reach.title")}</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.reach}
                </div>
              </div>
              <div className="numbers-details">
                <div className="numbers-details-title">{t("story.numbers.consumptions")}</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.consumptions}
                </div>
              </div>
            </div>
          </div>
          <div className="separator"></div>
          <div className="numbers-content">
            <h4>{t("story.numbers.smartdata")}</h4>
            <div className="numbers-details-container">
              <div className="numbers-details">
                <div className="numbers-details-title">{t("story.numbers.engagementRate")}</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.engagementRate}
                </div>
              </div>
              <div className="numbers-details">
                <div className="numbers-details-title">{t("story.numbers.linkClick")}</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.linkClick}
                </div>
              </div>
            </div>

            <div className="numbers-details-container">
              <div className="numbers-details">
                <div className="numbers-details-title">{t("story.numbers.hide")}</div>
                <div className="numbers-details-value">
                  {loading ? (
                    <CircleLoader width="40px" height="40px" />
                  ) : (
                    numbers?.facebook?.negativeFeedbackDetail?.hide
                  )}
                </div>
              </div>
              <div className="numbers-details">
                <div className="numbers-details-title">{t("story.numbers.hideAll")}</div>
                <div className="numbers-details-value">
                  {loading ? (
                    <CircleLoader width="40px" height="40px" />
                  ) : (
                    numbers?.facebook?.negativeFeedbackDetail?.hideAll
                  )}
                </div>
              </div>
            </div>

            <div className="numbers-details-container">
              <div className="numbers-details">
                <div className="numbers-details-title">{t("story.numbers.report")}</div>
                <div className="numbers-details-value">
                  {loading ? (
                    <CircleLoader width="40px" height="40px" />
                  ) : (
                    numbers?.facebook?.negativeFeedbackDetail?.reportSpam
                  )}
                </div>
              </div>
              <div className="numbers-details">
                <div className="numbers-details-title">{t("story.numbers.pageUnlike")}</div>
                <div className="numbers-details-value">
                  {loading ? (
                    <CircleLoader width="40px" height="40px" />
                  ) : (
                    numbers?.facebook?.negativeFeedbackDetail?.unlikePage
                  )}
                </div>
              </div>
            </div>
            <div className="facebook-reactions-container">
              <div className="facebook-reaction">
                <div className="facebook-reaction-icon">
                  <Like />
                </div>
                <div className="facebook-reaction-count">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.reactionDetail?.like}
                </div>
              </div>
              <div className="facebook-reaction">
                <div className="facebook-reaction-icon">
                  <Love />
                </div>
                <div className="facebook-reaction-count">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.reactionDetail?.love}
                </div>
              </div>
              <div className="facebook-reaction">
                <div className="facebook-reaction-icon">
                  <Haha />
                </div>
                <div className="facebook-reaction-count">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.reactionDetail?.haha}
                </div>
              </div>
              <div className="facebook-reaction">
                <div className="facebook-reaction-icon">
                  <Wow />
                </div>
                <div className="facebook-reaction-count">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.reactionDetail?.wow}
                </div>
              </div>
              <div className="facebook-reaction">
                <div className="facebook-reaction-icon">
                  <Sad />
                </div>
                <div className="facebook-reaction-count">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.reactionDetail?.sad}
                </div>
              </div>
              <div className="facebook-reaction">
                <div className="facebook-reaction-icon">
                  <Angry />
                </div>
                <div className="facebook-reaction-count">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.reactionDetail?.angry}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  }, [hideFacebookNumbers, numbers, loading, t]);

  const renderVideoSocialCards = useCallback(() => {
    return (
      <>
        <div className="numbers-section facebook-numbers">
          <div className="numbers-header">
            <h3>{t("story.numbers.facebook")}</h3>
            <h4>{t("story.reach.title")}</h4>
            <div className="numbers-details-container">
              <div className="numbers-details">
                <div className="numbers-details-title">Streamstarts</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.videoViews || 0}
                </div>
              </div>
            </div>
          </div>
          <div className="separator"></div>
          <div className="numbers-content">
            <h4>Data by Facebook API</h4>
            <div className="numbers-details-container">
              <div className="numbers-details-half-w">
                <div className="numbers-details-title">Likes</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.likes || 0}
                </div>
              </div>
              <div className="numbers-details-half-w">
                <div className="numbers-details-title">Comments</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.comments || 0}
                </div>
              </div>
            </div>

            <div className="numbers-details-container">
              <div className="numbers-details-half-w">
                <div className="numbers-details-title">Shares</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.facebook?.shares || 0}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="numbers-section facebook-numbers">
          <div className="numbers-header">
            <h3>YouTube</h3>
            <h4>{t("story.reach.title")}</h4>
            <div className="numbers-details-container">
              <div className="numbers-details">
                <div className="numbers-details-title">Streamstarts</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.youtube?.videoViews || 0}
                </div>
              </div>
            </div>
          </div>
          <div className="separator"></div>
          <div className="numbers-content">
            <h4>Data by YouTube API</h4>
            <div className="numbers-details-container">
              <div className="numbers-details-half-w">
                <div className="numbers-details-title">Likes</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.youtube?.likes || 0}
                </div>
              </div>

              <div className="numbers-details-half-w">
                <div className="numbers-details-title">Dislikes</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.youtube?.dislikes || 0}
                </div>
              </div>
            </div>

            <div className="numbers-details-container">
              <div className="numbers-details-half-w">
                <div className="numbers-details-title">Favorites</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.youtube?.favorites || 0}
                </div>
              </div>

              <div className="numbers-details-half-w">
                <div className="numbers-details-title">Comments</div>
                <div className="numbers-details-value">
                  {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.youtube?.comments || 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }, [loading, numbers, t]);

  return (
    <div className="numbers-container">
      <h3>{t("story.numbers.title")}</h3>

      <div className="numbers-sections-container">
        <div className="numbers-section web-numbers">
          <div className="numbers-header">
            <h3>{t("story.numbers.web")}</h3>
            <h4>Reach</h4>
            <div className="numbers-details">
              <div className="numbers-details-title">
                {type !== "videos" ? t("story.numbers.pageviews") : "Streamstarts"}
              </div>
              <div className="numbers-details-value">
                {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.web?.pageViews || "-"}
              </div>
            </div>
          </div>
          <div className="separator"></div>
          {type !== "videos" && isFeatureAllowed("readingtime") && (
            <>
              <div className="numbers-content">
                <div className="numbers-header-tooltip">
                  <h4>{t("story.numbers.smartdata")}</h4>
                  <ToolTip message={"Based on data accumulated from Yesterday morning until now."}>
                    <QuestionMarkIcon />
                  </ToolTip>
                </div>
                <div className="numbers-details">
                  <div className="numbers-details-title">{t("story.numbers.avgReadingTime")}</div>
                  <div className="numbers-details-value">
                    {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.web?.averageTime || "-"}
                  </div>
                </div>
                <div className="numbers-details">
                  <div className="numbers-details-title">Total reading time</div>
                  <div className="numbers-details-value">
                    {loading ? (
                      <CircleLoader width="40px" height="40px" />
                    ) : (
                      formatTotalReadingTime(numbers?.web?.totalTime)
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          {type === "videos" && isFeatureAllowed("readingtime") && numbers?.totalVideoAttTime > 0 && (
            <>
              <div className="separator"></div>
              <div className="numbers-content">
                <div className="numbers-header-tooltip">
                  <h4>{t("story.numbers.smartdata")}</h4>
                  <ToolTip message={"Based on data accumulated from Yesterday morning until now."}>
                    <QuestionMarkIcon />
                  </ToolTip>
                </div>
                <div className="numbers-details">
                  <div className="numbers-details-title">Avg. attention time </div>
                  <div className="numbers-details-value">
                    {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.avgVideoAttTime}
                  </div>
                </div>
                {/* <div className="numbers-details">
                  <div className="numbers-details-title">Total attention time</div>
                  <div className="numbers-details-value">
                    {loading ? (
                      <CircleLoader width="40px" height="40px" />
                    ) : (
                        formatTotalReadingTime(numbers?.totalVideoAttTime)
                    )}
                  </div>
                </div> */}
              </div>
            </>
          )}
        </div>
        <div className="numbers-section app-numbers">
          <div className="numbers-header">
            <h3>{t("story.numbers.app")}</h3>
            <h4>{t("story.reach.title")}</h4>
            <div className="numbers-details">
              <div className="numbers-details-title">
                {type !== "videos" ? t("story.numbers.screenviews") : "Streamstarts"}
              </div>
              <div className="numbers-details-value">
                {loading ? <CircleLoader width="40px" height="40px" /> : numbers?.app?.screenViews || "-"}
              </div>
            </div>
          </div>
          <div className="separator"></div>
          <div className="numbers-content"></div>
        </div>
        {type !== "videos" ? renderSocialCards() : renderVideoSocialCards()}
      </div>
    </div>
  );
};

export default Numbers;
