import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<any>;
  isLoading: boolean;
  error: Object | null;

  postingError: Object | null;
  isPosting: boolean;
};

const users = createSlice({
  name: "users",
  initialState: {
    data: [],
    isLoading: true,
    error: null,

    postingError: null,
    isPosting: false,
  },
  reducers: {
    fetchUsersStart: (state: State) => {
      state.isLoading = true;
    },
    fetchUsersSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchUsersFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    addUserStart: (state: State) => {
      state.isPosting = true;
      state.postingError = null;
    },
    addUserSuccess: (state: State, action) => {
      state.isPosting = false;
      const newUsers = [...state.data];
      newUsers.push({
        id: action.payload.id,
        active: action.payload.active,
        admin: action.payload.admin,
        apiToken: action.payload.apiToken,
        brands: action.payload.brands,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        superUser: action.payload.superUser,
      });
      state.data = newUsers;
    },
    addUserFailed: (state: State, action) => {
      state.isPosting = false;
      state.postingError = action.payload;
    },

    editUserStart: (state: State) => {
      state.isPosting = true;
      state.postingError = null;
    },
    editUserSuccess: (state: State, action) => {
      state.isPosting = false;
      const updatedUserIndex = state.data.findIndex((user) => user.email === action.payload.oldEmail);
      const newUsers = [...state.data];
      newUsers[updatedUserIndex] = {
        active: action.payload.active,
        admin: action.payload.admin,
        apiToken: action.payload.apiToken,
        brands: action.payload.brands,
        email: action.payload.email,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        superUser: action.payload.superUser,
      };
      state.data = newUsers;
    },
    editUserFailed: (state: State, action) => {
      state.isPosting = false;
      state.postingError = action.payload;
    },

    deleteUserStart: (state: State) => {
      state.isPosting = true;
      state.postingError = null;
    },
    deleteUserSuccess: (state: State, action) => {
      state.isPosting = false;
      const newUsers = state.data.filter((user) => user.id !== action.payload);
      state.data = newUsers;
    },
    deleteUserFailed: (state: State, action) => {
      state.isPosting = false;
      state.postingError = action.payload;
    },

    inviteUserStart: (state: State) => {
      state.isPosting = true;
      state.postingError = null;
    },
    inviteUserSuccess: (state: State) => {
      state.isPosting = false;
    },
    inviteUserFailed: (state: State, action) => {
      state.isPosting = false;
      state.postingError = action.payload;
    },
  },
});

export default users.reducer;

const {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFailed,
  addUserStart,
  addUserSuccess,
  addUserFailed,
  editUserStart,
  editUserSuccess,
  editUserFailed,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailed,
  inviteUserStart,
  inviteUserSuccess,
  inviteUserFailed,
} = users.actions;

export const fetchUsers = () => async (dispatch: Function) => {
  try {
    dispatch(fetchUsersStart());
    const api = new Api();
    const result = await api.getUsers();
    dispatch(fetchUsersSuccess(result));
  } catch (err) {
    dispatch(fetchUsersFailed(err));
  }
};

export const fetchAllUsers = () => async (dispatch: Function) => {
  try {
    dispatch(fetchUsersStart());
    const api = new Api();
    const result = await api.getAllUsers();
    dispatch(fetchUsersSuccess(result));
  } catch (err) {
    dispatch(fetchUsersFailed(err));
  }
};

export const addUser = (user) => async (dispatch: Function) => {
  try {
    dispatch(addUserStart());
    const api = new Api();
    const result = await api.postAddUser(user);
    dispatch(addUserSuccess({ id: result.id, ...user }));
  } catch (err) {
    dispatch(addUserFailed(err));
    throw err;
  }
};

export const editUser = (email, user) => async (dispatch: Function) => {
  try {
    dispatch(editUserStart());
    const api = new Api();
    await api.postUpdateUser(email, user);
    dispatch(editUserSuccess({ ...user, oldEmail: email }));
  } catch (err) {
    dispatch(editUserFailed(err));
    throw err;
  }
};

export const deleteUser = (id) => async (dispatch: Function) => {
  try {
    dispatch(deleteUserStart());
    const api = new Api();
    await api.deleteUser(id);
    dispatch(deleteUserSuccess(id));
  } catch (err) {
    dispatch(deleteUserFailed(err));
    throw err;
  }
};

export const inviteUser = (email, host, generatePassword) => async (dispatch: Function) => {
  try {
    dispatch(inviteUserStart());
    const api = new Api();
    await api.postInviteUser(email, host, generatePassword);
    dispatch(inviteUserSuccess());
  } catch (err) {
    dispatch(inviteUserFailed(err));
    throw err;
  }
};
