import React, { useEffect, useState, useRef } from "react";
import Button from "../common/Button";
import "./CollapsibleCell.scss";

const CollapsibleCell = ({ children }: { children: any }) => {
  const cellRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!cellRef.current) return;
    if (cellRef.current?.offsetHeight > 100) {
      setCollapsed(true);
    }
  }, [cellRef]);

  const expandCell = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  return (
    <div>
      <div ref={cellRef} className={`${collapsed && "cell-collapsed"} ${expanded && "cell-expanded"}`}>
        {children}
      </div>
      {collapsed && <Button onClick={expandCell}>{expanded ? "Show less" : "Show more"}</Button>}
    </div>
  );
};

export default CollapsibleCell;
