import React, { useState, useMemo } from "react";
import Input from "../ui/common/Input";
import "./BrandSelector.scss";

type BrandSelectorProps = {
  brands: Array<any>;
  currentBrand: string;
};

const BrandSelector = ({ brands, currentBrand }: BrandSelectorProps) => {
  const [searchTerm, setSearchTerm] = useState("");

  const getRedirectAddress = (brandId: string) => {
    let link = window.location.origin;
    link = origin.replace(currentBrand, brandId);
    return link;
  };

  const searchFilteredBrands = useMemo(() => {
    if (!brands) return [];
    if (searchTerm.trim().length === 0) return brands;
    return brands.filter((brand) => brand.name.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
  }, [searchTerm, brands]);

  return (
    <div>
      <h3>View other brands</h3>
      <Input
        fullWidth
        type="text"
        placeholder="Search for brand..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
      />
      <div className="brand-selector-container">
        <ul>
          {brands &&
            searchFilteredBrands.map((brand) => {
              return (
                <a key={brand.id} href={getRedirectAddress(brand.id)}>
                  <li>{brand.name}</li>
                </a>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default BrandSelector;
