import React from "react";
import "./ToggleSwitch.scss";

type SwitchProps = {
  checked?: boolean;
  disabled?: boolean;
  onChange?: Function
};

const ToggleSwitch = ({ checked, disabled, onChange }: SwitchProps) => {
  return (
    <label className="switch">
      <input 
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange ? (e) => onChange(e.target.checked) : () => {}}
      />
      <span className="slider round"></span>
    </label>
  );
};

export default ToggleSwitch;
