import React, { useCallback, useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import Input from "../../ui/common/Input";
import Button from "../../ui/common/Button";
import TextArea from "../../ui/common/TextArea";
import ToggleSwitch from "../../ui/toggleSwitch/ToggleSwitch";
import Select from "../../ui/select/Select";
import { useAppDispatch } from "../../../store";
import { updateImport, resetImportStates } from "../../../store/slices/imports";
import "./EditImportModal.scss";

const EditImportModal = ({ data, onClose }) => {
  const dispatch = useAppDispatch();
  const importTypes = useSelector((state: any) => state.imports.importTypes);
  const brands = useSelector((state: any) => state.brands.data);
  const [importId, setImportId] = useState(data.id);
  const [brandId, setBrandId] = useState(data.brandId);
  const [type, setType] = useState(data.type);
  const [importActive, setImportActive] = useState(data.active);
  const [insertMethod, setInsertMethod] = useState(data.insertMethod);
  const [mapping, setMapping] = useState(JSON.stringify(data.mapping, null, 2));
  const [params, setParams] = useState<any>({});
  const InsertMethods = ["insert", "update"];

  const updatingImport = useSelector((state: any) => state.imports.updatingImport);
  const updatingImportError = useSelector((state: any) => state.imports.updatingImportError);
  const [hasAttemptedUpdate, setHasAttemptedUpdate] = useState(false);

  useEffect(() => {
    // set initial values of param fields based on type and initial data
    if (!type) return;
    const availableParams = importTypes[type];

    let preparedValuesForParams = {};
    if (availableParams) {
      availableParams.forEach((element) => {
        if (element.field === "wildcardFilters") {
          if (data[element.field] !== undefined) {
            preparedValuesForParams = {
              ...preparedValuesForParams,
              [element.field]: JSON.stringify(data[element.field], null, 2),
            };
          }
        } else if (data[element.field] && element.type === "array") {
          if (data[element.field].length === 1 && data[element.field][0].substring(0, data[element.field][0].length - 1) !== ",") {
            preparedValuesForParams = {
              ...preparedValuesForParams,
              [element.field]: data[element.field][0] + ","
            };
          } else {
            preparedValuesForParams = {
              ...preparedValuesForParams,
              [element.field]: (!Array.isArray(data[element.field])) ? data[element.field] + ',' : data[element.field].join(",\n")
            };
          }
        }
        else if (data[element.field] !== undefined) {
          preparedValuesForParams = {
            ...preparedValuesForParams,
            [element.field]: data[element.field],
          };
        }
        setParams({
          ...params,
          ...preparedValuesForParams,
        });
      });
    }
  }, [importTypes, type, data]);

  const brandOptions = useMemo(() => {
    return brands.map((brand) => {
      return brand.id;
    });
  }, [brands]);

  const handleParamValueChange = (value: any, field: string) => {
    setParams({
      ...params,
      [field]: value,
    });
  };
  const closeModal = () => {
    onClose();
  }
  const handleSaveImport = useCallback(async () => {
    try {
      let preparedParams = {} as any;
      preparedParams = prepareParamsForSave(params);
      const payload = {
        ...data,
        active: importActive,
        mapping: JSON.parse(mapping),
        ...preparedParams,
      };
      await dispatch(updateImport(importId, payload));
      setHasAttemptedUpdate(true);
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, importActive, params, mapping, importId, data]);



  const prepareParamsForSave = (paramsData) => {
    let params = { ...paramsData };
    for (let key in params) {
      if (params.hasOwnProperty(key)) {
        const paramType = importTypes[type].find((param) => param.field === key).type;
        if (params[key] && paramType === "array" && typeof params[key] === "string" && params[key].includes(",")) {
          if (key === "wildcardFilters") {
            try {
              params[key] = JSON.parse(params[key]);
            } catch (e) {
              console.error("Error parsing wildcardFilters", e);
            }
          } else {
            params[key] = params[key].split(",").map((item) => item.trim()).filter(item => item);
          }
        }

        if (params[key] && paramType === "boolean") {
          params[key] = (params[key] === "true" || params[key] === true);
        }
      }
    }
    return params;
  };

  useEffect(() => {
    if (hasAttemptedUpdate && !updatingImport) {
      if (updatingImportError === null) {
        closeModal();
      }
    }
  }, [hasAttemptedUpdate, updatingImport, updatingImportError]);

  useEffect(() => {
    // Reset the imports update error state when the modal is opened
    dispatch(resetImportStates());
  }, [dispatch]);



  const paramsForm = useMemo(() => {
    if (importTypes[type] === undefined || Object.keys(params).length === 0) return "";
    return (
      <div className="params-container">
        {importTypes[type].map((param, i) => {
          return (
            <div className="param-container" key={param.field || i}>
              <label className="param-label">{param.field}</label>
              {param.type === "boolean" ? (
                <ToggleSwitch
                  checked={params[param.field] === true}
                  onChange={(e) => {
                    handleParamValueChange(e, param.field);
                  }}
                />
              ) : param.type === "array" || param.type === "object" ? (
                <TextArea
                  value={params[param.field]}
                  onChange={(e) => {
                    handleParamValueChange(e.target.value, param.field);
                  }}
                />
              ) : (
                <Input
                  value={params[param.field]}
                  onChange={(e) => {
                    handleParamValueChange(e.target.value, param.field);
                  }}
                  fullWidth
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }, [params]);

  return (
    <div className="imports-modal-wrapper">
      <h1 className="imports-modal-title">Edit import</h1>
      <div className="imports-modal-container">
        <div className="column">
          <Input label="Id" value={importId} disabled separateLines fullWidth />

          <div className="drop-select">
            <label htmlFor="importType">Type</label>

            <Input
              value={type}
              disabled={true}
              onChange={(e) => {
                setType(e.target.value);
              }}
              fullWidth
            />

          </div>
          <div className="drop-select">
            <label htmlFor="type">BrandId</label> 
            <Input
              value={brandId}
              disabled={true}
              onChange={(e) => {
                setBrandId(e.target.value);
              }}
              fullWidth
            />
          </div>
          <div className="switch-container">
            <ToggleSwitch
              checked={importActive}
              onChange={(e) => {
                setImportActive(e === true);
              }}
            />{" "}
            Active
          </div>
          {type && (
            <div className="drop-select">
              <label htmlFor="insertMethod">Insert Method</label>
              <Select
                options={InsertMethods}
                selectedOption={insertMethod}
                setSelectedOption={(e) => {
                  setInsertMethod(e);
                }}
                styling={{
                  selectOptionsContainerClassName: "options-dropdown",
                }}
                fullWidth
              />
            </div>
          )}
          <p>Mapping</p>
          <TextArea
            value={mapping}
            className="textarea-code-editor"
            onChange={(e) => {
              setMapping(e.target.value);
            }}
          />
        </div>
        <div className="column">{paramsForm}</div>
      </div>
      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </Button>
        <Button.Feedback
          variant="success"
          onClick={() => {
            handleSaveImport();
          }}
        >
          Save
        </Button.Feedback>
      </div>
    </div>
  );
};

export default EditImportModal;
