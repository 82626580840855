import React, { useEffect } from "react";
import { Provider } from "react-redux";
import "./App.scss";
import store from "./store";
import Router from "./Router";

function App() {

  useEffect(() => {
    // Handle legacy hash router, redirect to route without it.
    if (window.location.href.includes("#")) {
      const location = `${window.location}`;
      const newLocation = location.replace("/#", "");
      window.location.replace(newLocation);
    }
  }, []);

  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

export default App;
