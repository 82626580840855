import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LogoIcon } from "../icons/Icons";

import "./ForgotPassword.scss";
import Api from "../store/api/Api";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const API = new Api();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [formSuccess, setFormSuccess]: [boolean|null, Function] = useState(null);
  const [responseMsg, setResponseMsg] = useState("");

  const handleSubmit = (e) => {
    setFormSuccess(null);
    setResponseMsg("");

    API.forgotPassword(email).then(response => {
      if (response.result === 'success') {
        setFormSuccess(true);
        setResponseMsg(response.message);
      } else {
        setFormSuccess(false);
        setResponseMsg(response.message);
      }
    })
      .catch(err => {
        setFormSuccess(false);
        if (err?.response?.data?.message) {
          setResponseMsg(err?.response?.data?.message);
        }
      });
  };

  useEffect(() => {

  }, []);

  return (
    <div className="forgot-pwd-page">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        
          handleSubmit(e); 
        }}
      >
        <div className="login-logo-container">
          <LogoIcon />
        </div>

        {formSuccess && (
          <>
            <p>{responseMsg}</p>
            <p>
              <Link to={'/login'}>[ Back to the login screen ]</Link>
            </p>
          </>
        )}

        {!formSuccess && <>
          <input
            type="text"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Your email"
            required
          />

          {formSuccess === false && (
            <span className="validation-message">{responseMsg}</span>
          )}

          <button
            type="submit"
            disabled={email.length === 0}
          >
            request a new password
          </button>
        </>}
      </form>
    </div>
  );
};

export default ForgotPassword;
