import React, { useMemo, useEffect } from "react";
import AdminCard from "../../components/ui/cards/AdminCard";
import MainNavigation from "../../components/navigation/MainNavigation";
import useCheckUserPermissions from "../../customHooks/useCheckUserPermissions";
import MobileNavigation from "../../components/navigation/MobileNavigation";
import { useSelector } from "react-redux";
import "./Admin.scss";

const Admin = () => {
  const checkUserPermissions = useCheckUserPermissions();
  const isMobileNavigationShown = useSelector((state: any) => state.mobile.isNavigationOpen);

  useEffect(() => {
    document.title = "Admin - Smartocto real-time";
  }, []);

  const cardOptions = useMemo(() => {
    const optionsForAdmin = [
      {
        id: 1,
        title: "Users",
        description: "Add, edit, remove and invite users inside Smartocto",
        linkPath: "users",
      },
      {
        id: 2,
        title: "Notifiers",
        description: "Modify the notifier settings of this domain",
        linkPath: "notifiers",
      },
      {
        id: 3,
        title: "Imports",
        description: "Modify the import settings of this domain",
        linkPath: "imports",
      },
      {
        id: 7,
        title: "Credit usage",
        description: "See how the credits are used over the brands",
        linkPath: "creditUsage",
      },
      {
        id: 8,
        title: "Brands",
        description: "Modify brand settings",
        linkPath: "brands",
      },
      {
        id: 9,
        title: "Umbrella Brands",
        description: "Add, modify and remove unbrella brands",
        linkPath: "umbrellaBrands",
      },
    ];

    const optionsForSuperUser = [
      {
        id: 1,
        title: "Users",
        description: "Add, edit, remove and invite users inside Smartocto",
        linkPath: "users",
      },
    ];

    if (checkUserPermissions("admin")) {
      return optionsForAdmin;
    }
    if (checkUserPermissions("superuser")) {
      return optionsForSuperUser;
    }

    return [];
  }, [checkUserPermissions]);

  return (
    <>
      <MainNavigation />
      <MobileNavigation title="Admin" />
      <div className={`cards-container ${isMobileNavigationShown ? "mobile-menu-shown" : ""}`}>
        {cardOptions.map((option) => {
          return <AdminCard {...option} key={option.id} />;
        })}
      </div>
    </>
  );
};

export default Admin;
