import React, { useMemo, useState, memo } from "react";
import {
  BellIcon,
  HomeIcon,
  MailIcon,
  FacebookIcon,
  LogoIcon,
  DiscIcon,
  InkButton,
  BoostedIcon,
} from "../../icons/Icons";
import TimeAgoShort from "../utils/TimeAgoShort";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SparkGraph from "../graphs/SparkGraph";
import { saveNotificationAsInked } from "../../store/slices/inked";
import { fetchFeedInkedItems } from "../../store/slices/feed";
import { useAppDispatch } from "../../store";
import { toggleNavigation } from "../../store/slices/mobile";
import "./NavigationListItem.scss";
import { useSelector } from "react-redux";

type Props = {
  title: string;
  type?: "primary" | "secondary";
  subtitle?: string;
  description: string;
  itemType?: "acts" | "updates" | "tentacles" | "abTestSecretTreasure";
  itemData?: any;
  paid?: boolean;
  secondaryPosts?: any;
  postId: string;
  postType?: string;
  itemId?: string;
  inked?: boolean | number;
  pubDate: number;
  bundleStoryCount?: string;
  sparkData?: Array<any>;
  day?: string;
  page?: string;
};

const NavigationListItem = ({
  title,
  type = "primary",
  description,
  itemType,
  itemData,
  paid,
  secondaryPosts,
  postId,
  postType,
  itemId,
  inked = 0,
  pubDate,
  bundleStoryCount,
  sparkData,
  day,
  page,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [inkedClicked, setInkedClicked] = useState(inked);

  const displayedIcons = useMemo(() => {
    if (!secondaryPosts)
      return (
        <div className="secondary-icons">
          <div className="time">
            <TimeAgoShort timestamp={pubDate} />
          </div>
        </div>
      );
    const secondaryPostsArray = Object.values(secondaryPosts);

    return (
      <div className="secondary-icons">
        <div className="time">
          <TimeAgoShort timestamp={pubDate} />
        </div>
        {
          // @ts-ignore
          secondaryPostsArray?.some((obj) => obj.post_type === "pushmessage") ? (
            <div className="pushmessage-icon"></div>
          ) : null
        }
        {
          // @ts-ignore
          secondaryPostsArray?.some((obj) => obj.post_type === "newsletter") ? <MailIcon color="#ffffffd9" /> : null
        }
        {
          // @ts-ignore
          secondaryPostsArray?.some((obj) => obj.post_type === "linkedin") ? (
            <div className="linkedin-icon"></div>
          ) : null
        }
        {
          // @ts-ignore
          secondaryPostsArray?.some((obj) => obj.post_type === "homepage_link") ? (
            <HomeIcon width="15px" height="15px" color="#ffffffd9" />
          ) : null
        }
        {
          // @ts-ignore
          secondaryPostsArray?.some((obj) => obj.post_type === "fb_post") ? (
            <FacebookIcon width="12px" height="12px" color="#ffffffd9" />
          ) : null
        }
        {secondaryPostsArray?.some((obj) => {
          // @ts-ignore
          return obj.post_type === "tweet" || obj.post_type === "retweet";
        }) ? (
          <div className="tweeter-icon"></div>
        ) : null}
        {
          // @ts-ignore
          secondaryPostsArray?.some((obj) => obj.post_type === "site_link") ? (
            <div className="site-link-icon"></div>
          ) : null
        }
        {
          // @ts-ignore
          secondaryPostsArray?.some((obj) => obj.post_type === "page") ? <div className="origin-icon"></div> : null
        }
        {
          // @ts-ignore
          secondaryPostsArray?.some((obj) => obj.post_type === "video") ? <div className="origin-icon"></div> : null
        }
        {
          // @ts-ignore
          secondaryPostsArray?.some((obj) => obj.post_type === "boosted") ? (
            <div style={{ position: "relative", top: "3.4px" }}>
              <BoostedIcon width="20px" height="20px" />
            </div>
          ) : null
        }
      </div>
    );
  }, [pubDate, secondaryPosts]);

  const displayedStreamIcon = useMemo(() => {
    if (itemData?.notification_type === "headertest_completed") {
      return <div className="checkmark-icon"></div>;
    }
    switch (itemType) {
      case "acts":
        return <DiscIcon width="18px" height="20px" color="#f45642" />;
      case "updates":
      case "abTestSecretTreasure":
        return <BellIcon width="22px" height="22px" color="#ffffffd9" />;
      case "tentacles":
        return <LogoIcon width="25px" height="25px" />;
      default:
        break;
    }
  }, [itemType]);

  const handleNavLinkClick = () => {
    if (window.innerWidth <= 770) {
      dispatch(toggleNavigation());
    }
    if (location.pathname.indexOf("stream") > 0) {
      navigate(`/stream/${postId}`);
    } else if (location.pathname.indexOf("stories") > 0) {
      navigate(`/stories/${postId}`);
    } else if (location.pathname.indexOf("videos") > 0) {
      navigate(`/videos/${postId}`);
    } else if (location.pathname.indexOf("bundles") > 0) {
      navigate(`/bundles/${postId}`);
    } else if (location.pathname.indexOf("inked") > 0) {
      navigate(`/inked/${postId}`);
    } else if (location.pathname.indexOf("pages") > 0) {
      navigate(`/pages/${postId}`);
    }
  };

  const isNavItemActive = useMemo(() => {
    if (!id || !postId) return false;
    else if (id === postId) return true;
  }, [id, postId]);

  const handleSaveAsInked = (e) => {
    e.stopPropagation();
    if (inkedClicked) {
      if (page === "inked") {
        dispatch(saveNotificationAsInked(itemId || "", "delete", fetchFeedInkedItems));
      } else {
        dispatch(saveNotificationAsInked(itemId || "", "delete"));
      }
      setInkedClicked(false);
    } else {
      if (page === "inked") {
      }
      dispatch(saveNotificationAsInked(itemId || "", "create"));
      setInkedClicked(true);
    }
  };

  return (
    <>
      {day && <li className="nav-list-day">{day}</li>}
      <li className={`nav-list-item ${isNavItemActive ? "active" : ""}`} onClick={handleNavLinkClick}>
        {type === "primary" ? (
          <div className="nav-list-item-container">
            <div className="icon-container">{displayedStreamIcon}</div>
            <div className="details-container">
              <div className="description-primary">{title.length > 60 ? title.slice(0, 60) + "..." : title}</div>
              <div className={`title-container--${type}`}>
                <div className={`title--${type}`}>
                  {description.length > 77 ? description.slice(0, 77) + "..." : description}
                </div>
                <div className="time">
                  <TimeAgoShort timestamp={pubDate} />
                </div>
              </div>
            </div>
            <div
              className={`ink-btn-container ${inkedClicked && "active"}`}
              onClick={(e) => {
                handleSaveAsInked(e);
              }}
            >
              <InkButton width="15px" height="15px" />
            </div>
          </div>
        ) : (
          <>
            <SparkGraph data={sparkData} width={280} height={140} isVideo={page === "videos"} />
            <div className="nav-list-item-container">
              <div className="details-container">
                {bundleStoryCount && <div className="bundles-story-count">{bundleStoryCount}</div>}
                <div className={`title-container--${type}`}>
                  <div className={`title--${type}`}>{title.length > 65 ? title.slice(0, 65) + "..." : title}</div>
                  {/* <div className="time">14m</div> */}
                  {type === "secondary" ? (
                    displayedIcons
                  ) : (
                    <div className="time">
                      <TimeAgoShort timestamp={pubDate} />
                    </div>
                  )}
                </div>
              </div>
              {paid ? <div className="paid"></div> : null}
            </div>
          </>
        )}
      </li>
    </>
  );
};

export default NavigationListItem;
