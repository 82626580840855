import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../../components/ui/table/Table";
import MainNavigation from "../../../components/navigation/MainNavigation";
import Button from "../../../components/ui/common/Button";
import Pill from "../../../components/ui/common/Pill";
import { timeStampToDate } from "../../../helpers/dateHelper";
import { DeleteIcon, EditIcon, PasswordIcon, InvitationIcon, PlusIcon } from "../../../icons/Icons";
import Modal from "../../../components/ui/modal/Modal";
import UserFormModal from "../../../components/modals/UserFormModal/UserFormModal";
import EditDisplayedColumnsModal from "../EditDisplayedColumnsModal";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { deleteUser, fetchAllUsers, fetchUsers, inviteUser } from "../../../store/slices/users";
import { CircleLoader } from "../../../icons/Loaders";
import "../Admin.scss";
import CollapsibleCell from "../../../components/ui/table/CollapsibleCell";
import { Buffer } from "buffer";

type BrandState = {
  currentBrand: {
    isLoading: boolean;
    error: Object | null;
    data: {
      features: Array<string>;
      domain: string;
      organisationIdp: string;
    } | null;
  };
};

const defaultUserData = {
  id: "",
  active: true,
  admin: false,
  superUser: false,
  email: "",
  firstName: "",
  lastName: "",
  brands: [],
};

const Users = () => {
  const dispatch = useAppDispatch();
  const usersNavPath = "/admin/users";
  const [userModalOpened, setUserModalOpened] = useState(false);
  const [editingColumns, setEditingColumns] = useState(false);
  const [userEditData, setUserEditData]: [typeof defaultUserData | null, Function] = useState(defaultUserData);
  const [creatingNewUser, setCreatingNewUser] = useState(false);

  const brandInfo = useSelector((state: BrandState) => state.currentBrand.data);
  const users = useSelector((state: any) => state.users.data);
  const isLoading = useSelector((state: any) => state.users.isLoading);

  useEffect(() => {
    document.title = "Admin - Users - Smartocto real-time";
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id && users.length > 0) {
      const decodedId = Buffer.from(id, "base64").toString()
      handleEdit(decodedId);
    }
  }, [id, users]);

  useEffect(() => {
    if (creatingNewUser) return;
    if (!id && Object.keys(userEditData).length > 0) {
      const encodedEmail = Buffer.from(userEditData["email"]).toString('base64')
      navigate(`${usersNavPath}/${encodedEmail}`);
    }
    if (!id && Object.keys(userEditData).length === 0) {
      navigate(usersNavPath);
    }
  }, [id, navigate, userEditData, creatingNewUser]);

  const columns = useMemo(() => {
    return [
      { title: "id", label: "id" },
      { title: "name", label: "name" },
      { title: "email", label: "email" },
      { title: "brands", label: "brands", width: "500px" },
      { title: "active", label: "active" },
      { title: "admin", label: "admin" },
      { title: "superUser", label: "super user" },
      { title: "noPass", label: "no pass" },
      { title: "lastLogin", label: "last login" },
      { title: "resetDate", label: "reset date" },
      { title: "actions", label: "actions" },
    ];
  }, []);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const handleEdit = (id) => {
    const userData = users.filter((item) => item.email === id)[0];
    if (userData) {
      setUserModalOpened(true);
      setUserEditData(userData);
    }
  };

  const handleDelete = (id) => {
    const confirm = window.confirm("Are you sure?");

    if (confirm) {
      dispatch(deleteUser(id));
    }
  };

  const handleInvite = async (user, generatePassword) => {
    const confirm = window.confirm("Are you sure?");
    if (confirm) {
      if (user.brands && user.brands.length >= 1) {
        const inviteBrand = user.brands[0];

        const hostname = window.location.origin;
        const splitIndex = hostname.indexOf("id-auth") === 0 ? 7 : hostname.search(/(\.|-)/);
        if (splitIndex < 0) {
          alert("Unsupported Smartocto URL");
        }
        const suffix = hostname.substring(splitIndex);
        const inviteUrl =
          hostname.substring(splitIndex + 1, splitIndex + 10) === "localhost"
            ? ["http://", inviteBrand, "-localhost:8000"].join("")
            : ["https://", inviteBrand, suffix].join("");

        try {
          await dispatch(inviteUser(user.email, inviteUrl, generatePassword));
          alert("Invitation e-mail has been sent");
        } catch (error) {
          alert("Oops, mail could not be sent, please try again.");
        }
      } else {
        const errorMsg = "Please add a brand to the user so we can send an invite link.";
        alert(errorMsg);
        console.error(errorMsg);
      }
    }
  };

  const getDate = (timestamp) => {
    return timeStampToDate(timestamp);
  };

  const preparedColumns = useMemo(() => {
    try {
      const tablesConfig = JSON.parse(localStorage.tablesConfig);
      const excludedColumns = tablesConfig.users;
      return columns.filter((obj) => !excludedColumns.includes(obj.label));
    } catch {
      return columns;
    }
  }, [columns]);

  const dataWithActions = users.map((e, i) => {
    return {
      ...e,
      sort: {
        id: e?.id,
        name: `${e.firstName} ${e.lastName}`,
        email: e?.email,
        brands: Array.isArray(e?.brands) ? e.brands.join("") : "",
        active: e?.active,
        admin: e?.admin,
        superUser: e?.superUser,
        noPass: e?.noPass,
        lastLogin: e?.lastLoginDate,
        resetDate: e?.resetDate,
      },
      name: (
        <>
          {e.firstName} {e.lastName}
        </>
      ),
      brands: (
        <CollapsibleCell>
          {e.brands.map((brand) => (
            <Pill key={brand}>{brand}</Pill>
          ))}
        </CollapsibleCell>
      ),
      brandsForSearch: Array.isArray(e?.brands) ? e.brands.join("") : "",
      active: e.active ? "yes" : "no",
      admin: e.admin ? "yes" : "no",
      superUser: e.superUser ? "yes" : "no",
      actions: (
        <div
          style={{
            display: "flex",
            verticalAlign: "middle",
          }}
          className="users-table-actions-container"
        >
          <Button
            data-testid={`editButton${i}`}
            size="small"
            title="Edit User"
            variant="warning"
            onClick={() => {
              handleEdit(e.email);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            data-testid={`editButton${i}`}
            size="small"
            title="Delete User"
            variant="danger"
            onClick={() => {
              handleDelete(e.id);
            }}
          >
            <DeleteIcon />
          </Button>

          <Button
            data-testid={`editButton${i}`}
            size="small"
            title={
              brandInfo && brandInfo.organisationIdp !== "smartocto"
                ? "Users using third party login cannot be invited"
                : "Invite User"
            }
            variant="success"
            disabled={brandInfo && brandInfo.organisationIdp !== "smartocto" ? true : false}
            onClick={() => {
              if (brandInfo && brandInfo.organisationIdp !== "smartocto") return;
              handleInvite(e, false);
            }}
          >
            <InvitationIcon />
          </Button>

          <Button
            data-testid={`editButton${i}`}
            size="small"
            title={
              brandInfo && brandInfo.organisationIdp !== "smartocto"
                ? "Password cannot be generated for user that is using third party login"
                : "Generate Password"
            }
            variant="success"
            disabled={brandInfo && brandInfo.organisationIdp !== "smartocto" ? true : false}
            onClick={() => {
              if (brandInfo && brandInfo.organisationIdp !== "smartocto") return;
              handleInvite(e, true);
            }}
          >
            <PasswordIcon />
          </Button>
        </div>
      ),
      noPass: e.passwordSet ? "" : "+",
      resetDate: e.resetDate ? getDate(e.resetDate) : "",
      lastLogin: e.lastLoginDate ? getDate(e.lastLoginDate) : "",
    };
  });

  const handleModalClose = () => {
    navigate(usersNavPath);
    setUserModalOpened(false);
    setCreatingNewUser(false);
    setUserEditData({});
  };

  return (
    <>
      <Modal isOpen={userModalOpened} onClose={handleModalClose}>
        <UserFormModal user={userEditData} setUserModalOpened={setUserModalOpened} creatingNewUser={creatingNewUser} />
      </Modal>
      <Modal
        isOpen={editingColumns}
        onClose={() => {
          setEditingColumns(false);
        }}
      >
        <EditDisplayedColumnsModal tableTitle="users" columns={columns} />
      </Modal>
      <MainNavigation />
      <div className="page-container-no-side-nav">
        <h1 className="admin-page-title">Users </h1>

        <div className="admin-page-header-btns">
          <Button
            style={{ marginLeft: 20, padding: "6px 8px" }}
            variant="success"
            onClick={() => {
              setUserModalOpened(true);
              setUserEditData(null);
              setCreatingNewUser(true);
            }}
          >
            <PlusIcon width="16px" height="16px" />
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            title="Show users of all brands"
            variant="success"
            onClick={() => {
              if (window.confirm("Show all users? This may take a while.")) {
                dispatch(fetchAllUsers());
              }
            }}
          >
            Show users of all brands
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            title="Edit User"
            variant="success"
            onClick={() => {
              setEditingColumns(true);
            }}
          >
            <EditIcon /> Edit Columns
          </Button>
        </div>

        {!isLoading ? (
          <div className="table-container">
            <Table showRowNumbers={true} rowData={dataWithActions} columnsData={preparedColumns} />
          </div>
        ) : (
          <div className="loader-container">
            <CircleLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default Users;
