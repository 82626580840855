import React, { useState } from "react";
import Input from "../../../ui/common/Input";
import Button from "../../../ui/common/Button";
import "./WavesModal.scss";

const WavesModal = ({ data, onSave, onClose }) => {
  const [bigMinutes, setBigMinutes] = useState(data.bigMinutes.value || data.bigMinutes.default);
  const [fastMinutes, setFastMinutes] = useState(data.fastMinutes.value || data.fastMinutes.default);
  const [metricsRefreshTime, setMetricsRefreshTime] = useState(
    data.metricsRefreshTime.value || data.metricsRefreshTime.default,
  );
  const [storyListRefreshTime, setStoryListRefreshTime] = useState(
    data.storyListRefreshTime.value || data.storyListRefreshTime.default,
  );
  const [umbrellaBrandId, setUmbrellaBrandId] = useState(data.umbrellaBrandId.value || data.umbrellaBrandId.default);

  const handleSave = () => {
    onSave({
      bigMinutes: {
        default: data.bigMinutes.default,
        value: bigMinutes,
      },
      fastMinutes: {
        default: data.fastMinutes.default,
        value: fastMinutes,
      },
      metricsRefreshTime: {
        default: data.metricsRefreshTime.default,
        value: metricsRefreshTime,
      },
      storyListRefreshTime: {
        default: data.storyListRefreshTime.default,
        value: storyListRefreshTime,
      },
      umbrellaBrandId: {
        default: data.umbrellaBrandId.default,
        value: umbrellaBrandId,
      },
    });
    onClose();
  };

  return (
    <div className="waves-modal-wrapper">
      <h3>Waves settings</h3>
      <Input
        label="Big Minutes"
        type="number"
        value={bigMinutes}
        onChange={(e) => {
          setBigMinutes(e.target.value);
        }}
        separateLines
      />
      <Input
        label="Fast Minutes"
        type="number"
        value={fastMinutes}
        onChange={(e) => {
          setFastMinutes(e.target.value);
        }}
        separateLines
      />
      <Input
        label="Metrics Refresh Time"
        type="number"
        value={metricsRefreshTime}
        onChange={(e) => {
          setMetricsRefreshTime(e.target.value);
        }}
        separateLines
      />
      <Input
        label="Story List Refresh Time"
        type="number"
        value={storyListRefreshTime}
        onChange={(e) => {
          setStoryListRefreshTime(e.target.value);
        }}
        separateLines
      />
      <Input label="Umbrella Brand Id"
        value={umbrellaBrandId}
        onChange={(e) => {
          setUmbrellaBrandId(e.target.value);
        }}
        separateLines
      />

      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="success" onClick={handleSave}>
          OK
        </Button>
      </div>
    </div>
  );
};

export default WavesModal;
