import React, { useEffect, useMemo, useState } from "react";
import TableRow from "./TableRow";
import TableHeaderCell from "./TableHeaderCell";
import TableTools from "./TableTools";
import "./Table.scss";

type TableProps = {
  rowData: Array<any>;
  columnsData: Array<any>;
  columnsActions?: Array<any>;
  showRowNumbers?: boolean;
  paginationOptions?: Array<number>;
};

const Table = ({ rowData, columnsData, showRowNumbers, paginationOptions = [100, 50, 25, 10] }: TableProps) => {
  const [preparedColumnsData, setPreparedColumnsData] = useState(columnsData);
  const [numberOfResults, setNumberOfResults] = useState(100);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [sortColumns, setSortColumns] = useState([]) as Array<any>;
  const [sortBy, setSortBy] = useState("");

  useEffect(() => {
    if (showRowNumbers) {
      setPreparedColumnsData([{ title: "nmb", label: "#" }, ...columnsData]);
    }
  }, [columnsData, showRowNumbers]);

  const searchFilteredData = useMemo(() => {

    return rowData.filter((obj) => {
      if (obj.hasOwnProperty('search')) {
        return obj['search'].toLowerCase().includes(searchTerm.toLowerCase());
      } else {
        return Object.keys(obj).some((key) => obj[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase()));
      }
    });
  }, [rowData, searchTerm]);
  

  useEffect(() => {
    if (!rowData) return;
    setTotalPages(Math.ceil(searchFilteredData.length / numberOfResults));
    if (rowData.length > 0 && rowData[0].hasOwnProperty('sort'))
      setSortColumns(Object.keys(rowData[0]['sort']));
  },[rowData, searchFilteredData, numberOfResults, setTotalPages]);

  const handleSearch = (term) => {
    setSelectedPage(1);
    setSearchTerm(term);
  };

  const changeSort = (key) => {
    if (sortBy.indexOf(key) === -1) {
      setSortBy(key + '.asc');
    } else {
      const [column, order] = sortBy.split('.');
      const newSort = key + '.' + (order === 'asc' ? 'desc' : 'asc');
      setSortBy(newSort);
    }
  }

  const orderRows = (a, b) => {
    if (!sortBy) return 0;
    const [sortColumn, order] = sortBy.split('.');
    if (!sortColumn || !order) return 0;
    const nameA = a['sort'] && a['sort'][sortColumn] ?
        typeof a['sort'][sortColumn] === 'number' ? a['sort'][sortColumn] : a['sort'][sortColumn].toString().trim().toUpperCase() : '';
    const nameB = b['sort'] && b['sort'][sortColumn] ?
        typeof b['sort'][sortColumn] === 'number' ? b['sort'][sortColumn] :b['sort'][sortColumn].toString().trim().toUpperCase() : '';


    if (nameA < nameB) {
      return order === 'asc' ? -1 : 1;
    }
    if (nameA > nameB) {
      return order === 'asc' ? 1 : -1;
    }

    return 0;
  };

  

  const startSlicingValue = useMemo(() => {
    if (selectedPage === 1) return 0;
    else return (selectedPage - 1) * numberOfResults;
  }, [selectedPage, numberOfResults]);

  return (
    <>
      <TableTools
        paginationOptions={paginationOptions}
        handleSearch={handleSearch}
        numberOfResults={numberOfResults}
        setNumberOfResults={(e) => {
          setNumberOfResults(e);
          setSelectedPage(1);
        }}
        selectedPage={selectedPage}
        setSelectedPage={setSelectedPage}
        totalNumberOfPages={totalPages}
      />
      <table className="so-table">
        <thead>
          <tr>
            {preparedColumnsData &&
              preparedColumnsData.map((e) => (
                <TableHeaderCell sortBy={sortBy} onTableSort={changeSort} key={e.title} columnName={e.title} style={{ width: e.width }} isSortable={sortColumns.indexOf(e.title) !== -1}>
                  {e.label}
                </TableHeaderCell>
              ))}
          </tr>
        </thead>
        <tbody>
          {rowData &&
            searchFilteredData
              .slice(startSlicingValue, startSlicingValue + numberOfResults)
              .sort(orderRows)
              .map((e, index) => (
                <TableRow key={index} data={{ nmb: index + 1, ...e }} columns={preparedColumnsData} />
              ))}
        </tbody>
      </table>
    </>
  );
};

export default Table;
