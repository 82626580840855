import React, { useEffect, useState } from "react";
import Input from "../ui/common/Input";
import { SearchIcon } from "../../icons/Icons";
import { useAppDispatch } from "../../store";
import "./NavigationSearch.scss";

type NavigationSearchProps = {
  searchTerm?: string;
  setSearchTerm?: Function;
  searchFunction: Function;
  selectedFilter?: string;
  selectedSections?: Array<string>;
};

const NavigationSearch = ({ searchTerm, setSearchTerm, searchFunction, selectedFilter, selectedSections }: NavigationSearchProps) => {
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(searchTerm || '');
  }, [searchTerm, setInputValue]);

  const submitSearch = () => {
    if (searchFunction && setSearchTerm) {
      setSearchTerm(inputValue);
      dispatch(searchFunction(selectedFilter, inputValue, selectedSections));
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      submitSearch();
    }
  };

  return (
    <div className="navigation-search">
      <Input
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        onKeyDown={(e) => {
          handleEnterPress(e);
        }}
      />
      <div
        className="search-icon-container"
        onClick={() => {
          submitSearch();
        }}
      >
        <SearchIcon color="#fff" width="25px" height="25px" />
      </div>
    </div>
  );
};

export default NavigationSearch;
