import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  isLoading: boolean;
  streamInitialFetch: boolean;
  storiesInitialFetch: boolean;
  videosInitialFetch: boolean;
  error: Object | null;
  streamData: Array<Object>;

  storiesData: Array<Object>;
  storiesSparkData: Object;

  videosData: Array<Object>;
  videosSparkData: Object;

  inkedData: Array<Object>;
  pagesData: Array<Object>;
};

const INITIAL_STATE = {
  isLoading: true,
  streamInitialFetch: true,
  storiesInitialFetch: true,
  videosInitialFetch: true,
  error: null,
  streamData: [],
  storiesData: [],
  storiesSparkData: {},
  videosData: [],
  videosSparkData: {},
  inkedData: [],
  pagesData: [],
};

const feed = createSlice({
  name: "feed",
  initialState: INITIAL_STATE,
  reducers: {
    fetchFeedStreamItemsStart: (state: State) => {
      if (state.streamInitialFetch) {
        state.isLoading = true;
      }
    },
    fetchFeedStreamItemsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.streamData = action.payload;
      if (state.streamInitialFetch) {
        state.streamInitialFetch = false;
      }
    },
    fetchFeedStreamItemsFailed: (state: State, action) => {
      state.isLoading = false;
      state.streamData = [];
      state.error = action.payload;
    },

    fetchFeedStoriesItemsStart: (state: State) => {
      if (state.storiesInitialFetch) {
        state.isLoading = true;
      }
    },
    fetchFeedStoriesItemsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.storiesData = action.payload;
      if (state.storiesInitialFetch) {
        state.storiesInitialFetch = false;
      }
    },
    fetchFeedStoriesItemsFailed: (state: State, action) => {
      state.isLoading = false;
      state.storiesData = [];
      state.error = action.payload;
    },

    fetchFeedVideoItemsStart: (state: State) => {
      if (state.videosInitialFetch) {
        state.isLoading = true;
      }
    },
    fetchFeedVideoItemsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.videosData = action.payload;
      if (state.videosInitialFetch) {
        state.videosInitialFetch = false;
      }
    },
    fetchFeedVideoItemsFailed: (state: State, action) => {
      state.isLoading = false;
      state.videosData = [];
      state.error = action.payload;
    },

    fetchFeedPagesItemsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchFeedPagesItemsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.pagesData = action.payload;
    },
    fetchFeedPagesItemsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchFeedInkedItemsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchFeedInkedItemsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.inkedData = action.payload;
    },
    fetchFeedInkedItemsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchStoriesSparkDataStart: (state: State) => {},
    fetchStoriesSparkDataSuccess: (state: State, action) => {
      state.storiesSparkData = { ...state.storiesSparkData, [action.payload.post_id]: action.payload.data[0].data };
    },
    fetchStoriesSparkDataFailed: (state: State, action) => {
      state.error = action.payload;
    },

    fetchVideoSparkDataStart: (state: State) => {},
    fetchVideoSparkDataSuccess: (state: State, action) => {
      state.videosSparkData = { ...state.videosSparkData, [action.payload.post_id]: action.payload.data[0].data };
    },
    fetchVideoSparkDataFailed: (state: State, action) => {
      state.error = action.payload;
    },
  },
});

export default feed.reducer;

const {
  fetchFeedStreamItemsStart,
  fetchFeedStreamItemsSuccess,
  fetchFeedStreamItemsFailed,
  fetchFeedStoriesItemsStart,
  fetchFeedStoriesItemsSuccess,
  fetchFeedStoriesItemsFailed,
  fetchFeedVideoItemsStart,
  fetchFeedVideoItemsSuccess,
  fetchFeedVideoItemsFailed,
  fetchFeedPagesItemsStart,
  fetchFeedPagesItemsSuccess,
  fetchFeedPagesItemsFailed,

  fetchFeedInkedItemsStart,
  fetchFeedInkedItemsSuccess,
  fetchFeedInkedItemsFailed,
  fetchStoriesSparkDataStart,
  fetchStoriesSparkDataSuccess,
  fetchStoriesSparkDataFailed,

  fetchVideoSparkDataStart,
  fetchVideoSparkDataSuccess,
  fetchVideoSparkDataFailed,
} = feed.actions;

export const fetchFeedStreamItems =
  (sections: string[] = []) =>
  async (dispatch: Function) => {
    try {
      dispatch(fetchFeedStreamItemsStart());
      const api = new Api();
      const result = await api.getFeedStreamItems(sections);
      dispatch(fetchFeedStreamItemsSuccess(result));
    } catch (err) {
      dispatch(fetchFeedStreamItemsFailed(err));
    }
  };

export const fetchStoriesSparkData = (id: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchStoriesSparkDataStart());
    const api = new Api();
    const result = await api.getStoriesSparkData(id);
    dispatch(fetchStoriesSparkDataSuccess(result));
  } catch (err) {
    dispatch(fetchStoriesSparkDataFailed(err));
  }
};

export const fetchVideoSparkData = (id: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchVideoSparkDataStart());
    const api = new Api();
    const result = await api.getVideoSparkData(id);
    dispatch(fetchVideoSparkDataSuccess(result));
  } catch (err) {
    dispatch(fetchVideoSparkDataFailed(err));
  }
};

export const fetchFeedStoriesItems =
  (filter = "page", searchTerm = "", sections: string[] = []) =>
  async (dispatch: Function, getState: Function) => {
    try {
      dispatch(fetchFeedStoriesItemsStart());
      const api = new Api();
      const result = await api.getFeedStoriesItems(filter, searchTerm, sections);
      const items = result.posts;
      dispatch(fetchFeedStoriesItemsSuccess(result));
      if (items) {
        const state = getState();
        items.forEach((item) => {
          if (!state.feed.storiesSparkData[item.post_id]) {
            dispatch(fetchStoriesSparkData(item.post_id));
          }
        });
      }
    } catch (err) {
      dispatch(fetchFeedStoriesItemsFailed(err));
    }
  };

export const fetchFeedVideosItems =
  (filter = "page", searchTerm = "") =>
  async (dispatch: Function, getState: Function) => {
    try {
      dispatch(fetchFeedVideoItemsStart());
      const api = new Api();
      const result = await api.getFeedVideosItems(filter, searchTerm);
      const items = result.posts;
      dispatch(fetchFeedVideoItemsSuccess(result));
      const state = getState();
      if (items) {
        items.forEach((item) => {
          if (!state.feed.videosSparkData[item.post_id]) {
            dispatch(fetchVideoSparkData(item.post_id));
          }
        });
      }
    } catch (err) {
      dispatch(fetchFeedVideoItemsFailed(err));
    }
  };

export const fetchFeedPagesItems =
  (filter = "page", searchTerm = "") =>
  async (dispatch: Function) => {
    try {
      dispatch(fetchFeedPagesItemsStart());
      const api = new Api();
      const result = await api.getFeedPagesItems(filter, searchTerm);
      dispatch(fetchFeedPagesItemsSuccess(result));
    } catch (err) {
      dispatch(fetchFeedPagesItemsFailed(err));
    }
  };

export const fetchFeedInkedItems = () => async (dispatch: Function) => {
  try {
    dispatch(fetchFeedInkedItemsStart());
    const api = new Api();
    const result = await api.getFeedInkedItems();
    dispatch(fetchFeedInkedItemsSuccess(result));
  } catch (err) {
    dispatch(fetchFeedInkedItemsFailed(err));
  }
};
