import { useCallback } from "react";
import { useSelector } from "react-redux";

const useCheckUserPermissions = () => {
  const userInfo = useSelector((state: any) => state.currentUser.data);

  const checkIfAllowed = useCallback(
    (userStatus: "admin" | "superuser") => {
      if (userStatus === "admin") {
        return userInfo.admin || false;
      }
      if (userStatus === "superuser") {
        return userInfo.superUser || false;
      }
    },
    [userInfo],
  );

  return checkIfAllowed;
};

export default useCheckUserPermissions;
