export const FEATURES = [
  {
    name: "bundles",
    hasSettings: false,
  },
  {
    name: "insights",
    hasSettings: true,
  },
  {
    name: "smartify",
    hasSettings: false,
  },
  {
    name: "stories",
    hasSettings: true,
  },
  {
    name: "readingtime",
    hasSettings: false,
  },
  {
    name: "tentacles",
    hasSettings: true,
  },
  {
    name: "videos",
    hasSettings: true,
  },
  {
    name: "waves",
    hasSettings: true,
  },
  {
    name: "goals",
    hasSettings: false,
  },
  {
    name: "recommender",
    hasSettings: false,
  },
  {
    name: "landingPages",
    hasSettings: false,
  },
  {
    name: "ai",
    hasSettings: true,
  },
];
