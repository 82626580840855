import React, { useEffect, useState } from "react";
import Input from "../../ui/common/Input";
import Button from "../../ui/common/Button";
import { fetchBundles } from "../../../store/slices/bundles";
import { useAppDispatch } from "../../../store";
import { useSelector } from "react-redux";
import { saveItemToBundle, createNewBundle } from "../../../store/slices/bundles";
import "./AddItemToBundleModal.scss";

const AddItemToBundleModal = ({ postId, closeModal, updateShownBundles }) => {
  const dispatch = useAppDispatch();
  const bundles = useSelector((state: any) => state.bundles.data);
  const [newBundleName, setNewBundleName] = useState("");

  useEffect(() => {
    dispatch(fetchBundles());
  }, []);

  const handleSaveItemToBundle = (bundle_id) => {
    if (postId) {
      dispatch(saveItemToBundle(bundle_id.toString(), postId.toString(), updateShownBundles));
      closeModal();
    }
  };

  const handleCreateAndAddToBundle = () => {
    dispatch(createNewBundle(0, newBundleName, postId, updateShownBundles));
    closeModal();
  };

  return (
    <div className="add-item-to-bundle-modal-container">
      <div className="bundle-input-header">
        <h2>Add this story to a bundle</h2>
        <div className="input-container">
          <Input
            placeholder="find or create bundle"
            onChange={(e) => {
              setNewBundleName(e.target.value);
            }}
          />{" "}
          <Button variant="success" onClick={handleCreateAndAddToBundle} disabled={newBundleName.length < 2}>
            Create and add
          </Button>
        </div>
      </div>
      <div className="available-bundles-container">
        <div className="title">All bundles</div>
        {bundles.map((bundle) => {
          return (
            <div
              key={bundle.bundle_id}
              className="bundle-to-select"
              onClick={() => {
                handleSaveItemToBundle(bundle.bundle_id);
              }}
            >
              {bundle.bundle_name} <span>{bundle.bundle_story_count}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddItemToBundleModal;
