import React, { useMemo } from "react";
import { NoDataIcon } from "../../icons/Icons";
import { Link } from "react-router-dom";
import "./DataNotAvailable.scss";

type DataNotAvailableProps = {
  pageType: string;
};

const DataNotAvailable = ({ pageType }: DataNotAvailableProps) => {
  const message = useMemo(() => {
    if (pageType === "stream") return "stream items";
    if (pageType === "inked") return "inked items";
    return pageType;
  }, [pageType]);

  const contentToShow = useMemo(() => {
    if (pageType === "stream" || pageType === "stories" || pageType === "videos" || pageType === "pages") {
      return (
        <>
          <div className="logo"></div>
          <p className="no-items-stay-alert">will stay alert!</p>
        </>
      );
    }

    if (pageType === "inked") {
      return (
        <>
          <p className="no-items-suggestion">
            you can always mark any <Link to="/stream">stream</Link> item as inked!
          </p>
        </>
      );
    }

    if (pageType === "bundles") {
      return (
        <>
          <p className="no-items-suggestion">
            you can always add some <Link to="/stories">stories</Link> to a bundle!
          </p>
        </>
      );
    }
  }, []);

  return (
    <div className="data-not-available-wrapper">
      <div className="data-not-available-container">
        <div className="no-data-icon-container">
          <NoDataIcon color="#145456" width="100" height="100" />
        </div>
        <p className="no-items-message">There are currently no {message} to show, but </p>
        {contentToShow}
      </div>
    </div>
  );
};

export default DataNotAvailable;
