import React from "react";
import { LogoIcon } from "../icons/Icons";
import "./LoadingPage.scss";
import { CircleLoader, DotsLoader } from "../icons/Loaders";

const LoadingPage = () => {
  return (
    <div className="loading-page-container">
      <div>
        <div className="please-wait-message">
          <span>Please wait </span>
          <div className="dots-loader-container">
            <DotsLoader width="30px" height="30px" />
          </div>
        </div>
        <div className="logo-container">
          <LogoIcon />
        </div>
        <div className="loading-message">
          <div className="dots-loader-container">
            <DotsLoader width="30px" height="30px" color="white" />
          </div>
          <span>is loading</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
