import React, { useCallback, useState } from "react";

import Button from "../../../ui/common/Button";
import "./TentaclesSettingsModal.scss";
import TentaclesSettingsForm from "./tentaclesSettingsForms/TentaclesSettingsForm";
import ABTestSettingsForm from "./tentaclesSettingsForms/ABTestSettingsFrom";
import ClassificationsForm from "./tentaclesSettingsForms/ClassificationsForm";
import TentaclesInfoForm from "./tentaclesSettingsForms/TentaclesInfoForm";

const checkForJSONStatus = (json) => {
  try {
    return JSON.stringify(JSON.parse(json), null, 2);
  } catch {
    return JSON.stringify(json, null, 2);
  }
};

const prepareSettingsData = (data) => {
  return {
    ...data
  };
};

const prepareClassificationsData = (data) => {
  return {
    ...data,
    clicks: {
      default: checkForJSONStatus(data.clicks.default),
      value: checkForJSONStatus(data.clicks.value),
    },
    loyalty: {
      default: checkForJSONStatus(data.loyalty.default),
      value: checkForJSONStatus(data.loyalty.value),
    },
  };
};

const TentaclesSettingsModal = ({ data, onSave, onClose }) => {
  const [settingsData, setSettingsData] = useState(prepareSettingsData(data.settings));
  const [abTestSettings, setAbTestSettings] = useState(data.abTestSettings);
  const [classifications, setClassifications] = useState(prepareClassificationsData(data.classifications));
  const [tentaclesInfo, setTentaclesInfo] = useState(data.tentaclesInfo);

  const handleSettingsChanges = useCallback(
    (attribute, value) => {
      const newAttributeValue = { ...settingsData[attribute], value: value };
      setSettingsData({ ...settingsData, [attribute]: newAttributeValue });
    },
    [settingsData],
  );

  const handleAbTestSettingsChanges = useCallback(
    (attribute, value) => {
      const newAttributeValue = { ...abTestSettings[attribute], value: value };
      setAbTestSettings({ ...abTestSettings, [attribute]: newAttributeValue });
    },
    [abTestSettings],
  );

  const handleClassificationsChanges = useCallback(
    (attribute, value) => {
      const newAttributeValue = { ...classifications[attribute], value: value };
      setClassifications({ ...classifications, [attribute]: newAttributeValue });
    },
    [classifications],
  );

  const handleTentaclesInfoChanges = useCallback(
    (attribute, value) => {
      const newAttributeValue = { ...tentaclesInfo[attribute], value: value };
      setTentaclesInfo({ ...tentaclesInfo, [attribute]: newAttributeValue });
    },
    [tentaclesInfo],
  );

  const handleSave = () => {
    onSave({
      abTestSettings,
      classifications,
      settings: settingsData,
      tentaclesInfo,
    });
  };

  return (
    <div className="tentacles-settings-modal-wrapper">
      <h3>Tentacles Settings</h3>
      <div className="tentacles-settings-container">
        <div className="general-tentacles-settings">
          <h4>Settings</h4>
          <TentaclesSettingsForm data={settingsData} handleChanges={handleSettingsChanges} />
        </div>
        <div className="abtest-settings">
          <h4>AB Test Settings</h4>
          <ABTestSettingsForm data={abTestSettings} handleChanges={handleAbTestSettingsChanges} />
        </div>
        <div className="tentacles-settings-classification">
          <h4>Classifications</h4>
          <ClassificationsForm data={classifications} handleChanges={handleClassificationsChanges} />
        </div>
        <div className="tentacles-settings-info">
          <h4>Tentacles Info</h4>
          <TentaclesInfoForm data={tentaclesInfo} handleChanges={handleTentaclesInfoChanges} />
        </div>
      </div>

      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            handleSave();
            onClose();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default TentaclesSettingsModal;
