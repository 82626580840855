import Input from "../../ui/common/Input";
import React from "react";

type StatisticsSettingsProps = {
  showStatisticsSettings: boolean;
  handleToggleStatistics: Function;
  onInputChange: Function;
  data: any;
};

const StatisticsSettings = ({
  showStatisticsSettings,
  handleToggleStatistics,
  onInputChange,
  data,
}: StatisticsSettingsProps) => {
  return (
    <div>
      <div
        className="brand-settings-title first"
        onClick={() => {
          handleToggleStatistics();
        }}
      >
        Statistics
      </div>

      <div className={["brand-settings-container", "statistics", showStatisticsSettings && "active"].join(" ")}>
        <div className="statistics-wrapper">
          <div>
            <h4>At Internet Auth</h4>
            <Input
              label="Client Email"
              separateLines
              value={data.atInternetAuth.clientEmail.value || data.atInternetAuth.clientEmail.default}
              onChange={(e) => onInputChange(e.target.value, "atInternetAuth", "clientEmail")}
            />
            <Input
              label="Private Key"
              separateLines
              value={data.atInternetAuth.privateKey.value || data.atInternetAuth.privateKey.default}
              onChange={(e) => onInputChange(e.target.value, "atInternetAuth", "privateKey")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatisticsSettings;
