import { useCallback } from "react";

const useGetEnvironment = () => {
  const location = window.location.hostname;

  const getEnvironment = useCallback(() => {
    if (location.includes("staging2")) {
      return "staging2";
    }
    if (location.includes("staging")) {
      return "staging";
    }
    if (location.includes("localhost")) {
      return "localhost";
    }
    return "production";
  }, [location]);

  return getEnvironment;
};

export default useGetEnvironment;
