import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<Object>;
  isLoading: boolean;
  error: Object | null;
};

const algorithms = createSlice({
  name: "algorithms",
  initialState: {
    data: [],
    isLoading: true,
    error: null,
  },
  reducers: {
    fetchAlgorithmsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchAlgorithmsSuccess: (state: State, action) => {
      state.isLoading = false;
      
      const activeAlgorithms = action.payload.filter(item => {
        return item.active && item.hasOwnProperty('filteringTags') && !arrayItemStartWith(item.filteringTags, 'system') && item.hasOwnProperty('params')
      }).map(algorithmItemMapper).sort((a, b) => a.title.localeCompare(b.title));

      const valueplusAlgorithms = activeAlgorithms.filter(item => arrayItemStartWith(item.filteringTags, 'channel/valueplus'));
      const socialAlgorithms = activeAlgorithms.filter(item => arrayItemStartWith(item.filteringTags, 'channel/social'));
      const homepageAlgorithms = activeAlgorithms.filter(item => arrayItemStartWith(item.filteringTags, 'channel/home'));
      const webAppAlgorithms = activeAlgorithms.filter(item => arrayItemStartWith(item.filteringTags, 'channel/web'));

      state.data = [
        {
          id: 'all',
          label: 'All',
          limit: activeAlgorithms.length,
          items: activeAlgorithms
        },
        {
          id: 'social',
          label: 'Social',
          limit: socialAlgorithms.length,
          items: socialAlgorithms
        },
        {
          id: 'homepage',
          label: 'Homepage',
          limit: homepageAlgorithms.length,
          items: homepageAlgorithms
        },
        {
          id: 'webapp',
          label: 'Web/App',
          limit: webAppAlgorithms.length,
          items: webAppAlgorithms
        },
        {
          id: 'valueplus',
          label: 'Value Plus',
          limit: valueplusAlgorithms.length,
          items: valueplusAlgorithms
        },
      ];
    },
    fetchAlgorithmsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default algorithms.reducer;

const { fetchAlgorithmsStart, fetchAlgorithmsSuccess, fetchAlgorithmsFailed } = algorithms.actions;

export const fetchAlgorithms = () => async (dispatch: Function) => {
  try {
    dispatch(fetchAlgorithmsStart());
    const api = new Api();
    const result = await api.getAlgorithms();
    dispatch(fetchAlgorithmsSuccess(result));
  } catch (err) {
    dispatch(fetchAlgorithmsFailed(err));
  }
};

/**
 * Check an array for the existence of an element that start with {word}
 * @param {array} items - Array of elements to search trough.
 * @param {string} word - String to search for
 * @returns {string|undefined}
 */
const arrayItemStartWith = (items, word) => {
  return items.find(item => item.lastIndexOf(word, 0) === 0);
}

const algorithmItemMapper = (item) => {
  const title = item.params.hasOwnProperty('message') ? item.params.hasOwnProperty('info') ? `${item.params.message}. ${item.params.info.join(' ')}` : item.params.message : item.type;
  return {
    id: item.id,
    type: item.name,
    title: title,
    filteringTags: item.filteringTags
  }
}