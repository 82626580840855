import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import Button from "../components/ui/common/Button";
import useGetEnvironment from "../customHooks/useGetEnvironment";
import "./Current.scss";

const Current = () => {
  const brandDomain = useSelector((state: any) => state.currentBrand.data.domain);
  const [isFullScreen, setIsFullSreen] = useState(false);
  const [versionTest, setVersionTest] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const getEnvironment = useGetEnvironment();
  const fullScreenParams = new URLSearchParams("mode");

  useEffect(() => {
    document.title = "Current Dashboard - Smartocto real-time";
  }, []);

  var elem = document.documentElement;

  /* View in fullscreen */
  const openFullscreen = () => {
    setIsFullSreen(true);
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      //@ts-ignore
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      //@ts-ignore
      elem.webkitRequestFullscreen();
      //@ts-ignore
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      //@ts-ignore
      elem.msRequestFullscreen();
    }
    fullScreenParams.set("mode", "tv");
    navigate({
      pathname: location.pathname,
      search: fullScreenParams.toString(),
    });
  };

  useEffect(()=>{
    if (location.search.includes("mode=tv")) {
      setIsFullSreen(true);
    }
    if (location.search.includes("version=test")) {
      setVersionTest(true)
    }
  },[])

  useEffect(() => {
    const exitHandler = () => {
      //@ts-ignore
      if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        setIsFullSreen(false);
        navigate({
          pathname: location.pathname,
          search: "",
        });
      }
    };
    document.addEventListener("fullscreenchange", exitHandler, false);
    document.addEventListener("mozfullscreenchange", exitHandler, false);
    document.addEventListener("MSFullscreenChange", exitHandler, false);
    document.addEventListener("webkitfullscreenchange", exitHandler, false);

    return () => {
      document.removeEventListener("fullscreenchange", exitHandler, false);
      document.removeEventListener("mozfullscreenchange", exitHandler, false);
      document.removeEventListener("MSFullscreenChange", exitHandler, false);
      document.removeEventListener("webkitfullscreenchange", exitHandler, false);
    };
  }, []);

  const iframeURL = useMemo(() => {
    const environment = getEnvironment();
    if (environment === "production") return `https://${brandDomain}-waves.smartocto.com/dashboards/current${versionTest ? "?version=test" : ""}`;
    if (environment === "staging2") return `https://${brandDomain}-staging2-waves.smartocto.com/dashboards/current${versionTest ? "?version=test" : ""}`;
    if (environment === "staging") return `https://${brandDomain}-staging-waves.smartocto.com/dashboards/current${versionTest ? "?version=test" : ""}`;
    if (environment === "localhost") return `https://${brandDomain}-staging2-waves.smartocto.com/dashboards/current${versionTest ? "?version=test" : ""}`;
  }, [brandDomain, getEnvironment, versionTest]);

  return (
    <div className="current-wrapper">
      {!isFullScreen && (
        <div className="full-screen-btn-container">
          <Button variant="success" onClick={openFullscreen}>
            Switch to TV mode
          </Button>
        </div>
      )}

      <iframe className="current-iframe" src={iframeURL}></iframe>
    </div>
  );
};

export default Current;
