import React, { useState } from "react";
import Input from "../../../ui/common/Input";
import "./InsightsSettingsModal.scss";
import Button from "../../../ui/common/Button";

const InsightsSettingsModal = ({ data, onSave, onClose }) => {
  const [ciApiKey, setCiApiKey] = useState(data.ciApiKey.value || data.ciApiKey.default);
  const [domainId, setDomainId] = useState(data.domainId.value || data.domainId.default);

  const handleSave = () => {
    onSave({
      ciApiKey: {
        default: data.ciApiKey.default,
        value: ciApiKey,
      },
      domainId: {
        default: data.domainId.default,
        value: domainId,
      },
    });
    onClose();
  };

  return (
    <div className="insights-settings-modal-wrapper">
      <h4>Insights Settings</h4>
      <Input
        id="token"
        label="Ci API Key"
        value={ciApiKey}
        onChange={(e) => {
          setCiApiKey(e.target.value);
        }}
        separateLines
        fullWidth
      />
      <Input
        id="token"
        label="Domain Id"
        value={domainId}
        onChange={(e) => {
          setDomainId(e.target.value);
        }}
        separateLines
        fullWidth
      />
      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="success" onClick={handleSave}>
          OK
        </Button>
      </div>
    </div>
  );
};

export default InsightsSettingsModal;
