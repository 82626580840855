import React from "react";
// @ts-ignore
import smartoctoAILoading from "../../assets/images/smartocto_ai.gif";
import "./SmartoctoAILoading.scss";

const SmartoctoAILoading = () => {
  return (
    <div className="smartocto-ai-loading-page">
      <div className="smartocto-ai-loader-container">
        <img src={smartoctoAILoading} alt="" />
      </div>
    </div>
  );
};

export default SmartoctoAILoading;
