import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./StreamTableNavigation.scss";

const StreamTableNavigation = ({ setSelectedTable, data, setShowedAll }) => {
  const { t } = useTranslation();
  const [navItems, setNavItems] = useState<any>();

  useEffect(() => {
    setNavItems([
      {
        title: "All",
        translatedTitle: t("stream.table.all"),
        active: true,
        disabled: data["All"]?.length === 0,
      },
      {
        title: "Acts",
        translatedTitle: t("stream.table.acts"),
        active: false,
        disabled: data["Acts"]?.length === 0,
      },
      {
        title: "Updates",
        translatedTitle: t("stream.table.updates"),
        active: false,
        disabled: data["Updates"]?.length === 0,
      },
      {
        title: "Comments",
        translatedTitle: t("stream.table.comments"),
        active: false,
        disabled: data["Comments"]?.length === 0,
      },
      {
        title: "Tentacles",
        translatedTitle: t("stream.table.tentacles"),
        active: false,
        disabled: data["Tentacles"]?.length === 0,
      },
    ]);
  }, [setNavItems, data]);

  const handleNavItemClick = (navItem) => {
    if (navItem.disabled) return;
    setSelectedTable(navItem.title);
    setNavItems(
      navItems.map((e) => {
        if (e.title === navItem.title) e.active = true;
        else e.active = false;
        return e;
      }),
    );
    setShowedAll(false);
  };

  return (
    <div className="stream-table-navigation">
      {navItems?.map((e) => {
        return (
          <div
            className={`stream-table-nav-item
                          ${e.active ? "active" : null}
                          ${e.disabled ? "disabled" : null}`}
            key={e.title}
            onClick={() => {
              handleNavItemClick(e);
            }}
          >
            {e.translatedTitle}
          </div>
        );
      })}
    </div>
  );
};

export default StreamTableNavigation;
