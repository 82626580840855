import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LogoIcon } from "../icons/Icons";

import "./ResetPassword.scss";
import Api from "../store/api/Api";
import LoadingPage from "./LoadingPage";

const ResetPassword = () => {
  const API = new Api();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [tokenIsValid, setTokenIsValid] : [boolean|null, Function] = useState(null);
  const [formSuccess, setFormSuccess]: [boolean|null, Function] = useState(null);
  const [responseMsg, setResponseMsg] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const handleSubmit = (e) => {
    setFormSuccess(null);
    setResponseMsg("");

    API.setPassword({ new_password: password, token: token, userid: email }).then(response => {
      if (response.result === 'success') {
        setFormSuccess(true);
        setResponseMsg(response.message);
      } else {
        setFormSuccess(false);
        setResponseMsg(response.message);
      }
    })
    .catch(err => {
      setFormSuccess(false);
      if (err?.response?.data?.message) {
        setResponseMsg(err?.response?.data?.message);
      }
    });
  };

  const handleRequestNewLink = () => {
    setFormSuccess(null);
    setResponseMsg("");
    API.forgotPassword(email).then(response => {
      if (response.result === 'success') {
        setFormSuccess(true);
        setResponseMsg(response.message);
      } else {
        setFormSuccess(false);
        setResponseMsg(response.message);
      }
    })
    .catch(err => {
      setFormSuccess(false);
      if (err?.response?.data?.message) {
        setResponseMsg(err?.response?.data?.message);
      }
    });
  }

  if (!email || !token) {
    navigate('/login', { replace: true })
  }

  useEffect(() => {
    API.checkToken({
      token: token,
      userid: email
    }).then((response) => {
      setTokenIsValid(response.result === 'success' ? true : false);
    }).catch(err => {
      setTokenIsValid(false);
    });
  }, []);

  return tokenIsValid === null ? <LoadingPage /> : (
    <div className="new-user-page">
      <form
        onSubmit={(e) => {
          e.preventDefault();
        
          handleSubmit(e); 
        }}
      >
        <div className="login-logo-container">
          <LogoIcon />
        </div>

        {tokenIsValid && (
          <>
            {formSuccess && (
              <>
                <p>{responseMsg}</p>
                <p>
                  <Link to={'/login'}>[ Back to the login screen ]</Link>
                </p>
              </>
            )}

            {!formSuccess && <>
              <input
                type="password" 
                name="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                placeholder="Fill your desired password here"
                required
              />

              {formSuccess === false && (
                <span className="validation-message">{responseMsg}</span>
              )}

              <button
                type="submit"
                disabled={password.length === 0}
              >
                new password
              </button>
            </>}
          </>
        )}
        
        {!tokenIsValid && (
          <>
            {formSuccess && (
              <>
                <p>{responseMsg}</p>
                <p>
                  <Link to={'/login'}>[ Back to the login screen ]</Link>
                </p>
              </>
            )}

            {!formSuccess && (
              <div>
                <p>
                  You're using an <strong>invalid</strong> or <strong>expired</strong> token, <br/>press the button below to request a new one.
                </p>

                <button
                  type="button" 
                  className="c-button" 
                  onClick={handleRequestNewLink}
                >
                  Send new forgot password link
                </button>

                {formSuccess === false && (
                  <span className="validation-message">{responseMsg}</span>
                )}

                <p>
                  <Link to={'/login'}>[ Back to the login screen ]</Link>
                </p>
              </div>
            )}
          </>
        )}
      </form>
    </div>
  );
};

export default ResetPassword;
