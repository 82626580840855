import React from "react";
import Button from "../ui/common/Button";
import "./NotifiersActions.scss";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { deleteTempListeners, fetchNotifiers, sendMailReports, switchMailReports } from "../../store/slices/notifiers";

type BrandState = {
  currentBrand: {
    isLoading: boolean;
    error: Object | null;
    data: {
      features: Array<string>;
      domain: string;
    } | null;
  };
};

const NotifiersActions = () => {
  const dispatch = useAppDispatch();
  const brandInfo = useSelector((state: BrandState) => state.currentBrand.data);
  const mailReportResponse = useSelector((state: any) => state.notifiers.mailReportResponse);

  const handleMailreportsOn = () => {
    if (brandInfo && brandInfo.domain) {
      const confirmed = window.confirm(`Are you sure you want to switch back on all mailreports which were recently switched off for ${brandInfo.domain}?`);

      if (confirmed) {
        dispatch(switchMailReports('active')).then(() => {
          dispatch(fetchNotifiers());
        });
      }
    }
  };

  const handleMailreportsOff = () => {
    if (brandInfo && brandInfo.domain) {
      const confirmed = window.confirm(`Are you sure you want to switch off all mailreports for ${brandInfo.domain}?`);
  
      if (confirmed) {
        dispatch(switchMailReports('inactive')).then(() => {
          dispatch(fetchNotifiers());
        });
      }
    }
  };

  const handleSendMailReports = () => {
    const confirmed = window.confirm('Are you sure you want the re-send all mailreports which were scheduled today from 00:00 till now to all subscribed users?');

    if (confirmed) {
      dispatch(sendMailReports()).then(() => {
        dispatch(fetchNotifiers());
      });
    }
  };

  const handleDeleteTempListeners = () => {
    const confirmed = window.confirm('Are you sure you want to delete all listeners for this brand ending in \'_temp\'?');

    if (confirmed) {
      dispatch(deleteTempListeners()).then(() => {
        dispatch(fetchNotifiers());
      });
    }
  };

  return (
    <>
      <div className="notifiers-actions-container">
        <Button
          variant="success"
          onClick={() => {
            handleMailreportsOn();
          }}
        >
          Mailreports on
        </Button>

        <Button
          variant="danger"
          onClick={() => {
            handleMailreportsOff();
          }}
        >
          Mailreports off
        </Button>

        <Button
          variant="success"
          onClick={() => {
            handleSendMailReports();
          }}
        >
          Send today's mailreports
        </Button>

        <Button
          variant="success"
          onClick={() => {
            handleDeleteTempListeners();
          }}
        >
          Delete temp listeners
        </Button>
      </div>
          
      {mailReportResponse && (
        <div className="notifiers-actions-response">
          {mailReportResponse}
        </div>
      )}
    </>
  );
};

export default NotifiersActions;
