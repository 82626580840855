import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import MainNavigation from "../../components/navigation/MainNavigation";
import Table from "../../components/ui/table/Table";
import { fetchCredits } from "../../store/slices/credits";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import Button from "../../components/ui/common/Button";
import useGetEnvironment from "../../customHooks/useGetEnvironment";


const CreditUsage = () => {
  const dispatch = useAppDispatch();
  const credits = useSelector((state: any) => state.credits.data);
  const navigate = useNavigate();
  const getEnvironment = useGetEnvironment();

  useEffect(() => {
    dispatch(fetchCredits());
  }, [dispatch]);

  useEffect(() => {
    document.title = "Credit List - Smartocto real-time";
  }, []);

  const columns = useMemo(() => {
    return [
      { title: "name", label: "Brand" },
      { title: "requests", label: "Total requests (current deal)" },
      { title: "totalCredits", label: "Total credits (current deal)" },
      { title: "remainingCredits", label: "Remaining credits" },
      { title: "remainingDays", label: "Remaining days" },
      { title: "actions", label: "" },
    ];
  }, []);

  const environment = getEnvironment();
  const dataWithActions = useMemo(() => {
    return credits.map((e) => {
      const smartoctoBrandUrl = (brandDomain) => {
        if (environment === "production") return `https://${brandDomain}.smartocto.com`;
        if (environment === "staging2") return `https://${brandDomain}-staging2.smartocto.com`;
        if (environment === "staging") return `https://${brandDomain}-staging.smartocto.com`;
        if (environment === "localhost") return `https://${brandDomain}.smartocto.com`;
      };
    
      return {
        ...e,
        sort: {name: e?.name , requests: e?.requests, totalCredits: e?.totalCredits, remainingCredits: e?.remainingCredits, remainingDays: e?.remainingDays},
        remainingDays: (
            <div style={{ backgroundColor: e.appochingEndOfDeal ? 'yellow' : 'transparent', fontWeight: e.appochingEndOfDeal ? 'bold' : ''  }}>
            {e.remainingDays} {e.remainingDays === 1 ? "day" : "days"}
            </div>
        ),
        remainingCredits: (
            <div style={{ backgroundColor: e.appochingCreditLimit ? 'yellow' : 'transparent', fontWeight: e.appochingCreditLimit ? 'bold' : '' }}>
            {e.remainingCredits} {e.remainingCredits === 1 ? "credit" : "credits"}
            </div>
      ),

        actions: (
          <>
            <Button
              // className="circle-button-icon"
              data-testid={`editButton`}
              title="Credit insights"
              variant="success"
              onClick={() => {
                if (window.location.hash === "") {
                  window.open(`${smartoctoBrandUrl(e.id)}/reports/credits`, "_blank");
                } else {
                  window.open(`${smartoctoBrandUrl(e.id)}/#/reports/credits`, "_blank");
                }
              }}
            >
              Insights
            </Button>
            &nbsp;
          <Button
            // className="circle-button-icon"
            data-testid={`editButton`}
            title="Edit Deals"
            variant="success"
            onClick={() => {
              if (window.location.hash === "") {
                window.open(`/admin/brands/${e.id}/ai`, "_blank");
              } else {
                window.open(`/#/admin/brands/${e.id}/ai`, "_blank");
              }
            }}
          >
            Deals
          </Button>
        </>
        ),
      };
    });
  }, [credits]);

  return (
    <>
      <MainNavigation />
      <div className="page-container-no-side-nav">
        <h1 className="admin-page-title">Credit usage </h1>
        {/* <Button
          style={{ marginLeft: 40 }}
          title="Edit User"
          variant="success"
          onClick={() => {
            setEditingColumns(true);
          }}
        >
          <EditIcon /> Edit Columns
        </Button> */}
        {/* {!isLoading ? ( */}
        <div className="table-container">
          <Table showRowNumbers={true} rowData={dataWithActions} columnsData={columns} />
        </div>
        {/* ) : ( */}
        {/* <div className="loader-container">
            <CircleLoader />
          </div>
        )} */}
      </div>
    </>
  );
};

export default CreditUsage;
