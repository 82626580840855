import React from "react";
import "./BundledStoriesTable.scss";
import { Link } from "react-router-dom";
import { DiscIcon, HexagonIcon } from "../../icons/Icons";
import DateTimeShort from "../utils/DateTimeShort";

type BundledStoriesTableProps = {
  stories: Array<any>;
};

const BundledStoriesTable = ({ stories }: BundledStoriesTableProps) => {
  return (
    <div className="bundled-stories-table-container">
      {stories?.map((story) => {
        return (
          <div className="bundled-stories-item" key={story.post.post_id}>
            <div className="datetime">
              <DateTimeShort inputDateTime={story.post.display_date} />
            </div>
            <div className="title">
              <Link to={`/stories/${story.post.post_id}`}>{story.post.title}</Link>
            </div>
            <div className="pageviews">
              <div className="icon-number">{story.totals.page_view_calc_result}</div>
              <DiscIcon width="40px" height="50px" color="#e6e6e6" />
            </div>
            <div className="engagement">
              <div className="icon-number">{story.engagement.engagement_calc_result}</div>
              <HexagonIcon width="40px" height="50px" color="#e6e6e6" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BundledStoriesTable;
