import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Input from "../../../ui/common/Input";
import TextArea from "../../../ui/common/TextArea";
import ToggleSwitch from "../../../ui/toggleSwitch/ToggleSwitch";
import Button from "../../../ui/common/Button";
import { EditIcon, DeleteIcon, PlusIcon, SaveIcon, CloseIcon } from "../../../../icons/Icons";
import "./AiModal.scss";

interface CreditDeal {
  id: string;
  startDate: string;
  credits: number;
}

const AiModal = ({ data, brandName, onSave, onSaveCredits, onDeleteCredits, onClose }: any) => {
  const [clientId, setClientId] = useState(data.clientId.value || data.clientId.default);
  const [goals, setGoals] = useState(data.goals.value?.split(",") || data.goals.default || []);
  const [northstars, setNorthstars] = useState(data.northstars.value?.split(",") || data.northstars.default || []);
  const [publicationTimeFrom, setPublicationTimeFrom] = useState(
    data.publicationTimeFrom.value || data.publicationTimeTo.default,
  );
  const [publicationTimeTo, setPublicationTimeTo] = useState(
    data.publicationTimeTo.value || data.publicationTimeTo.default,
  );

  const updatingCredits = useSelector((state: any) => state.credits.updatingCredits);
  const updateCreditsError = useSelector((state: any) => state.credits.creatingBrandError);

  const emptyCreditDeal = {
    id: "",
    startDate: new Date().toISOString().split("T")[0],
    credits: 0,
  };

  const [creditDealEditIndex, setCreditDealEditIndex] = useState(null);
  const [creditDealEditData, setCreditDealEditData] = useState<CreditDeal>(emptyCreditDeal);

  /* Credit deals 
  [{
      "credits" : 1000,
      "id" : "deal1",
      "startDate" : "2023-12-13"
  }]
  */
  const [creditDeals, setCreditDeals] = useState(data.creditDeals.value || data.creditDeals.default);

  const allGoals = useMemo(() => {
    return ["metadata", "readability", "sentiment", "timeslots", "userneeds"];
  }, []);

  const allNorthstars = useMemo(() => {
    return ["attentiontime", "conversions", "pageviews", "social"];
  }, []);

  const handleGoalSwitch = useCallback(
    (clickedGoal) => {
      if (goals.includes(clickedGoal)) {
        setGoals(
          goals.filter((goal) => {
            return goal !== clickedGoal;
          }),
        );
      } else {
        setGoals([...goals, clickedGoal]);
      }
    },
    [goals],
  );

  const handleInputChange = (e, field) => {
    setCreditDealEditData({
      ...creditDealEditData,
      [field]: e.target.value,
    });
  };

  const handleNorthstarSwitch = useCallback(
    (clickedNorthstar) => {
      if (northstars.includes(clickedNorthstar)) {
        setNorthstars(
          northstars.filter((northstar) => {
            return northstar !== clickedNorthstar;
          }),
        );
      } else {
        setNorthstars([...northstars, clickedNorthstar]);
      }
    },
    [northstars],
  );

  const handleCreditSave = () => {
    const updatedDeals = creditDeals.map((deal, key) => {
      if (key === creditDealEditIndex) {
        return {
          ...creditDealEditData,
        };
      }
      return deal;
    });

    setCreditDeals(updatedDeals);
    onSaveCredits(creditDealEditData);
    setCreditDealEditIndex(null);
  };
  const handleCreditCancel = () => {
    // Check if the id of the credit deal is empty, if so remove it from the list
    if (creditDealEditData.id === "") {
      const updatedDeals = creditDeals.filter((deal, key) => {
        return key !== creditDealEditIndex;
      });
      setCreditDeals(updatedDeals);
    }
    setCreditDealEditIndex(null);
  };

  const handleCreditDelete = (key) => {
    const updatedDeals = creditDeals.filter((deal, index) => {
      return index !== key;
    });
    onDeleteCredits(creditDeals[key].id);
    setCreditDeals(updatedDeals);
  };

  const handleCreditNew = () => {
    // add empty credit deal to the list
    const updatedDeals = [...creditDeals, emptyCreditDeal];
    setCreditDeals(updatedDeals);
    setCreditDealEditIndex(creditDeals.length);
    setCreditDealEditData(emptyCreditDeal);
  };

  const handleSave = () => {
    const dataToSave = {
      clientId: {
        default: data.clientId.default,
        value: clientId,
      },
      goals: {
        default: data.goals.default,
        value: goals.join(","),
      },
      northstars: {
        default: data.northstars.default,
        value: northstars.join(","),
      },
      publicationTimeFrom: {
        default: data.publicationTimeFrom.default,
        value: publicationTimeFrom,
      },
      publicationTimeTo: {
        default: data.publicationTimeTo.default,
        value: publicationTimeTo,
      },
    };

    onSave(dataToSave);
  };

  return (
    <div className="ai-modal-wrapper">
      <h3>AI settings for {brandName}</h3>
      <div className="columns-container">
        <div className="column-left">
          <Input
            label="Client Id"
            value={clientId}
            onChange={(e) => setClientId(e.target.value)}
            separateLines
          />
          <p>Credit Deals</p>
          <div className="creadit-deals-container">
            <div className="credit-deal-header">
              <div className="starting-date-deal">
                <p>Starting deal date</p>
              </div>
              <div className="number-of-credits">
                <p>Number of credits</p>
              </div>
              <div className="number-of-credits"></div>
            </div>
            <div className="credit-deal-body">
              {creditDeals.map((deal, key) => {
                return (
                  <div className="credit-deal" key={key}>
                    <div className="credit-deal-date">
                      {creditDealEditIndex === key ? (
                        <Input
                          type="date"
                          value={creditDealEditData?.startDate}
                          onChange={(e) => handleInputChange(e, "startDate")}
                          placeholder="dd-mm-yyyy"
                          min="2000-01-01"
                          max="2030-12-31"
                          pattern="\d{4}-\d{2}-\d{2}"
                          data-date-format="YYYY-MM-DD"
                        />
                      ) : (
                        <p>{deal.startDate}</p>
                      )}
                    </div>
                    <div className="credit-deal-credits">
                      {creditDealEditIndex === key ? (
                        <Input
                          type="number"
                          value={creditDealEditData?.credits}
                          onChange={(e) => handleInputChange(e, "credits")}
                        />
                      ) : (
                        <p>{deal.credits}</p>
                      )}
                    </div>
                    <div className="credit-deal-buttons">
                      <Button.Feedback
                        variant="success"
                        data-testid={`editButton${key}`}
                        size="small"
                        title="Save"
                        onClick={() => {
                          handleCreditSave();
                        }}
                        style={{ display: creditDealEditIndex === key ? "" : "none" }}
                      >
                        <SaveIcon />
                      </Button.Feedback>

                      <Button
                        variant="warning"
                        data-testid={`editButton${key}`}
                        size="small"
                        title="Cancel"
                        onClick={() => {
                          handleCreditCancel();
                        }}
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          display: creditDealEditIndex === key ? "" : "none",
                        }}
                      >
                        <CloseIcon />
                      </Button>

                      <Button
                        variant="warning"
                        data-testid={`editButton${key}`}
                        size="small"
                        title="Edit"
                        onClick={() => {
                          setCreditDealEditData(deal);
                          setCreditDealEditIndex(key);
                        }}
                        // hide if editing
                        style={{ display: creditDealEditIndex === key ? "none" : "" }}
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        variant="danger"
                        data-testid={`deleteButton${key}`}
                        size="small"
                        title="Delete"
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          display: creditDealEditIndex === key ? "none" : "",
                        }}
                        onClick={() => {
                          handleCreditDelete(key);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="credit-deal-footer">
              <Button
                variant="success"
                size="small"
                title={creditDealEditIndex !== null ? "First save or cancel editing" : "Add new credit deal"}
                style={{ marginLeft: "46%", marginRight: "47%" }}
                onClick={() => {
                  handleCreditNew();
                }}
                disabled={creditDealEditIndex !== null}
              >
                <PlusIcon />
              </Button>
            </div>
          </div>
        </div>
        <div className="column-right">
          <div className="goals-northstarts-container">
            <div className="goals-container">
              <p>Goals</p>
              {allGoals.map((goal) => {
                return (
                  <div className="switch-wrapper" key={goal}>
                    <div className="switch-container">
                      <ToggleSwitch
                        checked={goals.includes(goal)}
                        onChange={() => {
                          handleGoalSwitch(goal);
                        }}
                      />
                    </div>
                    <div className="switch-title">{goal}</div>
                  </div>
                );
              })}
            </div>
            <div className="northstart-container">
              <p>Northstars</p>
              {allNorthstars.map((northstar) => {
                return (
                  <div className="switch-wrapper" key={northstar}>
                    <div className="switch-container">
                      <ToggleSwitch
                        checked={northstars.includes(northstar)}
                        onChange={() => {
                          handleNorthstarSwitch(northstar);
                        }}
                      />
                    </div>
                    <div className="switch-title">{northstar}</div>
                  </div>
                );
              })}
            </div>
          </div>

          <Input
            label="Publication Time From"
            value={publicationTimeFrom}
            onChange={(e) => {
              setPublicationTimeFrom(e.target.value);
            }}
            separateLines
          />
          <Input
            label="Publication Time To"
            value={publicationTimeTo}
            onChange={(e) => {
              setPublicationTimeTo(e.target.value);
            }}
            separateLines
          />
        </div>
      </div>

      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            handleSave();
            onClose();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default AiModal;
