import React, { useState, useEffect } from "react";
import "./WeekPicker.scss";
import { v4 } from "uuid";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import { DateTime, Settings } from "luxon";

// Set the default locale to start the week on Sunday
Settings.defaultLocale = "en";

const WeekPicker = ({ onChange, initialValues }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(initialValues?.firstDay ? new Date(initialValues.firstDay) : new Date());

  const [week, setWeek] = useState({
    firstDay: initialValues?.firstDay
      ? new Date(initialValues.firstDay)
      : DateTime.now().startOf("week").minus({ days: 1 }).toJSDate(),
    lastDay: initialValues?.lastDay
      ? new Date(initialValues.lastDay)
      : DateTime.now().endOf("week").minus({ days: 1 }).toJSDate(),
  });

  // Todays date
  const dateToday = new Date();
  const dateTodayMonth = dateToday.getMonth() + 1;
  const dateTodayDay = dateToday.getDate();
  const dateTodayYear = dateToday.getFullYear();

  useEffect(() => {
    onChange && onChange(week);
  }, [week, onChange]);

  const isLeapYear = () => {
    return DateTime.now().isInLeapYear;
  };

  const convertDate = (date) => {
    const dt = DateTime.fromJSDate(date);
    return `${dt.day}.${dt.month}.${dt.year}.`;
  };

  const handleClick = (e) => {
    let localDate = DateTime.fromJSDate(date);
    if (e.target.id.includes("prev")) {
      localDate = localDate.set({ day: 1 });
    } else if (e.target.id.includes("next")) {
      localDate = localDate.set({ day: localDate.daysInMonth });
    } else {
      localDate = localDate.set({ day: parseInt(e.target.id, 10) });
    }
    setDate(localDate.toJSDate());
    const firstDay = localDate.startOf("week").minus({ days: 1 }).toJSDate();
    const lastDay = localDate.endOf("week").minus({ days: 1 }).toJSDate();
    setWeek({ firstDay, lastDay });
  };

  const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun", "July", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

  const days = {
    1: 31,
    2: isLeapYear() ? 29 : 28,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
  };

  const isDayInFuture = (day, month, year) => {
    return year > dateTodayYear || month > dateTodayMonth || (month === dateTodayMonth && day > dateTodayDay);
  };

  const renderDays = () => {
    const month = date.getMonth() + 1;
    let ar = [];

    for (let i = 1; i <= days[month]; i++) {
      let currentDate = new Date(date).setDate(i);
      let cName = "single-number ";
      if (
        new Date(week.firstDay).getTime() <= new Date(currentDate).getTime() &&
        new Date(currentDate).getTime() <= new Date(week.lastDay).getTime()
      ) {
        cName = cName + "selected-week";
      }
      let disabled = false;
      if (isDayInFuture(i, date.getMonth() + 1, date.getFullYear())) {
        disabled = true;
        cName += " disabled";
      }

      ar.push(
        <div
          key={v4()}
          id={i}
          className={cName}
          onClick={(e) => {
            if (!disabled) {
              handleClick(e);
            }
          }}
          onMouseEnter={() => {
            isDayInFuture(i, month);
          }}
        >
          {i}
        </div>,
      );
    }

    const displayDate = new Date(date).setDate(1);
    let dayInTheWeek = new Date(displayDate).getDay();
    let prevMonth = [];
    let prevMonthDays = new Date(date).getMonth();
    if (prevMonthDays === 0) {
      prevMonthDays = 12;
    }
    for (let i = dayInTheWeek; i > 0; i--) {
      let previousMonth = new Date(date).setMonth(new Date(date).getMonth() - 1);
      let currentDate = new Date(previousMonth).setDate(days[prevMonthDays] - i + 1);
      let cName = "single-number other-month";
      let currentTime = new Date(currentDate).getTime();
      let firstTime = new Date(week.firstDay).getTime();
      let endTime = new Date(week.lastDay).getTime();
      if (currentTime >= firstTime && currentTime <= endTime) {
        cName = "single-number selected-week";
      }

      prevMonth.push(
        <div onClick={handleClick} key={v4()} id={"prev-" + i} className={cName}>
          {days[prevMonthDays] - i + 1}
        </div>,
      );
    }

    let nextMonth = [];
    let fullDays = 35;
    if ([...prevMonth, ...ar].length > 35) {
      fullDays = 42;
    }

    for (let i = 1; i <= fullDays - [...prevMonth, ...ar].length; i++) {
      let cName = "single-number other-month";
      const lastDay = new Date(week.lastDay).getTime();
      const lastDayOfMonth = DateTime.fromJSDate(date).endOf("month").toJSDate();

      if (lastDayOfMonth.getTime() <= lastDay && new Date(week.firstDay).getMonth() === lastDayOfMonth.getMonth()) {
        cName = "single-number selected-week";
      }

      nextMonth.push(
        <div onClick={handleClick} key={v4()} id={"next-" + i} className={cName}>
          {i}
        </div>,
      );
    }
    return [...prevMonth, ...ar, ...nextMonth];
  };

  const handleDate = (next) => {
    let localDate = DateTime.fromJSDate(date);
    if (next) {
      localDate = localDate.plus({ months: 1 });
    } else {
      localDate = localDate.minus({ months: 1 });
    }
    setDate(localDate.toJSDate());
  };

  return (
    <div className="week-picker-display" onBlur={() => setOpen(false)} onClick={() => setOpen(true)} tabIndex={0}>
      <p>
        {convertDate(week.firstDay)} - {convertDate(week.lastDay)}
      </p>
      {open && (
        <div className="week-picker-options">
          <div className="title-week">
            <div onClick={() => handleDate(false)} className="arrow-container">
              <ArrowLeft />
            </div>
            {`${months[date.getMonth()]} ${date.getFullYear()}.`}
            {date.getMonth() + 1 < dateTodayMonth ? (
              <div onClick={() => handleDate(true)} className="arrow-container">
                <ArrowRight />
              </div>
            ) : (
              <div className="arrow-container-hidden"></div>
            )}
          </div>
          <div className="numbers-container">
            <div className="single-number day">Sun</div>
            <div className="single-number day">Mon</div>
            <div className="single-number day">Tue</div>
            <div className="single-number day">Wed</div>
            <div className="single-number day">Thu</div>
            <div className="single-number day">Fri</div>
            <div className="single-number day">Sat</div>
          </div>
          <div className="numbers-container">{renderDays()}</div>
        </div>
      )}
    </div>
  );
};

export default WeekPicker;
