import React, { useMemo } from "react";

type DateTimeShortProps = {
  inputDateTime: string;
};

const DateTimeShort = ({ inputDateTime }: DateTimeShortProps) => {
  const options = {
    day: "numeric",
    month: "long",
    hour12: false,
    timeZone: "UTC",
  };
  const calculatedDateTime = useMemo(() => {
    const date = new Date(inputDateTime);
    
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const day = new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(date);
    const hour = date.getHours();
    const minutes = ("0" + date.getMinutes()).slice(-2);
  
    return `${day} ${month} ${hour}:${minutes}`;
  }, [inputDateTime]);

  return <>{calculatedDateTime}</>;
};

export default DateTimeShort;
