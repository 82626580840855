import React, { useMemo } from "react";
import "./BarChartLegend.scss";

type BarChartData = {
  value: number;
  color: string;
  title: string;
};

const BarChartLegend = ({ data }: { data: Array<BarChartData> }) => {
  const arrangeData = useMemo(() => {
    let legendColumns = [[]];
    let columnIndex = 0;
    data.forEach((e, i) => {
      /* istanbul ignore else */
      if (legendColumns[columnIndex].length < 4) {
        // @ts-ignore
        legendColumns[columnIndex].push(e);
      } else if (legendColumns[columnIndex].length === 4) {
        columnIndex++;
        legendColumns.push([]);
        // @ts-ignore
        legendColumns[columnIndex].push(e);
      }
    });

    return legendColumns;
  }, [data]);

  return (
    <div className="bar-legend">
      {arrangeData.map((column, i) => {
        return (
          <div className="legend-column" key={i}>
            {column.map((legend: BarChartData) => {
              return (
                <div key={legend.title}>
                  <div className="legend-indicator" style={{ backgroundColor: legend.color }} />
                  <span className="legend-label">{legend.title}</span>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default BarChartLegend;
