import React, { useState } from "react";
import Input from "../../../ui/common/Input";
import TextArea from "../../../ui/common/TextArea";
import Button from "../../../ui/common/Button";
import "./VideosModal.scss";

const VideosModal = ({ data, onSave, onClose }) => {
  const [engagementExpectationVideo, setEngagementExpectationVideo] = useState(
    data.engagementExpectationVideo?.value || data.engagementExpectationVideo?.default,
  );
  const [engagementFactorVideo, setEngagementFactorVideo] = useState(
    data.engagementFactorVideo?.value || data.engagementFactorVideo?.default,
  );
  const [pageviewExpectationVideo, setPageviewExpectationVideo] = useState(
    data.pageviewExpectationVideo?.value || data.pageviewExpectationVideo?.default,
  );
  const [pageviewFactorVideo, setPageviewFactorVideo] = useState(
    data.pageviewFactorVideo?.value || data.pageviewFactorVideo?.default,
  );
  const [sparklineFactorVideo, setSparklineFactorVideo] = useState(
    data.sparklineFactorVideo?.value || data.sparklineFactorVideo?.default,
  );

  const handleSave = () => {
    onSave({
      engagementExpectationVideo: {
        default: data.engagementExpectationVideo?.default,
        value: engagementExpectationVideo,
      },
      engagementFactorVideo: {
        default: data.engagementFactorVideo?.default,
        value: engagementFactorVideo,
      },
      pageviewExpectationVideo: {
        default: data.pageviewExpectationVideo?.default,
        value: pageviewExpectationVideo,
      },
      pageviewFactorVideo: {
        default: data.pageviewFactorVideo?.default,
        value: pageviewFactorVideo,
      },
      sparklineFactorVideo: {
        default: data.sparklineFactorVideo?.default,
        value: sparklineFactorVideo,
      },
      videoChannels: {},
    });
    onClose();
  };

  return (
    <div className="videos-modal-wrapper">
      <h3>Videos settings</h3>
      <Input
        label="Engagement Expectation Video"
        type="number"
        value={engagementExpectationVideo}
        onChange={(e) => {
          setEngagementExpectationVideo(e.target.value);
        }}
        separateLines
      />
      <Input
        label="Engagement Factor Video"
        type="number"
        value={engagementFactorVideo}
        onChange={(e) => {
          setEngagementFactorVideo(e.target.value);
        }}
        separateLines
      />
      <Input
        label="Pageview Expectation Video"
        type="number"
        value={pageviewExpectationVideo}
        onChange={(e) => {
          setPageviewExpectationVideo(e.target.value);
        }}
        separateLines
      />
      <Input
        label="Pageview Factor Video"
        type="number"
        value={pageviewFactorVideo}
        onChange={(e) => {
          setPageviewFactorVideo(e.target.value);
        }}
        separateLines
      />
      <Input
        label="Sparkline Factor Video"
        type="number"
        value={sparklineFactorVideo}
        onChange={(e) => {
          setSparklineFactorVideo(e.target.value);
        }}
        separateLines
      />
      <p>Video Channels</p>
      <TextArea />

      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button variant="success" onClick={handleSave}>
          OK
        </Button>
      </div>
    </div>
  );
};

export default VideosModal;
