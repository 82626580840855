import React from "react";
import Input from "../../../../ui/common/Input";
import "./TentaclesSettingsForms.scss";

const TentaclesInfoForm = ({ data, handleChanges }) => {
  return (
    <div className="inputs-container">
      <Input
        label="Bottom clicks"
        value={data.bottomClicks.value || data.bottomClicks.default}
        onChange={(e) => {
          handleChanges("bottomClicks", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Bottom Loyalty"
        value={data.bottomLoyalty.value || data.bottomLoyalty.default}
        onChange={(e) => {
          handleChanges("bottomLoyalty", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="High Clicks"
        value={data.highClicks.value || data.highClicks.default}
        onChange={(e) => {
          handleChanges("highClicks", e.target.value);
        }}
        fullWidth
        separateLines
      />

      <Input
        label="High Loyalty"
        value={data.highLoyalty.value || data.highLoyalty.default}
        onChange={(e) => {
          handleChanges("highLoyalty", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Low Clicks"
        value={data.lowClicks.value || data.lowClicks.default}
        onChange={(e) => {
          handleChanges("lowClicks", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Low Loyalty"
        value={data.lowLoyalty.value || data.lowLoyalty.default}
        onChange={(e) => {
          handleChanges("lowLoyalty", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Top Clicks"
        value={data.topClicks.value || data.topClicks.default}
        onChange={(e) => {
          handleChanges("topClicks", e.target.value);
        }}
        fullWidth
        separateLines
      />
      <Input
        label="Top Loyalty"
        value={data.topLoyalty.value || data.topLoyalty.default}
        onChange={(e) => {
          handleChanges("topLoyalty", e.target.value);
        }}
        fullWidth
        separateLines
      />
    </div>
  );
};

export default TentaclesInfoForm;
