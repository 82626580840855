import React from "react";

function Sad() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 148 134"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(17 9)">
            <circle cx="57" cy="57" r="57" fill="#FFDA69"></circle>
            <path
              fill="#252B39"
              fillRule="nonzero"
              d="M73.197 86.855A21.43 21.43 0 0057.463 80a21.428 21.428 0 00-15.648 6.763 3 3 0 004.37 4.112A15.43 15.43 0 0157.463 86a15.43 15.43 0 0111.34 4.941 3 3 0 104.394-4.086z"
            ></path>
            <path
              fill="#548CFF"
              d="M100 105c0 5.523-4.477 10-10 10s-10-4.477-10-10c0-1.619.385-3.147 1.067-4.5L90 84l8.933 16.5A9.958 9.958 0 01100 105z"
            ></path>
            <path
              fill="#252B39"
              fillRule="nonzero"
              d="M27.274 44.448a9.113 9.113 0 00-5.008 4.66 2.5 2.5 0 01-4.527-2.123 14.112 14.112 0 017.743-7.205 14.105 14.105 0 019.676-.17 2.5 2.5 0 01-1.628 4.727 9.106 9.106 0 00-6.256.111zm59.362.406a9.106 9.106 0 00-6.256-.11 2.5 2.5 0 01-1.629-4.728 14.105 14.105 0 019.677.17 14.112 14.112 0 017.742 7.205 2.5 2.5 0 01-4.527 2.122 9.113 9.113 0 00-5.007-4.659zM82 69c-3.866 0-7-3.565-7-7.963 0-4.397 3.134-7.962 7-7.962s7 3.565 7 7.962C89 65.435 85.866 69 82 69zm-50 0c-3.866 0-7-3.565-7-7.963 0-4.397 3.134-7.962 7-7.962s7 3.565 7 7.962C39 65.435 35.866 69 32 69z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Sad;
