import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  isLoading: boolean;
  data: Array<Object>;
  error: Object | null;

  creatingCredits: boolean;
  creatingCreditsError: Object | null;

  updatingCredits: boolean;
  updatingCreditsError: Object | null;

  deletingCredits: boolean;
  deletingCreditsError: Object | null;
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: null,

  creatingCredits: false,
  creatingCreditsError: null,

  updatingCredits: false,
  updatingCreditsError: null,

  deletingCredits: false,
  deletingCreditsError: null,
};

const credits = createSlice({
  name: "credits",
  initialState: INITIAL_STATE,
  reducers: {
    fetchCreditsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchCreditsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchCreditsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    createCreditsStart: (state: State) => {
      state.creatingCredits = true;
    },
    createCreditsSuccess: (state: State, action) => {
      const createdCredits = action.payload;
      // state.allBrandsData.push(createdBrand);
      state.creatingCredits = false;
    },
    createCreditsFailed: (state: State, action) => {
      state.creatingCredits = false;
      state.creatingCreditsError = action.payload;
    },
    
    updateCreditsStart: (state: State) => {
      state.updatingCredits = true;
    },
    updateCreditsSuccess: (state: State, action) => {
      const updatedCredits = action.payload;
      // state.allBrandsData.push(createdBrand);
      state.updatingCredits = false;
    },
    updateCreditsFailed: (state: State, action) => {
      state.updatingCredits = false;
      state.updatingCreditsError = action.payload;
    },
    
    deleteCreditsStart: (state: State) => {
      state.deletingCredits = true;
    },
    deleteCreditsSuccess: (state: State) => {
      // const deletedCredits = action.payload;
      // state.allBrandsData.push(createdBrand);
      state.deletingCredits = false;
      state.deletingCreditsError = null;
    },
    deleteCreditsFailed: (state: State, action) => {
      state.deletingCredits = false;
      state.deletingCreditsError = action.payload;
    },

    resetCreditsState: (state: State) => {
      state.creatingCredits = false;
      state.creatingCreditsError = null; // Reset the error state
    },
  },
});

export default credits.reducer;

const { 
  fetchCreditsStart, 
  fetchCreditsSuccess, 
  fetchCreditsFailed,

  createCreditsStart,
  createCreditsSuccess,
  createCreditsFailed,

  updateCreditsStart,
  updateCreditsSuccess,
  updateCreditsFailed,

  deleteCreditsStart,
  deleteCreditsSuccess,
  deleteCreditsFailed,

 } = credits.actions;

export const fetchCredits = () => async (dispatch: Function) => {
  try {
    dispatch(fetchCreditsStart());
    const api = new Api();
    const result = await api.getCredits();
    dispatch(fetchCreditsSuccess(result));
  } catch (err) {
    dispatch(fetchCreditsFailed(err));
  }
};

export const createCredits = (brandId: string, payload: any) => async (dispatch: Function) => {
  try {
    dispatch(createCreditsStart());
    const api = new Api();
    const result = await api.postCreateCredits(brandId, payload)
    dispatch(createCreditsSuccess(result));
  } catch (err) {
    dispatch(createCreditsFailed(err))
  }
}

export const updateCredits = (brandId: string, creditId: string, payload: any) => async (dispatch: Function) => {
  try {
    dispatch(updateCreditsStart());
    const api = new Api();
    const result = await api.postUpdateCredits(brandId, creditId, payload)
    dispatch(updateCreditsSuccess(result));
  } catch (err) {
    dispatch(updateCreditsFailed(err))
  }
}

export const deleteCredits = (brandId: string, creditId: string) => async (dispatch: Function) => {
  try {
    dispatch(deleteCreditsStart());
    const api = new Api();
    const result = await api.deleteCredits(brandId, creditId);
    dispatch(deleteCreditsSuccess());
  } catch (err) {
    dispatch(deleteCreditsFailed(err))
  }
}