import React, { useState, useEffect, useRef } from "react";
import { ArrowIconClosed } from "../../../icons/Icons";
import "./Select.scss";

type OptionProps = {
  children: any;
  changeOption: Function;
  optionClassName?: string;
  rounded?: boolean;
  value?: any | null;
};

const Option = ({ children, changeOption, optionClassName, rounded, value }: OptionProps) => {
  return (
    <div
      className={[optionClassName || "option", rounded && "rounded"].join(" ")}
      onClick={() => {
        changeOption(value || children);
      }}
    >
      {children}
    </div>
  );
};

type SelectProps = {
  options: Array<any>;
  selectedOption: any;
  setSelectedOption: Function;
  rounded?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  styling?: {
    selectClassName?: string;
    selectOptionsContainerClassName?: string;
    optionClassName?: string;
  };
  search?: boolean;
};

const Select = ({
  options,
  selectedOption,
  setSelectedOption,
  rounded,
  fullWidth,
  disabled,
  styling,
  search = false,
}: SelectProps) => {
  const [optionsDisplayed, setOptionsDisplayed] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOptionsDisplayed(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setOptionsDisplayed]);

  useEffect(() => {
    if (search) {
      const filtered = options.filter((option) =>
        typeof option === "string"
          ? option.toLowerCase().includes(searchTerm.toLowerCase())
          : option?.label?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  }, [searchTerm, options, search]);

  const changeOption = (value: any) => {
    if (search) setSearchTerm("");
    setSelectedOption(value);
    setOptionsDisplayed(false);
  };

  return (
    <div className={`so-select ${fullWidth && "full-width"}`} ref={ref}>
      <div
        className={`${styling?.selectClassName || "select-value-container"} select-value-container
          ${rounded && "rounded"} ${disabled ? "disabled" : ""} ${optionsDisplayed ? "options-displayed" : null} ${disabled ? "disabled" : ""}`}

        onClick={() => {
          if (disabled) return;
          setOptionsDisplayed(!optionsDisplayed);
          if (search && !optionsDisplayed) {
            setTimeout(() => {
              const input = document.querySelector(".select-search") as HTMLInputElement;
              input?.focus();
            }, 0);
          }
        }}
      >

        {!optionsDisplayed && (
          <span className="select-value">{selectedOption}</span>
        )}

        {optionsDisplayed && search && (
          <input
            type="text"
            className="select-search"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        <span className={`dropdown-icon ${disabled ? "disabled" : ""}`}
          onClick={() => {
            if (disabled) return;
            setOptionsDisplayed(!optionsDisplayed);
            // if seach adn options displayed, focus on input
            if (search && !optionsDisplayed) {
              setTimeout(() => {
                const input = document.querySelector(".select-search") as HTMLInputElement;
                input?.focus();
              }, 0);
            }
          }}>
          <ArrowIconClosed />
        </span>

      </div>

      {optionsDisplayed && (
        <div
          className={`select-options ${rounded && "rounded"} ${optionsDisplayed ? "options-displayed" : "options-hidden"
            } ${styling?.selectOptionsContainerClassName}`}
        >
          {filteredOptions.map((e) => (
            <Option
              key={e.key || e}
              changeOption={changeOption}
              optionClassName={styling?.optionClassName}
              rounded={rounded}
              value={e.value || null}
            >
              {typeof e === "string" || typeof e === "number" ? e : e?.label || ""}
            </Option>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;