import React, { useMemo, useState } from "react";
import Input from "../ui/common/Input";
import Button from "../ui/common/Button";
import "./ProfileSettings.scss";
import { useSelector } from "react-redux";

type ProfileSettingsProps = {
  email: string;
  firstName: string;
  lastName: string;
  newPassword: string;
  confirmNewPassword: string;
  isSuperUserOrAdmin: boolean;
  organisationIdp: string;
  setFirstName: Function;
  setLastName: Function;
  handleSave: Function;
  setNewPassword: Function;
  setConfirmNewPassword: Function;
};

const ProfileSettings = ({
  email,
  firstName,
  lastName,
  newPassword,
  confirmNewPassword,
  setNewPassword,
  setConfirmNewPassword,
  setFirstName,
  setLastName,
  isSuperUserOrAdmin,
  organisationIdp,

  handleSave,
}: ProfileSettingsProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const passwordScore = useMemo(() => {
    const passwordMinLength = 8;
    const regexLowerLetter = new RegExp("(?=.*[a-z])");
    const regexUpperLetter = new RegExp("(?=.*[A-Z])");
    const regexDigits = new RegExp("(?=.*[0-9])");
    const regexLength = new RegExp(`(?=.{${passwordMinLength},})`);

    let score = 0;

    if (newPassword.match(regexLowerLetter)) ++score;
    if (newPassword.match(regexUpperLetter)) ++score;
    if (newPassword.match(regexDigits)) ++score;
    if (newPassword.match(regexLength)) ++score;
    if (score === 0 && newPassword.length > 0) ++score;

    return score;
  }, [newPassword]);

  const passwordScoreMessage = useMemo(() => {
    const scoreMessageList = ["", "Weak", "Medium", "Strong", "Very strong"];
    return scoreMessageList[passwordScore];
  }, [passwordScore]);

  const passwordsMatching = useMemo(() => {
    if (confirmNewPassword.length > 0) {
      return newPassword === confirmNewPassword;
    }
    return true;
  }, [newPassword, confirmNewPassword]);

  return (
    <form className="profile-settings">
      <h3>Login</h3>
      <Input fullWidth type="text" autoComplete="off" value={email} id="email" disabled />

      {(isSuperUserOrAdmin || organisationIdp === 'smartocto') && (
        <>
          <h3>Change your password</h3>
          <div className="new-password-container">
            <Input
              fullWidth
              id="new-password"
              type={passwordVisible ? "text" : "password"}
              autoComplete="new-password"
              placeholder="new password"
              value={newPassword}
              className={!passwordsMatching ? "error" : ""}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
            {newPassword.trim().length > 0 && (
              <div className="password-progress-bar-container">
                <div className={`password-progress-bar password-strength-${passwordScore}`}></div>
                <div className="password-strength-message">{passwordScoreMessage}</div>
              </div>
            )}
          </div>
          <div>
            <Input
              fullWidth
              id="password-retype"
              type={passwordVisible ? "text" : "password"}
              autoComplete="new-password-retype"
              placeholder="retype your password"
              value={confirmNewPassword}
              className={!passwordsMatching ? "error" : ""}
              onChange={(e) => {
                setConfirmNewPassword(e.target.value);
              }}
            />

            {!passwordsMatching && <p className="passwords-matching-message">Passwords are not matching</p>}
          </div>
          <div className="checkbox-container">
            <label htmlFor="show-password">Show password</label>
            <Input
              type="checkbox"
              id="show-password"
              checked={passwordVisible}
              onChange={(e) => {
                setPasswordVisible(e.target.checked);
              }}
            />
          </div>
        </>
      )}
      
      <h3>Name</h3>
      <div className="input-container">
        <Input
          fullWidth
          type="text"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          id="first-name"
          autoComplete="off"
        />
      </div>
      <div className="input-container">
        <Input
          fullWidth
          type="text"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          id="last-name"
        />
      </div>
      <Button
        fullWidth
        variant="default"
        rounded={false}
        className="profile-details-save-changes"
        onClick={() => {
          handleSave();
        }}
      >
        Save changes
      </Button>
    </form>
  );
};

export default ProfileSettings;
