import React from "react";
import { useAppDispatch } from "../../store";
import {
  fetchArticleReadability,
  fetchArticleRewrite,
  fetchHeadlineSuggestions,
  fetchParagraphRephrase,
  fetchProduceCategories,
  fetchSentiment,
  fetchTiming,
} from "../../store/slices/smartoctoAI";
import "./SmartoctoAIActions.scss";

type SmartoctoAIActionsProps = {
  headlineText: string;
  articleText: string;
  setOutputType: Function;
  setOutputRewriterType: Function;
  setHeadlineError: Function;
  setHeadlineErrorText: Function;
  setArticleError: Function;
  setArticleErrorText: Function;
};

const SmartoctoAIActions = ({
  headlineText,
  articleText,
  setOutputType,
  setOutputRewriterType,
  setHeadlineError,
  setHeadlineErrorText,
  setArticleError,
  setArticleErrorText,
}: SmartoctoAIActionsProps) => {
  const dispatch = useAppDispatch();

  const validateHeadlineInput = () => {
    if (headlineText.trim() === "") {
      setHeadlineError(true);
      setHeadlineErrorText("Please provide headline");
      return false;
    }
    return true;
  };

  const validateArticleInput = () => {
    if (articleText.trim() === "") {
      setArticleError(true);
      setArticleErrorText("Please provide draft article");
      return false;
    }
    return true;
  };

  const suggestHeadline = (type) => {
    if (validateHeadlineInput()) {
      setOutputType("headliner");
      const textCombined = headlineText + "||" + articleText;
      dispatch(fetchHeadlineSuggestions(textCombined, "so", type));
    }
  };

  const rephraseParagraph = () => {
    if (validateHeadlineInput() && validateArticleInput()) {
      setOutputType("rephraser");
      const textCombined = headlineText + "||" + articleText;
      dispatch(fetchParagraphRephrase(textCombined, ""));
    }
  };

  const retreiveOptimalTime = () => {
    if (validateHeadlineInput() && validateArticleInput()) {
      setOutputType("optimalTime");
      const textCombined = headlineText + "||" + articleText;
      dispatch(fetchTiming(textCombined, "dpg", false, false));
    }
  };

  const produceCategories = () => {
    if (validateHeadlineInput() && validateArticleInput()) {
      setOutputType("categorizer");
      const textCombined = headlineText + "||" + articleText;
      dispatch(fetchProduceCategories(textCombined));
    }
  };

  const readability = () => {
    if (validateHeadlineInput() && validateArticleInput()) {
      setOutputType("readability");
      const textCombined = headlineText + "||" + articleText;
      dispatch(fetchArticleReadability(textCombined));
    }
  };

  const sentiment = () => {
    if (validateArticleInput()) {
      setOutputType("sentiment");
      const textCombined = headlineText + "||" + articleText;
      dispatch(fetchSentiment(textCombined));
    }
  };

  const rewriteArticle = (type: string) => {
    if (validateArticleInput()) {
      setOutputRewriterType(type);
      setOutputType("rewriter");
      dispatch(fetchArticleRewrite(articleText, type));
    }
  };

  return (
    <>
      <h3>headline</h3>
      <div className="headline-buttons">
        <button
          onClick={() => {
            suggestHeadline("pageviews");
          }}
        >
          views
        </button>
        <button
          onClick={() => {
            suggestHeadline("engagement");
          }}
        >
          engagement
        </button>
      </div>

      <h3>output</h3>
      <div className="output-buttons">
        <button
          onClick={() => {
            rephraseParagraph();
          }}
        >
          rephrase paragraph
        </button>
        <button
          onClick={() => {
            retreiveOptimalTime();
          }}
        >
          calculate best timing
        </button>
        <button
          onClick={() => {
            produceCategories();
          }}
        >
          produce categories
        </button>
        <button
          onClick={() => {
            readability();
          }}
        >
          readability
        </button>
        <button
          onClick={() => {
            sentiment();
          }}
        >
          sentiment
        </button>
      </div>

      <h3>output user needs</h3>
      <div className="output-buttons-userneeds">
        <button
          className="btn-functional"
          onClick={() => {
            rewriteArticle("functional");
          }}
        >
          functional
        </button>
        <button
          className="btn-emotional"
          onClick={() => {
            rewriteArticle("emotional");
          }}
        >
          emotional
        </button>
        <button
          className="btn-contextual"
          onClick={() => {
            rewriteArticle("contextual");
          }}
        >
          contextual
        </button>
        <button
          className="btn-actionable"
          onClick={() => {
            rewriteArticle("actionable");
          }}
        >
          actionable
        </button>
      </div>

      <h3>coming soon</h3>
      <div className="coming-soon-buttons">
        <button disabled>summary</button>
        <button disabled>SEO</button>
      </div>

      <p className="contact-us">Contact us here for feedback</p>
    </>
  );
};

export default SmartoctoAIActions;
