import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

const useDateTimezoneAdjust = () => {
  const currentBrand = useSelector((state: any) => state.currentBrand.data);

  const brandTimezone = useMemo(() => {
    return currentBrand.domainSettings.timezone;
  }, [currentBrand]);

  const adjustTimezone = useCallback(
    (timestamp: number) => {
      const luxonDate = DateTime.fromMillis(timestamp).setZone(brandTimezone);
      return new Date(luxonDate.toFormat("yyyy-MM-dd HH:mm"));
    },
    [brandTimezone],
  );

  return adjustTimezone;
};

export default useDateTimezoneAdjust;
