import React, { useMemo } from "react";

type TimeAgoLongProps = {
  timestamp: number;
};

const TimeAgoLong = ({ timestamp }: TimeAgoLongProps) => {
  const calculatedTimePassed = useMemo(() => {
    if (!timestamp) return "";
    const now = new Date().valueOf();
    const timeDifference = now - timestamp;
    const secondsDifference = timeDifference / 1000;
    const minutesDifference = secondsDifference / 60;
    const hoursDifference = minutesDifference / 60;
    const daysDifference = hoursDifference / 24;

    if (Math.floor(daysDifference) === 1) {
      return "one day ago";
    } else if (daysDifference >= 1) {
      return Math.floor(daysDifference) + " days ago";
    } else if (Math.floor(hoursDifference) === 1) {
      return "an hour ago";
    } else if (hoursDifference >= 1) {
      return Math.floor(hoursDifference) + " hours ago";
    } else {
      return Math.floor(minutesDifference) + " minutes ago";
    }
  }, [timestamp]);

  return <>{calculatedTimePassed}</>;
};

export default TimeAgoLong;
