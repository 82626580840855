import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

const StoryDate = ({ timestamp }) => {
  const currentBrand = useSelector((state: any) => state.currentBrand.data);

  const timezone = useMemo(() => {
    return currentBrand.domainSettings.timezone;
  }, [timestamp, currentBrand]);

  const dateToDisplay = useMemo(() => {
    if (!timestamp) return "";
    const d = DateTime.fromMillis(timestamp).setZone(timezone);
    return d.toFormat("EEEE, d MMMM yyyy, HH:mm");
  }, [timestamp]);

  return <>{dateToDisplay}</>;
};

export default StoryDate;
