import React from "react";
import "./GrowthIndicators.scss";

type GrowthIndicatorsProps = {
  growth: number;
};

const GrowthIndicators = ({ growth }: GrowthIndicatorsProps) => {
  return (
    <div className="report-row-growth-indicators-container">
      <div className={["arrow-up", growth > 74 ? "green" : ""].join(" ")}></div>
      <div className={["arrow-up", growth > 49 ? "green" : ""].join(" ")}></div>
      <div className={["arrow-up", growth > 24 ? "green" : ""].join(" ")}></div>
      <div className={["arrow-up", growth > 0 ? "green" : ""].join(" ")}></div>
    </div>
  );
};
export default GrowthIndicators;
