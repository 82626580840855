import React, { useEffect, useRef, useState } from "react";
import { MenuIcon } from "../../icons/Icons";
import LanguageSwitcher from "./LanguageSwitcher";
import ApiSelector from "./ApiSelector";
import "./DevHelperTools.scss";

const DevHelperTools = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showApiOptions, setShowApiOptions] = useState(false);
  const [showLanguageOptions, setShowLanguageOptions] = useState(false);
  const devToolsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!devToolsRef.current) return;
      if (!devToolsRef.current.contains(event.target)) {
        setShowMenu(false);
        setShowApiOptions(false);
        setShowLanguageOptions(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleApiOptions = () => {
    setShowApiOptions((prev) => !prev);
    if (showLanguageOptions) {
      setShowLanguageOptions(false);
    }
  };

  const toggleLanguageOptions = () => {
    setShowLanguageOptions((prev) => !prev);
    if (showApiOptions) {
      setShowApiOptions(false);
    }
  };

  const toggleDevToolsMenu = () => {
    setShowMenu((prev) => !prev);
    setShowApiOptions(false);
    setShowLanguageOptions(false);
  };

  return (
    <div className="dev-tools-wrapper" ref={devToolsRef}>
      <div
        onClick={() => {
          toggleDevToolsMenu();
        }}
        className="dev-tools-icon"
      >
        <MenuIcon width="40px" height="40px" />
      </div>
      {showMenu && (
        <div className="dev-tools-dropmenu-container">
          <ApiSelector showOptions={showApiOptions} toggleOptions={toggleApiOptions} />
          <LanguageSwitcher showOptions={showLanguageOptions} toggleOptions={toggleLanguageOptions} />
        </div>
      )}
    </div>
  );
};

export default DevHelperTools;
