import React from "react";

function Like() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 148 134"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(17 10)">
            <circle cx="57" cy="57" r="57" fill="#548CFF"></circle>
            <path
              fill="#FFF"
              d="M78.062 43.017c.165-.011.331-.017.5-.017 3.59 0 6.5 2.686 6.5 6 0 1.947-1.005 3.677-2.561 4.773 1.539.886 2.56 2.448 2.56 4.227 0 2.176-1.529 4.028-3.664 4.715 1.597.875 2.665 2.467 2.665 4.285 0 2.237-1.617 4.131-3.846 4.77 1.119.727 1.846 1.903 1.846 3.23 0 2.059-1.75 3.755-4 3.976V79H49.059a3.993 3.993 0 01-3.997-3.99V46.007c0-.808.316-1.542.832-2.082C48.15 41.018 57.537 32.243 58 29c.5-3.5 1.85-9 4.071-9h.668c2.94 0 5.57 2.38 5.742 5.304 0 0 1.58 11.63-4.42 17.696h14v.017zM26 50.99A3.991 3.991 0 0129.993 47h6.014A3.99 3.99 0 0140 50.99v28.02A3.991 3.991 0 0136.007 83h-6.014A3.99 3.99 0 0126 79.01V50.99z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Like;