import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<Object>;
  isLoading: boolean;
  error: Object | null;

  allBrandsData: Array<any>;
  allBrandsInitialFetch: boolean;
  isLoadingAllBrands: boolean;
  errorAllBrands: Object | null;

  creatingBrand: boolean;
  creatingBrandError: Object | null;

  updatingBrand: boolean;
  updatingBrandError: Object | null;
};

const INITIAL_STATE = {
  data: [],
  isLoading: true,
  error: null,

  allBrandsData: [],
  isLoadingAllBrands: true,
  allBrandsInitialFetch: true,
  errorAllBrands: null,

  creatingBrand: false,
  creatingBrandError: null,

  updatingBrand: false,
  updatingBrandError: null,
};

const brands = createSlice({
  name: "brands",
  initialState: INITIAL_STATE,
  reducers: {
    fetchBrandsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchBrandsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchBrandsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchAllBrandsStart: (state: State) => {
      if (state.allBrandsInitialFetch) {
        state.isLoadingAllBrands = true;
      }
    },
    fetchAllBrandsSuccess: (state: State, action) => {
      state.isLoadingAllBrands = false;
      state.allBrandsData = action.payload;
      if (state.allBrandsInitialFetch) {
        state.allBrandsInitialFetch = false;
      }
    },
    fetchAllBrandsFailed: (state: State, action) => {
      state.isLoadingAllBrands = false;
      state.errorAllBrands = action.payload;
    },

    createBrandStart: (state: State) => {
      state.creatingBrand = true;
    },
    createBrandSuccess: (state: State, action) => {
      const createdBrand = action.payload;
      state.allBrandsData.push(createdBrand);
      state.creatingBrand = false;
    },
    createBrandFailed: (state: State, action) => {
      state.creatingBrand = false;
      state.creatingBrandError = action.payload;
    },
    resetBrandCreateState: (state: State) => {
      state.creatingBrand = false;
      state.creatingBrandError = null; // Reset the error state
    },

    updateBrandStart: (state: State) => {
      state.updatingBrand = true;
    },
    updateBrandSuccess: (state: State, action) => {
      const updatedBrand = action.payload;
      state.allBrandsData = state.allBrandsData.map((brand) => {
        // Check if the brand is the one being updated
        if (brand.hasOwnProperty("id") && brand.id.value === updatedBrand.id.value) {
          return updatedBrand;
        } else {
          return brand;
        }
      });
      state.updatingBrand = false;
    },
    updateBrandFailed: (state: State, action) => {
      state.updatingBrand = false;
      state.updatingBrandError = action.payload;
    },
    resetBrandUpdateState: (state: State) => {
      state.updatingBrand = false;
      state.updatingBrandError = null; // Reset the error state
    },
  },
});

export default brands.reducer;

const {
  fetchBrandsStart,
  fetchBrandsSuccess,
  fetchBrandsFailed,
  fetchAllBrandsStart,
  fetchAllBrandsSuccess,
  fetchAllBrandsFailed,

  createBrandStart,
  createBrandSuccess,
  createBrandFailed,
  resetBrandCreateState,

  updateBrandStart,
  updateBrandSuccess,
  updateBrandFailed,
  resetBrandUpdateState,
} = brands.actions;

export const fetchBrands = () => async (dispatch: Function) => {
  try {
    dispatch(fetchBrandsStart());
    const api = new Api();
    const result = await api.getBrands();
    dispatch(fetchBrandsSuccess(result));
  } catch (err) {
    dispatch(fetchBrandsFailed(err));
  }
};

export const fetchAllBrands = () => async (dispatch: Function) => {
  try {
    dispatch(fetchAllBrandsStart());
    const api = new Api();
    const result = await api.getAllBrands();
    dispatch(fetchAllBrandsSuccess(result));
  } catch (err) {
    dispatch(fetchAllBrandsFailed(err));
  }
};

export const updateBrand = (brandId: string, payload: any) => async (dispatch: Function) => {
  try {
    dispatch(updateBrandStart());
    const api = new Api();
    const result = await api.postUpdateBrand(brandId, payload);
    dispatch(updateBrandSuccess(result));
  } catch (err) {
    dispatch(updateBrandFailed(err));
  }
};

export const createBrand = (payload: any) => async (dispatch: Function) => {
  try {
    dispatch(createBrandStart());
    const api = new Api();
    const result = await api.postCreateBrand(payload);
    dispatch(createBrandSuccess(result));
  } catch (err) {
    dispatch(createBrandFailed(err));
  }
};

export const resetBrandStates = () => async (dispatch: Function) => {
  dispatch(resetBrandCreateState());
  dispatch(resetBrandUpdateState());
};
