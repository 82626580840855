import React, { ComponentPropsWithoutRef, useState } from "react";
import "./Button.scss";
import { CircleLoader } from "../../../icons/Loaders";
import { DeleteIcon } from "../../../icons/Icons";
interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  variant?: "default" | "success" | "danger" | "warning";
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  rounded?: boolean;
  className?: string;
  children: any;
  onClick?: () => void;
}

interface ButtonCircleProps extends ComponentPropsWithoutRef<"button"> {
  variant?: "default" | "success" | "danger" | "warning";
  className?: string;
  children: any;
  onClick?: () => void;
}

interface ButtonSaveProps extends ComponentPropsWithoutRef<"button"> {
  variant?: "default" | "success" | "danger" | "warning";
  className?: string;
  children: any;
  onClick?: () => void;
}


interface ButtonFeedbackProps extends ButtonProps {
  children: any;
  onClick?: () => void;
}


const Button = ({
  variant,
  size = "medium",
  fullWidth = false,
  children,
  className,
  rounded = true,
  ...restOfProps
}: ButtonProps) => {
  return (
    <button
      className={[
        "so-button",
        `so-button--${variant}`,
        `so-button--${size}`,
        `${rounded ? "so-button-rounded" : ""}`,
        `${fullWidth ? "so-button-fullwidth" : ""}`,
        className,
      ].join(" ")}
      {...restOfProps}
    >
      {children}
    </button>
  );
};

Button.Circle = ({ variant, children, className, ...restOfProps }: ButtonCircleProps) => {
  return (
    <button className={["so-button-circle", `so-button--${variant}`, className].join(" ")} {...restOfProps}>
      {children}
    </button>
  );
};


// A button that shows a loader when clicked
const FeedbackButton = ({ variant, size = "medium", rounded = true, fullWidth = false, className, onClick, children, ...restOfProps }: ButtonFeedbackProps) => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(true);
    if (onClick) onClick();
  };

  return (
    <button
      className={[
        "so-button",
        `so-button--${variant}`,
        `so-button--${size}`,
        `${rounded ? "so-button-rounded" : ""}`,
        `${fullWidth ? "so-button-fullwidth" : ""}`,
        className,
      ].join(" ")}
      onClick={handleClick}
      {...restOfProps}
    >
      {isActive ? (
        <CircleLoader
          width="20px"
          height="20px"
          color="white" />
      ) : (
        children
      )}
    </button>
  );
};
Button.Feedback = FeedbackButton;

export default Button;
