import React, { useRef, useEffect, useState, memo } from "react";
import * as d3 from "d3";
import "./SparkGraph.scss";
import { useSelector } from "react-redux";

const Spark = ({ data, width, height, isVideo }) => {
  const currentBrand = useSelector((state: any) => {
    return state.currentBrand.data;
  });

  const svgRef = useRef();
  const [calibration, setCalibration] = useState(0);

  useEffect(() => {
    if (isVideo) {
      setCalibration(currentBrand.domainSettings.sparkline_calibration_video);
    } else {
      setCalibration(currentBrand.domainSettings.sparkline_calibration);
    }
  }, [currentBrand, isVideo]);

  useEffect(() => {
    if (!data || calibration === 0) return;
    if (JSON.stringify(data) === '[{"x":0,"y":0}]') return;

    const svg = d3.select(svgRef.current);

    const filteredData = data.filter((d) => d.hasOwnProperty("h")); // Filter out data points without 'h' attribute
    const xMax = d3.max(filteredData, (d) => d.x);
    const yMax = d3.max(filteredData, (d) => d.y);

    const xScale = d3
      .scaleLinear()
      .domain([xMax - 9 / 0.5, xMax])
      .range([0, width]);

    const yScale = d3
      .scaleLinear()
      .domain([0, yMax > calibration ? yMax : calibration])
      .range([height, 0]);

    svg.selectAll("*").remove(); // Clear previous elements

    const area = d3
      .area()
      .curve(d3.curveBasis)
      .x((d) => xScale(d.x))
      .y0(height)
      .y1((d) => yScale(d.y));

    svg.append("path").datum(data).attr("class", "area").attr("d", area).attr("fill", "rgba(0, 0, 0, 0.3)");

    const xAxis = d3
      .axisBottom(xScale)
      .tickSize(6, 1)
      .tickPadding(10)
      .tickValues([0, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1]);
  }, [data, calibration, width, height]);

  return (
    <svg ref={svgRef} width={width} height={height} className="spark-graph">
      <g ref={svgRef} />
    </svg>
  );
};

export default memo(Spark);
