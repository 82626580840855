import React, { useMemo } from "react";
import { CircleLoader } from "../../icons/Loaders";
import StackedAreaGraphContainer from "../graphs/StackedAreaGraph/StackedAreaGraphContainer";
import BarChartContainer from "../graphs/BarChart/BarChartContainer";
import { useTranslation } from "react-i18next";
import "./ConversionScore.scss";

type ConversionScoreProps = {
  loading: boolean;
  conversionsData: any;
};

const ConversionScore = ({ loading, conversionsData }: ConversionScoreProps) => {
  const { t } = useTranslation();

  const preparedStackedGraphData = useMemo(() => {
    return conversionsData.graphData.map((e) => {
      return { ...e, id: e.name };
    });
  }, [conversionsData]);

  const preparedBarChartData = useMemo(() => {
    if (!conversionsData) return [];
    return conversionsData.graphData.map((e) => {
      return {
        value: parseInt(e.total, 10),
        color: e.color,
        title: e.name,
      };
    });
  }, [conversionsData]);

  return (
    <div className="story-reach-container">
      <h3>Conversion Score</h3>
      <div className="graphs-container">
        <div className="origins-graph">
          <h4>Conversions flow</h4>
          <div>
            {loading ? (
              <div className="loader-container">
                <CircleLoader />
              </div>
            ) : (
              <StackedAreaGraphContainer data={preparedStackedGraphData} />
            )}
          </div>
        </div>
        <div className="details-graph">
          <h4>{t("story.common.details")}</h4>
          {loading ? (
            <div className="loader-container">
              <CircleLoader />
            </div>
          ) : (
            <>
              <p className="conversions performance">{t("story.common.performance")} x 10</p>
              <p className="conversions-performance-subtitle">Conversions</p>
              <p className="conversions-performance-value">{conversionsData?.performance}</p>
              <BarChartContainer data={preparedBarChartData} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConversionScore;
