import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Object;
  isLoading: boolean;
  error: Object | null;
};

const INITIAL_STATE = {
  data: {
    headliner: {},
    rewriter: {},
    rephraser: {},
    readability: {},
    timing: {},
    sentiment: [],
    categorizer: {},
  },
  isLoading: false,
  error: null,
};

const smartoctoAI = createSlice({
  name: "smartoctoAI",
  initialState: INITIAL_STATE,
  reducers: {
    fetchAIActionStart: (state: State) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchAIActionFailed: (state: State, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    fetchHeadlineSuggestionsSuccess: (state: State, action) => {
      state.data = { ...state.data, headliner: action.payload };
      state.isLoading = false;
    },
    fetchTimingSuccess: (state: State, action) => {
      state.data = { ...state.data, timing: action.payload };
      state.isLoading = false;
    },
    fetchParagraphRephraseSuccess: (state: State, action) => {
      state.data = { ...state.data, rephraser: action.payload };
      state.isLoading = false;
    },
    fetchArticleRewriteSuccess: (state: State, action) => {
      state.data = { ...state.data, rewriter: action.payload };
      state.isLoading = false;
    },
    fetchProduceCategoriesSuccess: (state: State, action) => {
      state.data = { ...state.data, categorizer: action.payload.categories };
      state.isLoading = false;
    },
    fetchArticleReadabilitySuccess: (state: State, action) => {
      state.data = { ...state.data, readability: action.payload.response };
      state.isLoading = false;
    },
    fetchSentimentSuccess: (state: State, action) => {
      state.data = { ...state.data, sentiment: action.payload.analysis };
      state.isLoading = false;
    },

    resetState: (state: State) => {
      state.data = INITIAL_STATE;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export default smartoctoAI.reducer;

const {
  fetchAIActionStart,
  fetchAIActionFailed,
  fetchHeadlineSuggestionsSuccess,
  fetchTimingSuccess,
  fetchParagraphRephraseSuccess,
  fetchArticleRewriteSuccess,
  fetchArticleReadabilitySuccess,
  fetchProduceCategoriesSuccess,
  fetchSentimentSuccess,
  resetState,
} = smartoctoAI.actions;

const produceId = function () {
  const date = new Date();
  const request_id = Date.parse(date.toString());
  return request_id.toString();
};

export const fetchHeadlineSuggestions = (text: string, client: string, type: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchAIActionStart());
    const api = new Api();
    let result = {};
    if ((client = "rpo")) {
      result = await api.getRPOAIheadliner(client, produceId(), text);
    } else {
      result = await api.getSOAIheadliner(client, produceId(), text, type);
    }
    dispatch(fetchHeadlineSuggestionsSuccess(result));
  } catch (err) {
    dispatch(fetchAIActionFailed(err));
  }
};

export const fetchTiming =
  (text: string, client: string, hasImages: boolean, hasVideos: boolean) => async (dispatch: Function) => {
    try {
      dispatch(fetchAIActionStart());
      const api = new Api();
      const optimize = "page_views";
      const request_id = produceId();
      const sort = "page_views.desc";
      const timeFrom = "00:00";
      const timeTo = "23:59";
      const result = await api.getPublishTimer(
        client,
        hasImages,
        hasVideos,
        optimize,
        request_id,
        sort,
        text,
        timeFrom,
        timeTo,
      );
      dispatch(fetchTimingSuccess(result));
    } catch (err) {
      dispatch(fetchAIActionFailed(err));
    }
  };

export const fetchParagraphRephrase = (text: string, type: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchAIActionStart());
    const api = new Api();
    const client = "so";
    const request_id = produceId();
    const result = await api.getParagraphRephraser(request_id, client, text, type);
    dispatch(fetchParagraphRephraseSuccess(result));
  } catch (err) {
    dispatch(fetchAIActionFailed(err));
  }
};

export const fetchProduceCategories = (text: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchAIActionStart());
    const api = new Api();
    const request_id = produceId();
    const result = await api.getProduceCategories(request_id, text);
    dispatch(fetchProduceCategoriesSuccess(result));
  } catch (err) {
    dispatch(fetchAIActionFailed(err));
  }
};

export const fetchArticleRewrite = (text: string, type: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchAIActionStart());
    const api = new Api();
    const request_id = produceId();
    const result = await api.getArticleRewrite(request_id, text, type);
    dispatch(fetchArticleRewriteSuccess(result));
  } catch (err) {
    dispatch(fetchAIActionFailed(err));
  }
};

export const fetchArticleReadability = (text: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchAIActionStart());
    const api = new Api();
    const request_id = produceId();
    const result = await api.getArticleReadability(request_id, text);
    dispatch(fetchArticleReadabilitySuccess(result));
  } catch (err) {
    dispatch(fetchAIActionFailed(err));
  }
};

export const fetchSentiment = (text: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchAIActionStart());
    const api = new Api();
    const request_id = produceId();
    const client = "so";
    const result = await api.getSentiment(request_id, client, text);
    dispatch(fetchSentimentSuccess(result));
  } catch (err) {
    dispatch(fetchAIActionFailed(err));
  }
};

export const resetSmartoctoAIState = () => async (dispatch: Function) => {
  dispatch(resetState());
};
