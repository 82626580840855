import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<Object>;
  isLoading: boolean;
  error: Object | null;
};

const INITIAL_STATE = {
  data: [],
  isLoading: true,
  error: null,
};

const impact = createSlice({
  name: "impact",
  initialState: INITIAL_STATE,
  reducers: {
    fetchImpactStart: (state: State) => {
      state.isLoading = true;
      state.data = [];
    },
    fetchImpactSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchImpactFailed: (state: State, action) => {
      state.isLoading = false;
      state.data = [];
      state.error = action.payload;
    },
  },
});

export default impact.reducer;

const { fetchImpactStart, fetchImpactSuccess, fetchImpactFailed } = impact.actions;

export const fetchImpact = (from, to, channel) => async (dispatch: Function) => {
  try {
    dispatch(fetchImpactStart());
    const api = new Api();
    const result = await api.getImpact(from, to, channel);
    dispatch(fetchImpactSuccess(result));
  } catch (err) {
    dispatch(fetchImpactFailed(err));
  }
};
