import React from "react";
import Pagination from "../pagination/Pagination";
import Input from "../common/Input";
import Select from "../select/Select";

type TableToolsProps = {
  paginationOptions: Array<number>;
  handleSearch: Function;
  numberOfResults: number;
  setNumberOfResults: Function;
  selectedPage: number;
  setSelectedPage: Function;
  totalNumberOfPages: number;
};

const TableTools = ({
  paginationOptions,
  handleSearch,
  selectedPage,
  setSelectedPage,
  totalNumberOfPages,
  numberOfResults,
  setNumberOfResults,
}: TableToolsProps) => {
  return (
    <div className="table-tools">
      <Input
        placeholder={"search"}
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
      />
      <Pagination totalPages={totalNumberOfPages} selectedPage={selectedPage} setSelectedPage={setSelectedPage} />
      <div className="table-entries-number">
        Showing{" "}
        <Select
          rounded
          options={paginationOptions}
          selectedOption={numberOfResults}
          setSelectedOption={setNumberOfResults}
        />{" "}
        entries
      </div>
    </div>
  );
};

export default TableTools;
