import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<Object>;
  isLoading: boolean;
  error: Object | null;
};

const multibrand = createSlice({
  name: "multibrand",
  initialState: {
    data: [],
    isLoading: true,
    error: null,
  },
  reducers: {
    fetchMultibrandStart: (state: State) => {
      state.isLoading = true;
    },
    fetchMultibrandSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchMultibrandFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default multibrand.reducer;

const { fetchMultibrandStart, fetchMultibrandSuccess, fetchMultibrandFailed } = multibrand.actions;

export const fetchMultibrand = () => async (dispatch: Function) => {
  try {
    dispatch(fetchMultibrandStart());
    const api = new Api();
    const result = await api.getMultibrand();
    dispatch(fetchMultibrandSuccess(result));
  } catch (err) {
    dispatch(fetchMultibrandFailed(err));
  }
};
