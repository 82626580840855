import React, { useState, useMemo, useCallback } from "react";
import StreamTableNavigation from "./StreamTableNavigation";
import StreamTableDetail from "./StreamTableDetail";
import StreamTableInk from "./StreamTableInk";
import Button from "../../components/ui/common/Button";
import TextArea from "../../components/ui/common/TextArea";
import { BellIcon, DiscIcon, LogoIcon, CheckmarkIcon, AuthorImage } from "../../icons/Icons";
import { CircleLoader } from "../../icons/Loaders";
import TimeAgoLong from "../utils/TimeAgoLong";
import { useTranslation } from "react-i18next";

import "./StreamTable.scss";
import { useAppDispatch } from "../../store";
import { createComment } from "../../store/slices/comments";
import { addTimelineItem } from "../../store/slices/activeFeedItem";

type StreamTableProps = {
  data: any,
  post: nny,
  loading: boolean,
};

const StreamTable = ({ data, post, loading }: StreamTableProps) => {
  const { t } = useTranslation();
  const [selectedTable, setSelectedTable] = useState("All");
  const [showedAll, setShowedAll] = useState(false);
  const [showCommentTextbox, setShowCommentTextbox] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const dispatch = useAppDispatch();

  const displayedIcon = useCallback((item) => {
    if (item.item_data.notification_type === "headertest_completed") {
      return <div className="checkmark-icon"></div>;
    }
    switch (item.item_type) {
      case "acts":
        return <DiscIcon width="15px" height="20px" color="#f45642" />;
      case "postings":
      case "updates":
      case "abTestSecretTreasure":
        return <BellIcon width="20px" height="20px" color="#1abcae" />;
      case "tentacles":
        return <LogoIcon width="25px" height="25px" color="#1abcae" />;
      case "comments":
        return <BellIcon width="20px" height="20px" color="#1abcae" />;
      // return <AuthorImage imageUrl={item.imageUrl} author={item['item_data']['name_first'] + ' ' + item['item_data']['name_last']} />
      default:
        break;
    }
  }, []);

  const handleCommentClick = () => {
    if (!showCommentTextbox) {
      setShowCommentTextbox(true);
    } else {
      dispatch(createComment(post.post_id, commentInput, addTimelineItem));
      setCommentInput("");
      setShowCommentTextbox(false);
    }
  };

  const handleCommentInputChange = (e) => {
    setCommentInput(e.target.value);
  };

  const baseUrl = useMemo(() => {
    let baseUrl;
    if (post?.post_url) {
      const url = new URL(post.post_url);
      baseUrl = url.protocol + "//" + url.hostname;
    } else {
      baseUrl = null;
    }

    return baseUrl;
  }, [post]);

  const transformedData = useMemo(() => {
    const all = [];
    const acts = [];
    const updates = [];
    const comments = [];
    const tentacles = [];

    data?.forEach((e, i) => {
      all.push({
        id: e.item_id,
        type: e.item_type,
        icon: displayedIcon(e),
        title: e.content || e.title,
        pubdate: e.pubdate,
        notificationType: e.item_data?.notification_type || "",
        inked: e.inked,
        inks: e.inks,
        author: e["item_data"]["name_first"] + " " + e["item_data"]["name_last"],
        details: <StreamTableDetail timelineItem={e} storyBaseUrl={baseUrl} />,
      });
      if (e.item_type === "acts") {
        acts.push({
          id: e.item_id,
          type: e.item_type,
          icon: <DiscIcon width="15px" height="20px" color="#f45642" />,
          title: e.content,
          pubdate: e.pubdate,
          notificationType: e.item_data?.notification_type || "",
          inked: e.inked,
          inks: e.inks,
          details: <StreamTableDetail timelineItem={e} storyBaseUrl={baseUrl} />,
        });
      } else if (e.item_type === "postings" || e.item_type === "updates") {
        updates.push({
          id: e.item_id,
          type: e.item_type,
          icon: <BellIcon width="20px" height="20px" color="#1abcae" />,
          title: e.title,
          pubdate: e.pubdate,
          notificationType: e.item_data?.notification_type || "",
          inked: e.inked,
          inks: e.inks,
          details: <StreamTableDetail timelineItem={e} storyBaseUrl={baseUrl} />,
        });
      } else if (e.item_type === "tentacles") {
        tentacles.push({
          id: e.item_id,
          type: e.item_type,
          icon: <LogoIcon width="20px" height="20px" color="#1abcae" />,
          title: e.content,
          pubdate: e.pubdate,
          notificationType: e.item_data?.notification_type || "",
          inked: e.inked,
          inks: e.inks,
          details: <StreamTableDetail timelineItem={e} storyBaseUrl={baseUrl} />,
        });
      } else if (e.item_type === "comments") {
        comments.push({
          id: e.item_id,
          type: e.item_type,
          icon: <BellIcon width="20px" height="20px" color="#1abcae" />,
          // icon: <AuthorImage imageUrl={e.imageUrl} author={e['item_data']['name_first'] + ' ' + e['item_data']['name_last']} />,
          title: e.content || e.title,
          pubdate: e.pubdate,
          notificationType: e.item_data?.notification_type || "",
          inked: e.inked,
          inks: e.inks,
          author: e["item_data"]["name_first"] + " " + e["item_data"]["name_last"],
          details: <StreamTableDetail timelineItem={e} storyBaseUrl={baseUrl} />,
        });
      }
    });

    return {
      All: all,
      Acts: acts,
      Updates: updates,
      Comments: comments,
      Tentacles: tentacles,
    };
  }, [data, displayedIcon, baseUrl]);

  return (
    <div className="stream-table-container">
      <h3>{t("story.stream.title")}</h3>
      <>
        {showCommentTextbox && (
          <TextArea
            value={commentInput}
            onChange={(e) => handleCommentInputChange(e)}
            className="stream-table-comment-box"
            placeholder="Type something..."
          ></TextArea>
        )}
        <Button
          variant="success"
          className="stream-table-comment-btn"
          disabled={showCommentTextbox && commentInput.length === 0}
          onClick={handleCommentClick}
        >
          {t("story.stream.comment")}
        </Button>
        <StreamTableNavigation setSelectedTable={setSelectedTable} data={transformedData} setShowedAll={setShowedAll} />
        {loading ? (
          <div className="stream-table-loader-container">
            <CircleLoader />
          </div>
        ) : (
          <table className="so-table">
            <tbody>
              {!showedAll && transformedData[selectedTable].length > 4
                ? transformedData[selectedTable].slice(0, 4).map((e) => {
                    return (
                      <tr key={e.id}>
                        <td className="icon-cell">{e.icon}</td>
                        <td>
                          <p>{e.title}</p>
                          {e.type === "comments" && (
                            <p className="stream-table-author">
                              Comment by <strong>{e.author}</strong>
                            </p>
                          )}
                        </td>
                        <td>{e.details}</td>
                        <td>
                          {e.notificationType === "headertest_completed" ? (
                            <CheckmarkIcon width="20px" height="20px" color="gray" />
                          ) : null}
                        </td>
                        <td>
                          <TimeAgoLong timestamp={e.pubdate} />
                        </td>
                        <td>
                          <StreamTableInk timelineItem={e} />
                        </td>
                      </tr>
                    );
                  })
                : transformedData[selectedTable].map((e) => {
                    return (
                      <tr key={e.id}>
                        <td className="icon-cell">{e.icon}</td>
                        <td>
                          <p>{e.title}</p>
                          {e.type === "comments" && (
                            <p className="stream-table-author">
                              Comment by <strong>{e.author}</strong>
                            </p>
                          )}
                        </td>
                        <td>{e.details}</td>
                        <td>
                          {e.notificationType === "headertest_completed" ? (
                            <CheckmarkIcon width="20px" height="20px" color="gray" />
                          ) : null}
                        </td>
                        <td>
                          <TimeAgoLong timestamp={e.pubdate} />
                        </td>
                        <td>
                          <StreamTableInk timelineItem={e} />
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        )}
        {!showedAll && transformedData[selectedTable].length > 4 && !loading ? (
          <div className="show-more">
            <div
              onClick={() => {
                setShowedAll(true);
              }}
            >
              {t("global.showall")}
            </div>
          </div>
        ) : null}
      </>
    </div>
  );
};

export default StreamTable;
