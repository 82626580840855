//@ts-ignore
import SocialChannelFacebook from "../../../assets/images/socialchannel-facebook.svg";
//@ts-ignore
import SocialChannelHome from "../../../assets/images/homepage_link.svg";
//@ts-ignore
import SocialChannelLinkedin from "../../../assets/images/socialchannel-linkedin.svg";
//@ts-ignore
import SocialChannelNewsletter from "../../../assets/images/socialchannel-newsletter.svg";
//@ts-ignore
import Origin from "../../../assets/images/origin.svg";
//@ts-ignore
import Pushmessage from "../../../assets/images/pushmessage.svg";
//@ts-ignore
import SocialChannelTwitter from "../../../assets/images/socialchannel-twitter.svg";
//@ts-ignore
import SocialChannelLink from "../../../assets/images/socialchannel-link.svg";
//@ts-ignore
import Boosted from "../../../assets/images/boosted.svg";

export const ICON_OPTIONS = {
  fb_post: { icon: "socialchannel-facebook", iconSVG: SocialChannelFacebook, name: "Facebook" },
  homepage_link: { icon: "socialchannel-home", iconSVG: SocialChannelHome, name: "Homepage" },
  linkedin: { icon: "socialchannel-linkedin", iconSVG: SocialChannelLinkedin, name: "LinkedIn" },
  newsletter: { icon: "socialchannel-newsletter", iconSVG: SocialChannelNewsletter, name: "Newsletter" },
  page: { icon: "origin", iconSVG: Origin, name: "Created" },
  pushmessage: { icon: "pushmessage", iconSVG: Pushmessage, name: "Pushmessage" },
  retweet: { icon: "socialchannel-twitter", iconSVG: SocialChannelTwitter, name: "Tweeter" },
  site_link: { icon: "socialchannel-link", iconSVG: SocialChannelLink, name: "post_type.link" },
  tweet: { icon: "socialchannel-twitter", iconSVG: SocialChannelTwitter, name: "Tweeter" },
  boosted: { icon: "boosted", iconSVG: Boosted, name: "Boosted" },
  video: { icon: "origin", iconSVG: Origin, name: "Created" },
  youtube: { icon: "IDK", iconSVG: Origin, name: "post_type.created" },
  created: { icon: "" },
};
