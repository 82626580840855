import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  notificationData: Array<Object>;
  notificationLoading: boolean;
  notificationError: Object | null;

  roiOwnPlatformData: Array<Object>;
  roiOwnPlatformLoading: boolean;
  roiOwnPlatformError: Object | null;

  roiSocialData: Array<Object>;
  roiSocialLoading: boolean;
  roiSocialError: Object | null;

  roiConversionsData: Array<Object>;
  roiConversionsLoading: boolean;
  roiConversionsError: Object | null;

  headerTestsData: Array<Object>;
  headerTestsLoading: boolean;
  headerTestsError: Object | null;
};

const INITIAL_STATE = {
  notificationData: [],
  notificationLoading: true,
  notificationError: null,

  roiOwnPlatformData: [],
  roiOwnPlatformLoading: true,
  roiOwnPlatformError: null,

  roiSocialData: [],
  roiSocialLoading: true,
  roiSocialError: null,

  roiConversionsData: [],
  roiConversionsLoading: true,
  roiConversionsError: null,

  headerTestsData: [],
  headerTestsLoading: true,
  headerTestsError: null,
};

const statistics = createSlice({
  name: "statistics",
  initialState: INITIAL_STATE,
  reducers: {
    fetchNotificationStart: (state: State) => {
      state.notificationLoading = true;
    },
    fetchNotificationSuccess: (state: State, action) => {
      state.notificationLoading = false;
      state.notificationData = action.payload;
    },
    fetchNotificationFailed: (state: State, action) => {
      state.notificationLoading = false;
      state.notificationError = action.payload;
    },

    fetchRoiOwnPlatformStart: (state: State) => {
      state.roiOwnPlatformLoading = true;
    },
    fetchRoiOwnPlatformSuccess: (state: State, action) => {
      state.roiOwnPlatformLoading = false;
      state.roiOwnPlatformData = action.payload;
    },
    fetchRoiOwnPlatformFailed: (state: State, action) => {
      state.roiOwnPlatformLoading = false;
      state.roiOwnPlatformError = action.payload;
    },

    fetchRoiSocialStart: (state: State) => {
      state.roiSocialLoading = true;
    },
    fetchRoiSocialSuccess: (state: State, action) => {
      state.roiSocialLoading = false;
      state.roiSocialData = action.payload;
    },
    fetchRoiSocialFailed: (state: State, action) => {
      state.roiSocialLoading = false;
      state.roiSocialError = action.payload;
    },

    fetchRoiConversionsStart: (state: State) => {
      state.roiConversionsLoading = true;
    },
    fetchRoiConversionsSuccess: (state: State, action) => {
      state.roiConversionsLoading = false;
      state.roiConversionsData = action.payload;
    },
    fetchRoiConversionsFailed: (state: State, action) => {
      state.roiConversionsLoading = false;
      state.roiConversionsError = action.payload;
    },

    fetchHeaderTestsStart: (state: State) => {
      state.headerTestsLoading = true;
    },
    fetchHeaderTestsSuccess: (state: State, action) => {
      state.headerTestsLoading = false;
      state.headerTestsData = action.payload;
    },
    fetchHeaderTestsFailed: (state: State, action) => {
      state.headerTestsLoading = false;
      state.headerTestsError = action.payload;
    },
  },
});

export default statistics.reducer;

const {
  fetchNotificationStart,
  fetchNotificationSuccess,
  fetchNotificationFailed,
  fetchRoiOwnPlatformStart,
  fetchRoiOwnPlatformSuccess,
  fetchRoiOwnPlatformFailed,
  fetchRoiSocialStart,
  fetchRoiSocialSuccess,
  fetchRoiSocialFailed,
  fetchRoiConversionsStart,
  fetchRoiConversionsSuccess,
  fetchRoiConversionsFailed,
  fetchHeaderTestsStart,
  fetchHeaderTestsSuccess,
  fetchHeaderTestsFailed,
} = statistics.actions;

export const fetchNotificationStatistics = (period: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchNotificationStart());
    const api = new Api();
    const result = await api.getNotificationsStatistics(period);

    dispatch(fetchNotificationSuccess(result));
  } catch (err) {
    dispatch(fetchNotificationFailed(err));
  }
};

export const fetchRoiOwnPlatformStatistics = (period: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchRoiOwnPlatformStart());
    const api = new Api();
    const result = await api.getRoiOwnPlatformStatistics(period);

    dispatch(fetchRoiOwnPlatformSuccess(result));
  } catch (err) {
    dispatch(fetchRoiOwnPlatformFailed(err));
  }
};

export const fetchRoiSocialStatistics = (period: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchRoiSocialStart());
    const api = new Api();
    const result = await api.getRoiSocialStatistics(period);
    dispatch(fetchRoiSocialSuccess(result));
  } catch (err) {
    dispatch(fetchRoiSocialFailed(err));
  }
};

export const fetchRoiConversionsStatistics = (period: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchRoiConversionsStart());
    const api = new Api();
    const result = await api.getRoiConversionsStatistics(period);
    dispatch(fetchRoiConversionsSuccess(result));
  } catch (err) {
    dispatch(fetchRoiConversionsFailed(err));
  }
};

export const fetchHeaderTestsStatistics = (period: string) => async (dispatch: Function) => {
  try {
    dispatch(fetchHeaderTestsStart());
    const api = new Api();
    const result = await api.getHeaderTestsStatistics(period);
    dispatch(fetchHeaderTestsSuccess(result));
  } catch (err) {
    dispatch(fetchHeaderTestsFailed(err));
  }
};
