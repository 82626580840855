import React, { Component } from "react";
import * as d3 from "d3";
import "./Graph.scss";

class CircleGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 200,
      height: 200,
      ratio: 1.0,
      expectation: 0,
      performance: 0,
    };
  }

  componentDidMount() {
    this.initChart();
  }

  initChart() {
    let kpi = this.props.data;

    var performance = kpi.value;
    var expectation = kpi.story_expectation ? kpi.story_expectation : kpi.expectation;

    this.setState({
      performance: performance,
      expectation: expectation,
    });

    // this.root.classList.add(performance > expectation ? 'v-positive' : 'v-negative');
    // // Remove possibly existing display class
    // this.root.classList.remove(performance > expectation ? 'v-negative' : 'v-positive');
    let kpiRatio = Math.min(performance, expectation) / Math.max(performance, expectation);

    const svg = d3.select(this.svgRef);
    svg.selectAll("*").remove();
    const container = svg.append("g");
    const fullCircle = container.append("circle").attr("class", "full-circle");
    const partCircle = container.append("circle").attr("class", "part-circle");

    let largeRadius = Math.min(this.state.width, this.state.height) / 2;
    let smallRadius = Math.max(2, largeRadius * kpiRatio);

    if (isNaN(largeRadius)) {
      largeRadius = 0;
    }
    if (isNaN(smallRadius)) {
      smallRadius = 0;
    }

    fullCircle.attr("cx", largeRadius).attr("cy", largeRadius).attr("r", largeRadius);
    //   .attr('fill', 'green')
    partCircle.attr("cx", largeRadius).attr("cy", largeRadius).attr("r", smallRadius);
  }

  render() {
    return (
      <svg
        ref={(ref) => (this.svgRef = ref)}
        height={this.state.height}
        className={`circle-graph ${this.state.performance > this.state.expectation ? "positive" : "negative"}`}
      ></svg>
    );
  }
}

export default CircleGraph;
