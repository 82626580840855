import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import MainNavigation from "../../components/navigation/MainNavigation";
import SideNavigation from "../../components/navigation/SideNavigation";
import Story from "../../components/story/Story";
import { fetchFeedInkedItems } from "../../store/slices/feed";
import { useNavigate, useParams } from "react-router";
import { fetchActiveFeedItem, fetchActiveFeedItemCVI } from "../../store/slices/activeFeedItem";
import { CircleLoader } from "../../icons/Loaders";
import DataNotAvailable from "../../components/errors/DataNotAvailable";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch } from "../../store";
import { useSearchParams } from "react-router-dom";

const Inked = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const items = useSelector((state: any) => state.feed.inkedData);
  const isLoading = useSelector((state: any) => state.feed.isLoading);
  const [selectedFilter, setSelectedFilter]: [string, Function] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const { id } = useParams();

  useEffect(() => {
    const filter = searchParams.get("filter");
    setSelectedFilter(filter || "");

    dispatch(fetchFeedInkedItems());
  }, [dispatch]);

  const transformedItems = useMemo(() => {
    return items.map((e) => {
      return {
        title: e.title,
        description: e.content,
        itemType: e.item_type,
        postId: e.post_id,
        itemId: e.item_id,
        inked: e.inked,
        pubDate: e.pubdate,
        key: uuidv4(),
      };
    });
  }, [items]);

  useEffect(() => {
    if (!id && transformedItems[0]?.postId) {
      navigate(`/inked/${transformedItems[0].postId}`);
    }
  }, [transformedItems, id, navigate]);

  useEffect(() => {
    if (!id) return;
    if (id) {
      dispatch(fetchActiveFeedItem(id, {}));
      dispatch(fetchActiveFeedItemCVI(id));
    }
  }, [id, dispatch]);

  const filters = [
    {
      filter: "Acts",
      selected: false,
    },
    {
      filter: "Updates",
      selected: false,
    },
    {
      filter: "Comments",
      selected: false,
    },
    {
      filter: "Tentacles",
      selected: false,
    },
  ];

  const pageContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="loader-container">
          <CircleLoader />
        </div>
      );
    }
    if (transformedItems.length === 0) {
      return <DataNotAvailable pageType="inked" />;
    }
    return <Story />;
  }, [isLoading]);

  return (
    <div>
      <MainNavigation />
      <div className="container">
        <SideNavigation
          title="Inked"
          items={transformedItems}
          filters={filters}
          isLoading={isLoading}
          filtersMessage="Which inked items do you want to see?"
          page="inked"
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
        <div className="main-page-container">{pageContent}</div>
      </div>
    </div>
  );
};

export default Inked;
