import React from "react";

function Wow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 148 134"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(17 10)">
            <circle cx="57" cy="57" r="57" fill="#FFDA69"></circle>
            <ellipse cx="57" cy="79" fill="#252B37" rx="17" ry="22"></ellipse>
            <path
              fill="#252B37"
              d="M85.997 53.825c-4.895.863-9.757-3.507-10.86-9.762-1.103-6.255 1.971-12.025 6.866-12.888 4.895-.863 9.757 3.507 10.86 9.762 1.103 6.255-1.971 12.025-6.866 12.888zm-57.134.388c-4.895-.863-7.969-6.633-6.866-12.888 1.103-6.254 5.965-10.625 10.86-9.762 4.895.863 7.97 6.633 6.866 12.888-1.102 6.255-5.965 10.626-10.86 9.762zm63.69-39.299a2.5 2.5 0 01-3.65 3.418 9.113 9.113 0 00-6.202-2.884 9.106 9.106 0 00-5.984 1.828 2.5 2.5 0 11-3.01-3.993 14.105 14.105 0 019.256-2.828c3.7.194 7.107 1.808 9.59 4.46zm-61.356.974a9.113 9.113 0 00-6.202 2.884 2.5 2.5 0 01-3.65-3.417 14.112 14.112 0 019.59-4.46c3.366-.176 6.618.84 9.256 2.828a2.5 2.5 0 01-3.01 3.993 9.106 9.106 0 00-5.984-1.828z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Wow;
