import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { fetchTestArchive, toggleTestDisabled } from "../../../store/slices/abTests";
import MainNavigation from "../../../components/navigation/MainNavigation";
import DateTimeLong from "../../../components/utils/DateTimeLong";
import { CircleLoader } from "../../../icons/Loaders";
import "./ABTests.scss";
import ToggleSwitch from "../../../components/ui/toggleSwitch/ToggleSwitch";
import ToolTip from "../../../components/ui/common/ToolTip";
import QuestionMarkIcon from "../../../icons/QuestionMarkIcon";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

const ABTests = () => {
  const dispatch = useAppDispatch();
  const [preparingData, setPreparingData] = useState(true);
  const testsData = useSelector((state: any) => state.abTests.data);
  const isLoading = useSelector((state: any) => state.abTests.isLoading);
  const currentBrand = useSelector((state: any) => state.currentBrand.data);

  useEffect(() => {
    document.title = "A/B-test archive - Smartocto real-time";
  }, []);

  const handleToggleOverwrite = (id: string) => {
    dispatch(toggleTestDisabled(id));
  };
  const timezone = useMemo(() => {
    return currentBrand.domainSettings.timezone;
  }, [currentBrand]);

  const preparedData = useMemo(() => {
    let results = [];
    const optionLetters = ["A", "B", "C", "D", "E"];

    if (!testsData?.results) return [];
    testsData?.results.forEach((e) => {
      let result = { ...e };
      if (Array.isArray(e.stats)) {
        let tempTestData = [];
        result.testData.forEach((obj, index) => {
          const percentageLoyaltyClick = result.stats[index].percentageLoyaltyClick
            ? Number.parseInt(result.stats[index].percentageLoyaltyClick)
            : result.stats[index].percentageLoyaltyClick;

          //@ts-ignore
          tempTestData.push({ ...obj, ...result.stats[index], percentageLoyaltyClick });
        });
        result.testData = tempTestData;
      } else {
        let tempTestData = [];
        result.testData.forEach((obj, index) => {
          //@ts-ignore
          tempTestData.push({ ...obj, optionLetter: optionLetters[index] }); // Workaround for cancelled tests since they don't return optionLetter
        });

        result.testData = tempTestData;
      }

      // check if all items in testData do have percentageLoyaltyClick === 0 if so set test.noLoyaltyClick = true
      const noLoyaltyClick = result.testData.every(function (testDataItem) {
        return testDataItem.percentageLoyaltyClick <= 0;
      });

      result.noLoyaltyClick = noLoyaltyClick;

      // @ts-ignore
      results.push(result);
    });
    setPreparingData(false);
    return { ...testsData, results: results };
  }, [testsData]);

  useEffect(() => {
    dispatch(fetchTestArchive());
  }, [dispatch]);

  return (
    <>
      <MainNavigation />
      <div className="page-container-no-side-nav ab-tests-wrapper">
        <h1>A/B-test archive</h1>
        {isLoading ? <p className="ab-test-results-general">Loading tests</p> : null}
        {!isLoading && preparingData ? <p className="ab-test-results-general">Loading GUI</p> : null}
        {!isLoading && !preparingData ? (
          <p className="ab-test-results-general">
            {testsData?.results?.length} tests in the last {testsData?.days} days
          </p>
        ) : null}

        {!isLoading && !preparingData ? (
          preparedData?.results.map((testResult) => {
            return (
              <div className="ab-test-container" key={testResult.id}>
                <p className="ab-test-creation-date">
                  <DateTimeLong
                    inputDateTime={new Date(
                      DateTime.fromJSDate(new Date(testResult.creationDate))
                        .setZone(timezone)
                        .toFormat("yyyy-MM-dd HH:mm"),
                    ).toString()}
                  />
                </p>
                <Link to={`/stories/${testResult.relationId}`} className="title-link">
                  <h3 className="ab-test-title">{testResult.title}</h3>
                </Link>

                {testResult.status === "active" && <span className="ab-test-header-icon"></span>}
                <div className="ab-test-stats">
                  <div className="details">
                    <div>
                      Status: <span className="detail-bold">{testResult.status}</span>
                    </div>
                    <div>Duration: {testResult.elapsedTime}</div>
                    <div>
                      Reason: <span className="detail-bold">{testResult.reason}</span>
                    </div>
                    <div>Id: {testResult.id}</div>
                  </div>
                  <div className="details">
                    <div>Tester: {testResult.createdBy.name}</div>
                    <div>Test served: {testResult.totalImpressions}</div>
                    <div>{testResult.winner}</div>
                  </div>
                </div>

                <div className="ab-test-data">
                  <table className="ab-test-data-table">
                    <thead>
                      <tr>
                        <th className="option-letter"></th>
                        <th className="option-title"></th>
                        {testResult.status !== "canceled" && (
                          <>
                            <th className="option-views">Views</th>
                            <th className="option-ctr">
                              CTR{" "}
                              <ToolTip
                                message={
                                  "Clickthrough rate (CTR): The percentage of visitors that - after they saw the header - clicked on the teaser."
                                }
                              >
                                <QuestionMarkIcon />
                              </ToolTip>
                            </th>
                            {testsData?.useLTR && (
                              <th className="option-ltr">
                                LTR{" "}
                                <ToolTip
                                  message={
                                    "Loyaltyclickthrough rate (LTR): The percentage of visitors that - after they saw the header - clicked on the teaser and read the article for at least 10 seconds."
                                  }
                                >
                                  <QuestionMarkIcon />
                                </ToolTip>
                              </th>
                            )}
                            {!testsData?.useLTR && !testResult.noLoyaltyClick && (
                              <th className="option-loyalty">Loyalty</th>
                            )}
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {testResult.testData.map((test, i) => {
                        return (
                          <tr className={test.won ? "test-won" : ""} key={test.id}>
                            <td className="option-letter">{test.optionLetter}</td>
                            {test.title && test?.pictures?.[0] ? (
                              <td className="option-image">
                                <img src={test?.pictures?.[0] || ""} alt={test?.optionLetter || ""} />
                                <span>{test?.title}</span>
                              </td>
                            ) : test.title ? (
                              <td className="option-title">{test.title}</td>
                            ) : (
                              <td className="option-image">
                                <img src={test?.pictures?.[0] || ""} alt={test?.optionLetter || ""} />
                              </td>
                            )}
                            {testResult.status !== "canceled" && (
                              <>
                                <td className="option-views">{test.impressions}</td>
                                <td className="option-ctr">{test.ctr ? test.ctr.toFixed(2) : 0} %</td>
                                {testsData?.useLTR && (
                                  <td className="option-ltr">{test.ltr ? test.ltr.toFixed(2) : 0} %</td>
                                )}
                                {!testsData?.useLTR && !testResult.noLoyaltyClick && (
                                  <td className="option-loyalty">
                                    <div className="progress-bar">
                                      {test.percentageLoyaltyClick <= 5 ? (
                                        <span
                                          style={{
                                            textAlign: "right",
                                            width: test.percentageLoyaltyClick + "%",
                                          }}
                                        >
                                          {test.percentageLoyaltyClick}%
                                        </span>
                                      ) : (
                                        <span
                                          style={{
                                            textAlign: "right",
                                            width: test.percentageLoyaltyClick + "%",
                                            paddingRight: "5px",
                                          }}
                                        >
                                          {test.percentageLoyaltyClick}%
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                )}
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="ab-test-winning-results">
                    {testResult.status === "completed" && testResult.winnerIndex > 0 && (
                      <div>
                        <span className="detail-bold">Winning Title:</span>
                        {testResult.winnerGained !== undefined ? (
                          <div className="detail-bold">
                            {testResult.winnerGained > 0 ? (
                              <span>{testResult.winnerGained.toFixed(0)}%</span>
                            ) : (
                              <span>No</span>
                            )}{" "}
                            increase
                          </div>
                        ) : (
                          <div className="detail-bold">{testResult.winnerClicks} obtained clicks</div>
                        )}
                        during this test
                        <div className="ab-test-overwrite-switch">
                          <span
                            title={
                              testResult.hasImage
                                ? "To save bandwidth, the original teaser is shown, toggle is disabled"
                                : "Toggle to change the visibility of the winning teaser"
                            }
                          >
                            <ToggleSwitch
                              checked={testResult.disabled === false}
                              disabled={testResult.hasImage}
                              onChange={(e) => handleToggleOverwrite(testResult.id)}
                            />
                          </span>
                          &nbsp;Overwrite teaser
                        </div>
                      </div>
                    )}

                    {((testResult.status === "completed" && testResult.winnerIndex === -1) ||
                      testResult.status === "canceled") && <div className="detail-bold">No Winning Title</div>}

                    {testResult.status === "completed" && testResult.winnerIndex === 0 && (
                      <div className="detail-bold">Original title won</div>
                    )}
                  </div>
                </div>

                <div className="ab-test-explanation">{testResult.explanation}</div>
              </div>
            );
          })
        ) : (
          <div className="ab-tests-loader-container">
            <CircleLoader width="150px" />
          </div>
        )}
      </div>
    </>
  );
};

export default ABTests;
