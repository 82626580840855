import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<Object>;
  isLoading: boolean;
  error: Object | null;
};

const sections = createSlice({
  name: "sections",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    fetchSectionsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchSectionsSuccess: (state: State, action) => {
      state.isLoading = false;
      if (action.payload.brandId) {
        const brandSections = action.payload.sections.find(brandSections => brandSections.brandId === action.payload.brandId);

        if (brandSections) {
          state.data = brandSections.sections;
        }
      } else {
        state.data = action.payload.sections;
      }
    },
    fetchSectionsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default sections.reducer;

const { fetchSectionsStart, fetchSectionsSuccess, fetchSectionsFailed } = sections.actions;

export const fetchSections = (type = 'feed', brandId: string|undefined = undefined) => async (dispatch: Function) => {
  try {
    dispatch(fetchSectionsStart());
    const api = new Api();
    const result = await api.getSections(type);
    dispatch(fetchSectionsSuccess({ sections: result, brandId: brandId }));
  } catch (err) {
    dispatch(fetchSectionsFailed(err));
  }
};