import { createSlice } from "@reduxjs/toolkit";

type State = {
  modalStack: Array<string>;
};

const modalControll = createSlice({
  name: "modalControll",
  initialState: {
    modalStack: [],
  },
  reducers: {
    addModalToStack: (state: State, action: { payload: string }) => {
      state.modalStack = [...state.modalStack, action.payload];
    },
    removeModalFromTheStack: (state: State) => {
      state.modalStack.pop();
    },
  },
});

export default modalControll.reducer;

export const { addModalToStack, removeModalFromTheStack } = modalControll.actions;
