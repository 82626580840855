import React, { useEffect, useMemo, useState } from "react";
import { ArrowIcon, SaveIcon } from "../../icons/Icons";

type ApiSelectorProps = {
  showOptions: boolean;
  toggleOptions: Function;
};

const ApiSelector = ({ showOptions, toggleOptions }: ApiSelectorProps) => {
  const [selectedAPI, setSelectedAPI] = useState("api-staging2.smartocto.com");

  useEffect(() => {
    const currentlySetApiHost = window.localStorage.getItem("apihost");
    if (currentlySetApiHost) {
      setSelectedAPI(currentlySetApiHost);
    }
  }, []);

  const handleApiEndpointChange = (value: string) => {
    setSelectedAPI(value);
    toggleOptions();
  };

  const confirmApiEndpointChange = () => {
    window.localStorage.setItem("apihost", selectedAPI);
    /* istanbul ignore else */
    if (
      window.confirm(`Api host will be set to: \n
      ${selectedAPI} 
      \nRemove "apihost" key from this browsers localStorage if something isn't right 
      \nAfter you click on OK, the window will be reloaded to apply your change
    `)
    ) {
      window.location.reload();
    }
  };

  const apiOptions = useMemo(() => {
    return ["api-staging.smartocto.com", "api-staging2.smartocto.com", "api.smartocto.com", "api.smartocto.loc:8888"];
  }, []);

  return (
    <div className="api-selector-dropmenu-container">
      <p>Set active API-host</p>
      <div className="dev-tools-selector-current-value">
        <div className="value">{selectedAPI}</div>
        <div className="icons">
          <div
            className="drop-icon"
            onClick={() => {
              toggleOptions();
            }}
          >
            <div className={`${showOptions && "active"}`}>
              <ArrowIcon width="20px" height="20px" />
            </div>
          </div>
          <div
            className="save-icon"
            onClick={() => {
              confirmApiEndpointChange();
            }}
          >
            <SaveIcon width="20px" height="20px" />
          </div>
        </div>
      </div>
      {showOptions && (
        <div className="api-options-container">
          {apiOptions.map((endpoint) => {
            return (
              <div
                className="api-option"
                onClick={() => {
                  handleApiEndpointChange(endpoint);
                }}
              >
                {endpoint}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ApiSelector;
