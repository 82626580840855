import { useCallback } from "react";
import { useSelector } from "react-redux";

const useIsFeatureAllowed = () => {
  const brandInfo = useSelector((state: any) => state.currentBrand.data);

  const checkIfAllowed = useCallback(
    (feature: string) => {
      return brandInfo?.features?.indexOf(feature) > -1;
    },
    [brandInfo],
  );

  return checkIfAllowed;
};

export default useIsFeatureAllowed;
