import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import Table from "../../../components/ui/table/Table";
import MainNavigation from "../../../components/navigation/MainNavigation";
import { fetchImportTypes, fetchImports, deleteImport } from "../../../store/slices/imports";
import Button from "../../../components/ui/common/Button";
import { EditIcon, DeleteIcon } from "../../../icons/Icons";
import { CircleLoader } from "../../../icons/Loaders";
import Modal from "../../../components/ui/modal/Modal";
import EditImportModal from "../../../components/modals/EditImportModal/EditImportModal";
import { PlusIcon } from "../../../icons/Icons";
import CollapsibleCell from "../../../components/ui/table/CollapsibleCell";
import EditDisplayedColumnsModal from "../EditDisplayedColumnsModal";
import "../Admin.scss";
import "./imports.scss";

const Imports = () => {
  const dispatch = useAppDispatch();
  const importsNavPath = "/admin/imports";
  const [editingColumns, setEditingColumns] = useState(false);
  const imports = useSelector((state: any) => state.imports.data);
  const isLoading = useSelector((state: any) => state.imports.isLoading);
  const [editingImport, setEditingImport] = useState(false);
  const [importEditData, setImportEditData] = useState({});

  useEffect(() => {
    document.title = "Admin - Imports - Smartocto real-time";
  }, []);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    // if the id is found in the imports list, open the modal
    if (imports.length > 0 && imports.find((item) => item.id === id) && (!editingImport || (importEditData.hasOwnProperty("id") && importEditData["id"] && importEditData["id"] !== id))) {
      handleEdit(id);
    }
    // navigate to the base path if the id is not found in the imports list
    if (id && imports.length > 0 && imports.find((item) => item.id === id) === undefined) {
      navigate(importsNavPath);
    }
  }, [id, imports]);

  useEffect(() => {
    if (!id && Object.keys(importEditData).length > 0) {
      navigate(`${importsNavPath}/${importEditData["id"]}`);
    }
  }, [id, navigate, importEditData]);

  useEffect(() => {
    if (!id && Object.keys(importEditData).length === 0) {
      navigate(importsNavPath);
    }
  }, [id, navigate, importEditData]);

  const handleEdit = (id) => {
    const importData = imports.find((item) => item.id === id);
    if (importData) {
      setEditingImport(true);
      setImportEditData(importData);
    }
  };
  const handleDelete = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteImport(id));
    }
  };

  const columns = [
    { title: "id", label: "id" },
    { title: "insertMethod", label: "Insert Method" },
    { title: "index", label: "Index" },
    { title: "active", label: "Active" },
    { title: "type", label: "Type" },
    { title: "intoType", label: "IntoType" },
    { title: "url", label: "URL" },
    { title: "brandId", label: "BrandId" },
    { title: "actions", label: "Actions" },
  ];

  const preparedColumns = useMemo(() => {
    try {
      const tablesConfig = JSON.parse(localStorage.tablesConfig);
      const excludedColumns = tablesConfig.imports;
      return columns.filter((obj) => !excludedColumns.includes(obj.label));
    } catch {
      return columns;
    }
  }, []);

  const dataWithActions = imports.map((e, i) => {
    return {
      ...e,
      sort: {
        id: e.id,
        insertMethod: e?.insertMethod,
        index: e?.index,
        active: e?.active,
        type: e?.type,
        intoType: e?.intoType,
        url: e?.url,
        brandId: e?.brandId,
      },
      insertMethod: e.insertMethod || "",
      index: e.index || "",
      active: e.active ? "yes" : "no",
      intoType: e.intoType || "",
      url: (
        <div className="imports-url-container">
          <CollapsibleCell>{e.url || ""}</CollapsibleCell>
        </div>
      ),

      actions: (
        <div style={{ display: "flex" }}>
          <Button
            variant="warning"
            data-testid={`editButton${i}`}
            size="small"
            style={{ marginLeft: "10px", marginRight: "10px" }}
            onClick={() => {
              handleEdit(e.id);
              setEditingImport(true);
            }}
          >
            <EditIcon />
          </Button>

          <Button.Feedback
            variant="danger"
            data-testid={`deleteButton${i}`}
            size="small"
            onClick={() => {
              handleDelete(e.id);
            }}
          >
            <DeleteIcon />
          </Button.Feedback>
        </div>
      ),
    };
  });

  useEffect(() => {
    dispatch(fetchImports());
    dispatch(fetchImportTypes());
  }, []);

  const handleModalClose = () => {
    setEditingImport(false);
    setImportEditData({});
    // Ensure navigation to base path after the modal is closed
    navigate(importsNavPath);
  };

  return (
    <>
      <Modal isOpen={editingImport} onClose={handleModalClose}>
        <EditImportModal data={importEditData} onClose={handleModalClose} />
      </Modal>
      <Modal
        isOpen={editingColumns}
        onClose={() => {
          setEditingColumns(false);
        }}
      >
        <EditDisplayedColumnsModal tableTitle="imports" columns={columns} />
      </Modal>
      <MainNavigation />
      <div className="page-container-no-side-nav">
        <h1 className="admin-page-title">Imports </h1>

        <div className="admin-page-header-btns">
          <Button
            style={{ marginLeft: 20 }}
            title="Edit Notifiers columns"
            variant="success"
            onClick={() => {
              setEditingColumns(true);
            }}
          >
            <EditIcon /> Edit Columns
          </Button>
        </div>

        {!isLoading ? (
          <div className="table-container">
            <Table showRowNumbers={true} rowData={dataWithActions} columnsData={preparedColumns} />
          </div>
        ) : (
          <div className="loader-container">
            <CircleLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default Imports;
