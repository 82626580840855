import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import Table from "../../../components/ui/table/Table";
import MainNavigation from "../../../components/navigation/MainNavigation";
import Pill from "../../../components/ui/common/Pill";

import { fetchMultibrand } from "../../../store/slices/multibrand";
import Button from "../../../components/ui/common/Button";
import { EditIcon, DeleteIcon } from "../../../icons/Icons";
import { CircleLoader } from "../../../icons/Loaders";

// import "../Admin.scss";

const Multibrand = () => {
  const dispatch = useAppDispatch();
  const multibrandData = useSelector((state: any) => state.multibrand.data);
  const isLoading = useSelector((state: any) => state.multibrand.isLoading);

  useEffect(() => {
    document.title = "Multibrand";
  }, []);

  const handleEdit = (id) => {};
  const handleDelete = (id) => {};

  const columns = [
    { title: "id", label: "id" },
    { title: "description", label: "Description" },
    { title: "user", label: "Last modified by" },
    { title: "brands", label: "Brands" },
    { title: "notOnHomepage", label: "Not on homepage" },
    { title: "regionOnly", label: "Region only" },
    { title: "weightedAverage", label: "Weighted result" },
    { title: "actions", label: "Actions" },
  ];

  const dataWithActions = multibrandData.map((e, i) => {
    return {
      ...e,
      description: e.description || "",
      brands: e.brands
        ? e.brands.map((brand) => (
            <Pill key={brand}>
              {brand}{" "}
              <span
                style={{
                  width: 18,
                  height: 18,
                  borderRadius: 18,
                  cursor: "pointer",
                  display: "inline-block",
                  border: "1px solid gainsboro",
                  textAlign: "center",
                  lineHeight: "15px",
                }}
              >
                x
              </span>
            </Pill>
          ))
        : "",

      notOnHomepage: e.notOnHomepage ? "true" : "false",
      regionOnly: e.regioOnly ? "true" : "false",
      weightedAverage: e.weightedAverage ? "true" : "false",

      actions: (
        <div style={{ display: "flex" }}>
          <Button
            variant="warning"
            data-testid={`editButton${i}`}
            size="small"
            style={{ marginLeft: "10px", marginRight: "10px" }}
            onClick={() => {
              handleEdit(e.id);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            variant="danger"
            data-testid={`deleteButton${i}`}
            size="small"
            onClick={() => {
              handleDelete(e.id);
            }}
          >
            <DeleteIcon />
          </Button>
        </div>
      ),
    };
  });

  useEffect(() => {
    dispatch(fetchMultibrand());
  }, [dispatch]);

  return (
    <>
      <MainNavigation />
      <div className="page-container-no-side-nav">
        <h1 className="admin-page-title">Multibrand </h1>
        {!isLoading ? (
          <div className="table-container">
            <Table showRowNumbers={true} rowData={dataWithActions} columnsData={columns} />
          </div>
        ) : (
          <div className="loader-container">
            <CircleLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default Multibrand;
