import React from "react";
import GrowthIndicators from "./GrowthIndicators";
import "./ReportRowWIthNumbers.scss";

type ReportRowWIthNumbersProps = {
  title: string;
  description: string;
  items: Array<any>;
  type: string;
};

const ReportRowWithNumbers = ({ title, description, items, type }: ReportRowWIthNumbersProps) => {
  return (
    <div className="report-row-container">
      <div className="report-row-subject">
        <div className="report-row-subject-title">{title}</div>
        <div className="report-row-subject-description">{description}</div>
      </div>
      <div className="report-row-values">
        {items.map((item) => {
          return (
            <div className="report-row-value-container">
              <div className="report-row-value-container-title">{item.title}</div>
              <div className="report-row-value">
                <GrowthIndicators growth={Number(item.growth)} />
                <div className="report-row-value-digits-container">
                  <div className="report-row-value-digit">
                    {item.mainNumber.value}
                    {item.mainNumber.percentage && "%"}
                  </div>
                  <div className="report-row-percentage-diff">
                    <span className={item.growth > 0 ? "green" : ""}>{item.growth}% </span>
                    {type === "weekly" ? "last week" : "yesterday"}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReportRowWithNumbers;
