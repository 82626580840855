import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<Object>;
  isLoading: boolean;
  error: Object | null;
};

const INITIAL_STATE = {
  data: [],
  isLoading: true,
  error: null,
};

const abTests = createSlice({
  name: "abTests",
  initialState: INITIAL_STATE,
  reducers: {
    fetchTestArchiveStart: (state: State) => {
      state.isLoading = true;
    },
    fetchTestArchiveSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchTestArchiveFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    toggleTestDisabledStart: (state: State) => {
      state.isLoading = true;
    },
    toggleTestDisabledSuccess: (state: State, action) => {
      state.isLoading = false;
      //@ts-ignore
      const testData = state.data.results.find(test => test.id === action.payload.id);
      testData.disabled = action.payload.disabled;
    },
    toggleTestDisabledFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default abTests.reducer;

const { 
  fetchTestArchiveStart, 
  fetchTestArchiveSuccess, 
  fetchTestArchiveFailed,
  toggleTestDisabledStart,
  toggleTestDisabledSuccess,
  toggleTestDisabledFailed
} = abTests.actions;

export const fetchTestArchive = () => async (dispatch: Function) => {
  try {
    dispatch(fetchTestArchiveStart());
    const api = new Api();
    const result = await api.getTestArchive();
    dispatch(fetchTestArchiveSuccess(result));
  } catch (err) {
    dispatch(fetchTestArchiveFailed(err));
  }
};

export const toggleTestDisabled = (id) => async (dispatch: Function) => {
  try {
    dispatch(toggleTestDisabledStart());
    const api = new Api();
    const result = await api.postTestDisabled(id);
    dispatch(toggleTestDisabledSuccess({id, disabled: result.disabled}));
  } catch (err) {
    dispatch(toggleTestDisabledFailed(err));
  }
};
