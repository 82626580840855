import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  isLoading: boolean;
  data: Array<Object>;
  error: Object | null;

  creatingUmbrellaBrand: boolean;
  creatingUmbrellaBrandError: Object | null;
  updatingUmbrellaBrand: boolean;
  updatingUmbrellaBrandError: Object | null;
  deletingUmbrellaBrand: boolean;
  deletingUmbrellaBrandError: Object | null;
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: null,

  creatingUmbrellaBrand: false,
  creatingUmbrellaBrandError: null,

  updatingUmbrellaBrand: false,
  updatingUmbrellaBrandError: null,
  
  deletingUmbrellaBrand: false,
  deletingUmbrellaBrandError: null,
};

const umbrellaBrands = createSlice({
  name: "umbrellaBrands",
  initialState: INITIAL_STATE,
  reducers: {
    fetchUmbrellaBrandsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchUmbrellaBrandsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload.map((e: any, i: number) => {
        return {
          ...e,
          brands: Array.isArray(e.brands) ? e.brands : e.brands.split(' '),
        }
      });
    },
    fetchUmbrellaBrandsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    createUmbrellaBrandStart: (state: State) => {
      state.creatingUmbrellaBrand = true;
    },
    createUmbrellaBrandSuccess: (state: State, action) => {
      const createdUmbrellaBrand = action.payload;
      state.data.push(createdUmbrellaBrand);
      state.creatingUmbrellaBrand = false;
    },
    createUmbrellaBrandFailed: (state: State, payload) => {
      state.creatingUmbrellaBrandError = payload;
      state.creatingUmbrellaBrand = false;
    },

    updateUmbrellaBrandStart: (state: State) => {
      state.updatingUmbrellaBrand = true;
    },
    updateUmbrellaBrandSuccess: (state: State, action) => {
      const updatedUmbrellaBrand = action.payload;
      state.data = state.data.map((e) => {
        if (e['id'] === updatedUmbrellaBrand['id']) {
          return updatedUmbrellaBrand;
        }
        return e;
      });
      state.updatingUmbrellaBrand = false;
    },
    updateUmbrellaBrandFailed: (state: State, payload) => {
      state.updatingUmbrellaBrandError = payload;
      state.updatingUmbrellaBrand = false;
    },

    deleteUmbrellaBrandStart: (state: State) => {
      state.deletingUmbrellaBrand = true;
    },
    deleteUmbrellaBrandSuccess: (state: State, umbrellaBrandId) => {
      state.data = state.data.filter((e) => e['id'] !== umbrellaBrandId.payload);
      state.deletingUmbrellaBrand = false;
    },
    deleteUmbrellaBrandFailed: (state: State, payload) => {
      state.deletingUmbrellaBrandError = payload;
      state.deletingUmbrellaBrand = false;
    },
  },
});

export default umbrellaBrands.reducer;

const {
  fetchUmbrellaBrandsStart,
  fetchUmbrellaBrandsSuccess,
  fetchUmbrellaBrandsFailed,
  createUmbrellaBrandStart,
  createUmbrellaBrandSuccess,
  createUmbrellaBrandFailed,
  updateUmbrellaBrandStart,
  updateUmbrellaBrandSuccess,
  updateUmbrellaBrandFailed,
  deleteUmbrellaBrandStart,
  deleteUmbrellaBrandSuccess,
  deleteUmbrellaBrandFailed,
} = umbrellaBrands.actions;

export const fetchUmbrellaBrands = () => async (dispatch: Function) => {
  try {
    dispatch(fetchUmbrellaBrandsStart());
    const api = new Api();
    const result = await api.getUmbrellaBrands();
    dispatch(fetchUmbrellaBrandsSuccess(result));
  } catch (err) {
    dispatch(fetchUmbrellaBrandsFailed(err));
  }
};

export const createUmbrellaBrand = (payload: any) => async (dispatch: Function) => {
  try {
    dispatch(createUmbrellaBrandStart());
    const api = new Api();
    await api.postCreateUmbrellaBrand(payload);
    dispatch(createUmbrellaBrandSuccess(payload));
  } catch (err) {
    dispatch(createUmbrellaBrandFailed(err));
  }
};

export const updateUmbrellaBrand = (brandId: string, payload: any) => async (dispatch: Function) => {
  try {
    dispatch(updateUmbrellaBrandStart());
    const api = new Api();
    const result = await api.postUpdateUmbrellaBrand(brandId, payload);
    dispatch(updateUmbrellaBrandSuccess(result));
  } catch (err) {
    dispatch(updateUmbrellaBrandFailed(err));
  }
};

export const deleteUmbrellaBrand = (umbrellaBrandId) => async (dispatch: Function) => {
  try {
    dispatch(deleteUmbrellaBrandStart());
    const api = new Api();
    await api.deleteUmbrellaBrand(umbrellaBrandId);
    dispatch(deleteUmbrellaBrandSuccess(umbrellaBrandId));
  } catch (err) {
    dispatch(deleteUmbrellaBrandFailed(err));
  }
};
