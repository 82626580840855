import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./StreamTableDetail.scss";
import { ArrowLeftIcon, ArrowUpIcon, CheckmarkIcon, FacebookIcon, FacebookThinIcon, HeaderTestIcon, HomeIcon, InstagramIcon, LinkIcon, LinkedinIcon, NewsletterIcon, TwitterIcon, YoutubeIcon } from "../../icons/Icons";

const StreamTableDetail = ({ timelineItem, storyBaseUrl }) => {
    const { t } = useTranslation();

    const getDetails = useCallback((item) => {
        const itemSubType = item['item_data'].notification_type;

        switch (itemSubType) {
            case 'abTestSinkingShip':
            case 'abTestEmptyTreasure':
            case 'abTestSecretTreasure':
                return {
                    type: 'path',
                    path: item['item_data'].url,
                    hovertext: "View page",
                    icon: <HeaderTestIcon color="#000" width="18px" height="18px" />,
                };
            case 'moveUpPromotePirate':
                return {
                    type: 'path',
                    path: item['item_data'].url,
                    hovertext: "View page",
                    icon: <ArrowUpIcon color="#000" width="18px" height="18px" />,
                };
            case 'toFrontTailwind':
                return {
                    type: 'path',
                    path: item['item_data'].url,
                    hovertext: "View page",
                    icon: <ArrowLeftIcon color="#000" width="18px" height="18px" />,
                };
            case 'headertest_completed':
                return {
                    type: 'archive',
                    hovertext: "View results",
                    icon: <CheckmarkIcon color="#000" width="18px" height="18px" />
                };
            case 'headertest_canceled':
                return {
                    type: 'archive',
                    hovertext: "View test",
                    icon: <CheckmarkIcon color="#000" width="18px" height="18px" />
                };
            default:
                return null;
        }
    }, []);

    const detail = getDetails(timelineItem);
    const channelIcons = {
        facebook: <FacebookThinIcon color="#000" width="18px" height="18px" />,
        twitter: <TwitterIcon color="#000" width="18px" height="18px" />,
        instagram: <InstagramIcon color="#000" width="18px" height="18px" />,
        home: <HomeIcon color="#000" width="18px" height="18px" />,
        link: <LinkIcon color="#000" width="18px" height="18px" />,
        newsletter: <NewsletterIcon color="#000" width="18px" height="18px" />,
        linkedin: <LinkedinIcon color="#000" width="18px" height="18px" />,
        youtube: <YoutubeIcon color="#000" width="18px" height="18px" /> 
    };
    const channel: any = timelineItem['item_data'] && timelineItem['item_data'].channel ? { label: timelineItem['item_data'].channel } : null;
    let postType: string|null = null;

    if (timelineItem['item_type'] && timelineItem['item_type'].indexOf('post') >= 0) {
        postType = timelineItem['item_type'];
    }

    // channel is facebook, twitter, newsletter, ...
    if (channel) {
        channel.postType = postType; // post, tweet, retweet, ...
        channel.href = timelineItem['item_data'] && timelineItem['item_data'].url;
        channel.name = timelineItem['item_data'] && timelineItem['item_data'].channelName;
        channel.icon = channelIcons[channel.label.toLowerCase()];
    }

    return (
        <div>
            {channel && (
                <span className="stream-table-detail">
                    <a target="_blank"
                        href={channel.href}
                        title={channel.name ? channel.name : channel.label }
                    >
                        {channel.name && (
                            <span className="stream-table-detail-label">{channel.name}</span>
                        )}
                        <span className="stream-table-detail-icon">
                            {channel.icon}
                        </span>
                    </a>
                </span>
            )}

            {detail && detail.type === 'path' && (
                <span className="stream-table-detail">
                    <a target="_blank"
                        href={storyBaseUrl + detail.path}
                        title={detail.hovertext}
                    >
                        <span className="stream-table-detail-label">
                            {detail.path === '/' ? "Homepage" : detail.path}
                        </span>
                      
                        <span className="stream-table-detail-icon">
                            {detail.icon}
                        </span>
                    </a>
                </span>
            )}

            {detail && !detail.path && (
                <span className="stream-table-detail">
                    <a href="/#/reports/tentacles/"
                        title={detail.hovertext}
                    >
                        <span className="stream-table-detail-icon">
                            {detail.icon}
                        </span>
                    </a>
                </span>
            )}
        </div>
    );
};

export default StreamTableDetail;
