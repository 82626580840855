import axios, { Method } from "axios";

interface IRequestConfig {
  headers?: Object;
  withCredentials?: boolean;
  params?: Object;
  data?: Object;
  signal?: any;
}

class ApiCore {
  env: string | undefined;
  baseUrl: string | undefined;
  // loginUrl: string;
  brandId: string;

  constructor() {
    this.env = process.env.NODE_ENV;
    this.baseUrl = process.env.REACT_APP_URL;

    const hostname = window.location.hostname;
    const splitIndex = hostname.search(/(\.|-)/);
    this.brandId = hostname.substring(0, splitIndex);
    // this.loginUrl = (ensureType(process.env.REACT_APP_LOGIN_URL_PATTERN)).replace('brandId', this.brandId);
  }

  setBaseUrl(url: string) {
    this.baseUrl = url;
  }

  getBrandId() {
    return this.brandId;
  }

  sendRequest(method: Method, url: string, config: IRequestConfig) {
    if (!config.headers) {
      config.headers = {};
    }

    // add X-Requested-With for ajax calls
    // if (config.headers["X-Requested-With"] === undefined) {
    //     config.headers["X-Requested-With"] = "XMLHttpRequest";
    // }

    // debug output
    // axios.interceptors.request.use(request => {
    //     return request;
    // });
    config.withCredentials = true;

    // @ts-ignore
    return axios({
      method,
      url,
      baseURL: this.baseUrl,
      ...config,
    })
      .then((response) => response.data) // return data
      .then((responseData) => {
        if (!responseData) {
          // check success field
          // Api sends error like:
          // {"errorInfo":{"success":0,"data":{"errorCode":400,"message":""},"message":"","paging":null}}

          if (this.env === "development") {
            console.warn("ApiCore encountered an unexpected response", responseData);
          }

          const errMsg = (responseData.data && responseData.data.message) || "API call was unsuccessful";
          const errCode = responseData.data && responseData.data.errorCode;

          // throw new ApiError(errMsg, errCode, responseData.data);
        }
        return responseData; // return the data
      });
  }

  /**
   * GET request
   * @param   url     string
   * @param   payload Object
   * @returns {Promise<any>}
   */
  get(url: string, payload = {}, signal = null) {
    return this.sendRequest("get", url, {
      params: { ...payload },
      signal: signal ? signal : undefined,
    });
  }

  /**
   * POST request
   * @param url
   * @param payload
   * @returns {Promise<any>}
   */
  post(url: string, payload: Object) {
    return this.sendRequest("post", url, {
      // headers: { Accept: "*", "Content-Type": "application/json" },
      // headers: { "content-type": "application/x-www-form-urlencoded" },
      // data: qs.stringify(payload),
      data: payload,
    });
  }

  /**
   * DELETE request
   * @param url
   * @param payload
   * @returns {Promise}
   */
  delete(url: string, payload?: Object) {
    return this.sendRequest("delete", url, {
      data: payload,
    });
  }
}

export default ApiCore;
