import React from "react";
import "./ProfilePhoto.scss";

type ProfilePhotoProps = { imgSrc: string; updatePhoto: Function };

const ProfilePhoto = ({ imgSrc, updatePhoto }: ProfilePhotoProps) => {
  const handleFileUpdate = (e) => {
    // @ts-ignore
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      updatePhoto(reader.result);
    });

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="profile-photo-container">
      <h3>Profile photo</h3>
      <img style={{ color: "white" }} src={imgSrc} alt="profile-avatar" />
      <input type="file" id="profile-photo" onChange={handleFileUpdate} />
      <label htmlFor="profile-photo">Select new photo</label>
    </div>
  );
};

export default ProfilePhoto;
