import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import { fetchHeadlineSuggestions, fetchTiming, resetSmartoctoAIState } from "../../store/slices/smartoctoAI";
import { DateTime } from "luxon";
import useTimeTimezoneAdjust from "../../customHooks/useTimeTimezoneAdjust";
import TextArea from "../../components/ui/common/TextArea";
import ToggleSwitch from "../../components/ui/toggleSwitch/ToggleSwitch";
import Button from "../../components/ui/common/Button";
import MainNavigation from "../../components/navigation/MainNavigation";
import { CircleLoader } from "../../icons/Loaders";
import "./RPORecommender.scss";

const RPORecommender = () => {
  const dispatch = useAppDispatch();
  const timeTimezoneAdjust = useTimeTimezoneAdjust();
  const { isLoading, data } = useSelector((state: any) => state.smartoctoAI);
  // const isLoading = true;
  const [headlineInput, setHeadlineInput] = useState("");
  const [articleInput, setArticleInput] = useState("");
  const [containsImages, setContainsImages] = useState(false);
  const [containsVideo, setContainsVideo] = useState(false);

  const handleHeadlineInputChange = (e) => {
    setHeadlineInput(e.target.value);
  };

  const handleArticleInput = (e) => {
    setArticleInput(e.target.value);
  };

  const suggestHeadline = () => {
    const textCombined = headlineInput + "||" + articleInput;
    dispatch(fetchHeadlineSuggestions(textCombined, "rpo", ""));
  };

  const suggestTiming = () => {
    const textCombined = headlineInput + "||" + articleInput;
    dispatch(fetchTiming(textCombined, "rpo", containsImages, containsVideo));
  };

  useEffect(() => {
    return () => {
      dispatch(resetSmartoctoAIState());
    };
  }, [resetSmartoctoAIState]);

  return (
    <>
      <MainNavigation />
      <div className="recommender-page-wrapper page-container-no-side-nav">
        {isLoading && (
          <div className="recommender-loading-overlay">
            <CircleLoader />
          </div>
        )}
        <h1 className="recommender-page-title">RPO Draft article Recommender</h1>
        <p className="recommender-page-subtitle">
          Based on smartocto AI - click <a>here</a> for more info
        </p>
        <div className="recommender-container">
          <div className="recommender-form">
            <TextArea
              value={headlineInput}
              onChange={(e) => handleHeadlineInputChange(e)}
              className="headline-input"
              placeholder="Put in headline here"
            ></TextArea>
            <TextArea
              value={articleInput}
              onChange={(e) => handleArticleInput(e)}
              className="article-input"
              placeholder="paste your draft article in here, let the system know if the draft article contains videos or images and press the output buttons on the side..."
            ></TextArea>

            <h2>Suggestions for headlines</h2>
            <div className="headline-output">
              {data?.headliner?.alternatives && (
                <div>
                  <p>
                    <b>Headline 01: </b> {data?.headliner?.alternatives?.alternative01}
                  </p>
                  <p>
                    <b>Headline 02: </b> {data?.headliner?.alternatives?.alternative02}
                  </p>
                  <p>
                    <b>Headline 02: </b> {data?.headliner?.alternatives?.alternative02}
                  </p>
                </div>
              )}
            </div>
            <h2>Suggestions for timing</h2>
            <div className="timing-output">
              {data?.timing?.response && (
                <div>
                  <p>
                    According to the smartocto AI, these are the 3 optimal time slots to distribute this story, based on
                    your input, and get the attention of the right audience:
                  </p>
                  <p>
                    <b>Time slot 1:</b> {data?.timing?.response[0].next_24_hours ? null : "Any"}{" "}
                    {DateTime.fromJSDate(timeTimezoneAdjust(data?.timing?.response[0].time || "")).toFormat(
                      "cccc, HH:mm",
                    )}
                  </p>
                  <p>
                    <b>Time slot 2:</b> {data?.timing?.response[1].next_24_hours ? null : "Any"}{" "}
                    {DateTime.fromJSDate(timeTimezoneAdjust(data?.timing?.response[1].time || "")).toFormat(
                      "cccc, HH:mm",
                    )}
                  </p>
                  <p>
                    <b>Time slot 3:</b> {data?.timing?.response[2].next_24_hours ? null : "Any"}{" "}
                    {DateTime.fromJSDate(timeTimezoneAdjust(data?.timing?.response[2].time || "")).toFormat(
                      "cccc, HH:mm",
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="recommender-control">
            <div>
              <p className="switch-title">Story contains image(s)</p>
              no <ToggleSwitch checked={containsImages} onChange={setContainsImages} /> yes
            </div>
            <div>
              <p className="switch-title">Story contains video</p>
              no <ToggleSwitch checked={containsVideo} onChange={setContainsVideo} /> yes
            </div>

            <div className="output-actions-container">
              <p className="actions-title">Output:</p>
              <Button
                variant="success"
                onClick={() => {
                  suggestHeadline();
                }}
              >
                {" "}
                Suggest headlines{" "}
              </Button>
              <Button variant="success" onClick={suggestTiming}>
                {" "}
                Calculate best timing{" "}
              </Button>
            </div>
          </div>
        </div>

        <div className="info">
          <h2>Info</h2>
          <p>
            This is a beta smartocto.ai product and can be replaced by similar features. The system is trained on your
            published articles.
          </p>
        </div>
      </div>
    </>
  );
};

export default RPORecommender;
