import React from "react";
import BarChart from "./BarChart";
import BarChartLegend from "./BarChartLegend";
import "./BarChartContainer.scss";

const BarChartContainer = ({ data }: { data: any }) => {
  return (
    <div className="bar-chart-container">
      <BarChart data={data} height={250} mode="default" />
      <BarChartLegend data={data} />
    </div>
  );
};

export default BarChartContainer;
