import React, { useMemo } from "react";

type TimeAgoShortProps = {
  timestamp: number;
};

const TimeAgoShort = ({ timestamp }: TimeAgoShortProps) => {
  const calculatedTimePassed = useMemo(() => {
    if (!timestamp) return "";
    const now = new Date().valueOf();
    const timeDifference = now - timestamp;
    const secondsDifference = timeDifference / 1000;
    const minutesDifference = secondsDifference / 60;
    const hoursDifference = minutesDifference / 60;
    const daysDifference = hoursDifference / 24;
    if (daysDifference >= 1) {
      return Math.floor(daysDifference) + "d";
    } else if (hoursDifference >= 1) {
      return Math.floor(hoursDifference) + "h";
    } else {
      return Math.floor(minutesDifference) + "m";
    }
  }, [timestamp]);

  return <>{calculatedTimePassed}</>;
};

export default TimeAgoShort;
