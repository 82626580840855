import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<any>;
  isLoading: boolean;
  error: Object | null;

  importTypes: Array<Object>;
  importTypesLoading: boolean;
  importTypesError: Object | null;

  updatingImport: boolean;
  updatingImportError: Object | null;
  creatingImport: boolean;
  deletingImport: boolean;
  deletingImportError: Object | null;
};

const INITIAL_STATE = {
  data: [],
  isLoading: true,
  error: null,

  importTypes: [],
  importTypesLoading: true,
  importTypesError: null,

  updatingImport: false,
  updatingImportError: null,
  creatingImport: false,
  deletingImport: false,
  deletingImportError: null,
};

const imports = createSlice({
  name: "imports",
  initialState: INITIAL_STATE,
  reducers: {
    fetchImportsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchImportsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchImportsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchImportTypesStart: (state: State) => {
      state.importTypesLoading = true;
    },
    fetchImportTypesSuccess: (state: State, action) => {
      state.importTypesLoading = false;
      state.importTypes = action.payload;
    },
    fetchImportTypesFailed: (state: State, action) => {
      state.importTypesLoading = false;
      state.importTypesError = action.payload;
    },

    updateImportStart: (state: State) => {
      state.updatingImport = true;
    },
    updateImportSuccess: (state: State, action) => {
      const updatedImport = action.payload
      state.data = state.data.map((importItem) => {
        // Check if the brand is the one being updated
        if (importItem.hasOwnProperty('id') && importItem.id === updatedImport.id) {
          return updatedImport;
        } else {
          return importItem;
        } 
      } 
      );
      state.updatingImport = false;
    },
    updateImportFailed: (state: State, action) => {
      state.updatingImport = false;
    },

    createImportStart: (state: State) => {
      state.creatingImport = true;
    },
    createImportSuccess: (state: State, action) => {
      state.creatingImport = false;
      state.data = action.payload;
    },
    createImportFailed: (state: State, action) => {
      state.creatingImport = false;
    },

    deleteImportStart: (state: State) => {
      state.deletingImport = true;
    },
    deleteImportSuccess: (state: State, action) => {
      state.deletingImport = false;
      state.data = action.payload;
    },
    deleteImportFailed: (state: State, action) => {
      state.deletingImport = false;
    },

    resetImportUpdateState: (state: State) => {
      state.updatingImport = false;
      state.updatingImportError = null;
    },
  },
});

export default imports.reducer;

const {
  fetchImportsStart,
  fetchImportsSuccess,
  fetchImportsFailed,

  fetchImportTypesStart,
  fetchImportTypesSuccess,
  fetchImportTypesFailed,

  updateImportStart,
  updateImportSuccess,
  updateImportFailed,

  createImportStart,
  createImportSuccess,
  createImportFailed,

  deleteImportStart,
  deleteImportSuccess,
  deleteImportFailed,

  resetImportUpdateState,
} = imports.actions;

export const fetchImports = () => async (dispatch: Function) => {
  try {
    dispatch(fetchImportsStart());
    const api = new Api();
    const result = await api.getImports();
    dispatch(fetchImportsSuccess(result));
  } catch (err) {
    dispatch(fetchImportsFailed(err));
  }
};

export const fetchImportTypes = () => async (dispatch: Function) => {
  try {
    dispatch(fetchImportTypesStart());
    const api = new Api();
    const result = await api.getImportTypes();
    dispatch(fetchImportTypesSuccess(result));
  } catch (err) {
    dispatch(fetchImportTypesFailed(err));
  }
};

export const updateImport = (importId: string, payload: any) => async (dispatch: Function) => {
  try {
    dispatch(updateImportStart());
    const api = new Api();
    const result = await api.postUpdateImport(importId, payload);
    dispatch(updateImportSuccess(result));
  } catch (err) {
    dispatch(updateImportFailed(err));
  }
};

export const createImport = (payload: any) => async (dispatch: Function) => {
  try {
    dispatch(createImportStart());
    const api = new Api();
    const result = await api.postCreateImport(payload);
    dispatch(createImportSuccess(result));
  } catch (err) {
    dispatch(createImportFailed(err));
  }
};

export const deleteImport = (importId: string) => async (dispatch: Function) => {
  try {
    dispatch(deleteImportStart());
    const api = new Api();
    const result = await api.deleteImport(importId);
    dispatch(deleteImportSuccess(result));
  } catch (err) {
    dispatch(deleteImportFailed(err));
  }
};

export const resetImportStates = () => async (dispatch: Function) => {
  dispatch(resetImportUpdateState());
}
