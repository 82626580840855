import React, { Component } from "react";
import * as d3 from "d3";

class BarChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data || [],
      height: props.height || 250,
      mode: props.mode || "default",
      width: props.data.length * 32,
    };

    this.tag = new Date().getTime();
  }

  componentDidMount() {
    this.initChart();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ data: this.props.data, width: this.props.data.length * 32 }, () => {
        this.initChart();
      });
    }
  }

  initChart() {
    
    const isAllDataZero = () => {
      let allZero = true;
      this.props.data.forEach(e => {
        if (e.value !== 0){ allZero = false };
      })
      return allZero;
    }

    const dataHeight = this.state.height - 70;
    const barWidth = 26;
    const barGutter = 5;

    // Create a D3 scale for the y-axis
    const yScale = d3.scaleLinear().range([dataHeight, 0]);

    yScale.domain([0, d3.max(this.state.data, (d) => d.value)]);

    // Select the SVG container
    const svg = d3.select(this.svgRef);
    svg.selectAll("*").remove();

    const containers = svg.append("g").selectAll().data(this.state.data);

    const xPosition = (d, i) => i * (barWidth + barGutter);
    const yPosition = (d) => dataHeight - (dataHeight - yScale(d.value)) + 25;

    const textxPosition = (d, i) => i * (barWidth + barGutter) + 12;
    const textyPosition = (d) => dataHeight - (dataHeight - yScale(d.value)) + 15;

    containers
      .join("rect")
      .attr("x", xPosition) // Set the horizontal position
      .attr("y", (d) => {
        if(isAllDataZero()) { return dataHeight + 25}
        else return yPosition(d)
      })
      .attr("rx", 13)
      .attr("width", barWidth)
      .attr("height", (d) => { 
        if (isAllDataZero()) return 25;
        return barWidth + dataHeight - yScale(d.value)
      })
      .attr("fill", (d) => d.color);

    containers
      .join("text")
      .attr("x", textxPosition) // Set the horizontal position
      .attr("y", (d)=>{
        if (isAllDataZero()) {return dataHeight + 15}
        return textyPosition(d);
      })
      .attr("fill", "#000")
      .attr("font-size", 14)

      .attr("text-anchor", "middle")
      .text(function (d) {
        return d.displayedValue ? d.displayedValue : d.value;
      });
  }

  updateChart() {
    const dataHeight = this.state.height - 70;
    const barWidth = 26;
    const barGutter = 5;

    const isAllDataZero = () => {
      let allZero = false;
      this.props.data.forEach(e => {
        if (e.value !== 0) allZero = true;
      })
      return allZero;
    }

    // Create a D3 scale for the y-axis
    const yScale = d3.scaleLinear().range([dataHeight, 0]);

    yScale.domain([0, d3.max(this.state.data, (d) => d.value)]);

    // Select the SVG container
    const svg = d3.select(this.svgRef);

    const containers = svg.append("g").selectAll().data(this.state.data);

    const xPosition = (d, i) => i * (barWidth + barGutter);
    const yPosition = (d) => dataHeight - (dataHeight - yScale(d.value)) + 25;

    const textxPosition = (d, i) => i * (barWidth + barGutter) + 12;
    const textyPosition = (d) => dataHeight - (dataHeight - yScale(d.value)) + 15;

    containers
      .join("rect")
      .attr("x", xPosition) // Set the horizontal position
      .attr("y", () => {
        if(isAllDataZero()) { return dataHeight + 25}
        else return yPosition
      })
      .attr("rx", 13)
      .attr("width", barWidth)
      .attr("height", (d) => { 
        if (isAllDataZero) return 30;
        return barWidth + dataHeight - yScale(d.value)
      })
      .attr("fill", (d) => d.color);

    containers
      .join("text")
      .attr("x", textxPosition) // Set the horizontal position
      .attr("y", textyPosition)
      .attr("fill", "#000")
      .attr("font-size", 14)

      .attr("text-anchor", "middle")
      .text(function (d) {
        return d.displayedValue ? d.displayedValue : d.value;
      });
  }

  render() {
    return (
      <svg
        ref={(ref) => (this.svgRef = ref)}
        height={this.state.height}
        width={this.state.width}
        className="c-bar-chart"
      ></svg>
    );
  }
}

export default BarChart;
