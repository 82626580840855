import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Select from "../../ui/select/Select";
import Button from "../../../components/ui/common/Button";

import { copyImports, copyNotifications } from "../../../store/slices/configurations";
import { useAppDispatch } from "../../../store";

type CopyConfigurationProps = {
  showCopyConfiguration: boolean;
  handleToggleCopyConfiguration: Function;
  allBrandIds: any;
};

const CopyConfiguration = ({
  showCopyConfiguration,
  handleToggleCopyConfiguration,
  allBrandIds,
}: CopyConfigurationProps) => {
  const [brandIdNotifications, setBrandIdNotifications] = useState(" ");
  const [brandIdImports, setBrandIdImports] = useState(" ");

  const [copyNotificationsButtonText, setCopyNotificationsButtonText] = useState("Start copy");
  const [copyImportsButtonText, setCopyImportsButtonText] = useState("Start copy");

  const isCopyingNotifications = useSelector((state: any) => state.configurations.copyingNotifications);
  const copyingNotificationsError = useSelector((state: any) => state.configurations.copyingNotificationsError);

  const isCopyingImports = useSelector((state: any) => state.configurations.copyingNotifications);
  const copyingImportsError = useSelector((state: any) => state.configurations.copyingImportsError);

  const dispatch = useAppDispatch();

  const copyNotificationsOfSelectedBrand = useCallback(async () => {
    if (brandIdNotifications !== " ") {
      setCopyNotificationsButtonText("Copying...");
      try {
        await dispatch(copyNotifications(brandIdNotifications));
        if (!copyingNotificationsError) {
          setCopyNotificationsButtonText("Done!");
        } else {
          setCopyNotificationsButtonText("Failed!");
        }
      } catch (err) {
        setCopyNotificationsButtonText("Failed!");
      }
    }
  }, [brandIdNotifications, dispatch, copyingNotificationsError]);

  const copyImportsOfSelectedBrand = useCallback(async () => {
    if (brandIdImports !== " ") {
      setCopyImportsButtonText("Copying...");
      try {
        await dispatch(copyImports(brandIdImports));
        if (!copyingImportsError) {
          setCopyImportsButtonText("Done!");
        } else {
          setCopyImportsButtonText("Failed!");
        }
      } catch (err) {
        setCopyImportsButtonText("Failed!");
      }
    }
  }, [brandIdImports]);

  return (
    <div>
      <div
        className="brand-settings-title first"
        onClick={() => {
          handleToggleCopyConfiguration();
        }}
      >
        Copy configurations
      </div>

      <div className={["brand-settings-container", "copy-configuration", showCopyConfiguration && "active"].join(" ")}>
        <div className="copy-configuration-wrapper">
          <p>Copy notification algorithms from brand</p>
          <div className="drop-select">
            <Select
              fullWidth
              options={allBrandIds}
              selectedOption={brandIdNotifications}
              setSelectedOption={(e) => {
                setBrandIdNotifications(e);
                setCopyNotificationsButtonText("Start copy");
              }}
              styling={{
                selectOptionsContainerClassName: "brand-options-dropdown",
              }}
            />
            <Button
              variant="success"
              onClick={() => {
                copyNotificationsOfSelectedBrand();
              }}
              disabled={brandIdNotifications === " " || isCopyingNotifications}
            >
              {copyNotificationsButtonText}
            </Button>
          </div>
          <p>Copy import configurations from brand</p>
          <div className="drop-select">
            <Select
              fullWidth
              options={allBrandIds}
              selectedOption={brandIdImports}
              setSelectedOption={(e) => {
                setBrandIdImports(e);
                setCopyImportsButtonText("Start copy");
              }}
              styling={{
                selectOptionsContainerClassName: "brand-options-dropdown",
              }}
            />
            <Button
              variant="success"
              onClick={() => {
                copyImportsOfSelectedBrand();
              }}
              disabled={brandIdImports === " " || isCopyingImports}
            >
              {copyImportsButtonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyConfiguration;
//select-options undefined options-displayed undefined
// select-options undefined options-displayed types-options-dropdown
