import React from "react";
import "./StackedAreaGraphLegend.scss";

type LegendItem = {
  label: string;
  id: string;
  color: string;
  deactivated: boolean;
};

type StackedAreaGraphLegendProps = {
  data: Array<LegendItem>;
  itemClick: Function;
};

const StackedAreaGraphLegend = ({ data, itemClick }: StackedAreaGraphLegendProps) => {
  return (
    <div className="stacked-area-graph-legend-container">
      {data.map((item: LegendItem) => {
        return (
          <div
            className="legend-item"
            key={item.id}
            onClick={() => {
              itemClick(item.id);
            }}
          >
            <div
              className="legend-icon"
              style={{
                backgroundColor: item.deactivated ? "white" : item.color,
                border: `1px solid ${item.color}`,
              }}
            ></div>
            <div
              className="legend-label"
              style={{
                color: item.deactivated ? "gray" : "black",
              }}
            >
              {item.label}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StackedAreaGraphLegend;
