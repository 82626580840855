import React from "react";
import { useSelector } from "react-redux";
import StoryReach from "./StoryReach";
import Numbers from "./Numbers";
import { CircleLoader } from "../../icons/Loaders";

import "./Story.scss";

const PagesStory = () => {
  const loading = useSelector((state: any) => state.activeFeedItem.isLoading);
  const activeItem = useSelector((state: any) => state.activeFeedItem.feedItem);

  return (
    <div className="story-container">
      <header>
        {loading ? (
          <div className="title-loader-container">
            <CircleLoader />
          </div>
        ) : (
          <>
            <h1>{activeItem?.post?.title}</h1>
          </>
        )}
      </header>
      <main>
        <StoryReach totalsData={activeItem?.totals} storyLoading={loading} />
        <Numbers loading={loading} hideFacebookNumbers />
      </main>
    </div>
  );
};

export default PagesStory;
