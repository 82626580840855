import React, { Component } from "react";
import * as d3 from "d3";
import "./Graph.scss";

class HexagonGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data || [],
      width: 200,
      height: 230,
      ratio: 1.0,
      expectation: 0,
      performance: 0,
    };
  }

  componentDidMount() {
    this.initChart();
  }

  // componentDidUpdate() {
  //   this.initChart();
  // }

  initChart() {
    if (this.props.data) {
      let kpi = this.props.data;
      var performance = kpi.value;
      var expectation = kpi.story_expectation ? kpi.story_expectation : kpi.expectation;
      this.setState({
        performance: performance,
        expectation: expectation,
      });
      // this.root.classList.add(performance > expectation ? "v-positive" : "v-negative");
    }

    // // Remove possibly existing display class
    // this.root.classList.remove(performance > expectation ? 'v-negative' : 'v-positive');

    let ratio = Math.min(performance, expectation) / Math.max(performance, expectation);

    this.setState({
      ratio: ratio,
    });

    const svg = d3.select(this.svgRef);
    svg.selectAll("*").remove();
    const container = svg.append("g");
    const fullCircle = container.append("path").attr("class", "full-hex");
    const partCircle = container.append("path").attr("class", "part-hex");

    const drawHexagon = (d) => {
      return d3
        .line()
        .x(function (d) {
          return d.x;
        })
        .y(function (d) {
          return d.y;
        })
        .curve(d3.curveCardinalClosed.tension(1))(d);
    };

    const getHexagonPath = (xp, yp, radius) => {
      var h = Math.sqrt(3) / 2;

      return [
        { x: radius + xp, y: yp },
        { x: radius / 2 + xp, y: radius * h + yp },
        { x: -radius / 2 + xp, y: radius * h + yp },
        { x: -radius + xp, y: yp },
        { x: -radius / 2 + xp, y: -radius * h + yp },
        { x: radius / 2 + xp, y: -radius * h + yp },
      ];
    };

    const largeRadius = Math.min(this.state.width, this.state.height) / 2;
    // const smallRadius = Number.isNaN(this.state.ratio) ? 0 : Math.max(2, largeRadius * this.state.ratio);
    const smallRadius = Number.isNaN(ratio) ? 0 : Math.max(2, largeRadius * ratio);

    fullCircle.attr("d", drawHexagon(getHexagonPath(0, 0, largeRadius)));
    partCircle.attr("d", drawHexagon(getHexagonPath(0, 0, smallRadius)));
    fullCircle.attr("transform", "translate(" + this.state.width / 2 + "," + this.state.height / 2 + ")");
    partCircle.attr("transform", "translate(" + this.state.width / 2 + "," + this.state.height / 2 + ")");
  }

  render() {
    return (
      <svg
        ref={(ref) => (this.svgRef = ref)}
        height={this.state.height}
        className={`hexagon-graph ${this.state.performance > this.state.expectation ? "positive" : "negative"}`}
      ></svg>
    );
  }
}

export default HexagonGraph;
