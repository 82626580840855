import React from "react";
import "./Pill.scss";

interface PillProps {
    color?: string,
    children: any
}

const Pill = ({
    color, children
}: PillProps) => {
    return (
        <div className="so-pill"
            style={{backgroundColor: color}}>{children}</div>
    )
}


export default Pill;