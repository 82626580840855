import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  copyingNotificationsError: Object | null;
  copyingImportsError: Object | null;

  copyingNotifications: boolean;
  copyingImports: boolean;
};

const INITIAL_STATE = {
  copyingNotificationsError: null,
  copyingImportsError: null,

  copyingNotifications: false,
  copyingImports: false,
};

const configurations = createSlice({
  name: "configurations",
  initialState: INITIAL_STATE,
  reducers: {
    copyNotificationsStart: (state: State) => {
      state.copyingNotifications = true;
    },
    copyNotificationsSuccess: (state) => {
      state.copyingNotifications = false;
    },
    copyNotificationsFailed: (state: State, action) => {
      state.copyingNotifications = false;
      state.copyingNotificationsError = action.payload;
    },
    copyImportsStart: (state: State) => {
      state.copyingImports = true;
    },
    copyImportsSuccess: (state) => {
      state.copyingImports = false;
    },
    copyImportsFailed: (state: State, action) => {
      state.copyingImports = false;
      state.copyingImportsError = action.payload;
    }
  },
});

export default configurations.reducer;

const {
  copyNotificationsStart,
  copyNotificationsSuccess,
  copyNotificationsFailed,
  copyImportsStart,
  copyImportsSuccess,
  copyImportsFailed
} = configurations.actions;

export const copyNotifications = (brandId: string) => async (dispatch: Function) => {
  try {
    dispatch(copyNotificationsStart());
    const api = new Api();
    await api.postCreateBasicNotifiers(brandId)

    dispatch(copyNotificationsSuccess());
  } catch (err) {
    console.error(err);
    dispatch(copyNotificationsFailed(err));
  }
}

export const copyImports = (brandId: string) => async (dispatch: Function) => {
  try {
    dispatch(copyImportsStart());
    const api = new Api();
    await api.postCreateBasicImports(brandId)

    dispatch(copyImportsSuccess());
  } catch (err) {
    dispatch(copyImportsFailed(err))
  }
}
