import React from "react";

function Haha() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="40"
      height="40"
      viewBox="0 0 148 134"
    >
      <defs>
        <path
          id="path-1"
          d="M0 0c0 20.435 16.565 37 37 37S74 20.435 74 0"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(17 10)">
            <circle cx="57" cy="57" r="57" fill="#FFDA69"></circle>
            <g transform="translate(20 56)">
              <mask id="mask-2" fill="#fff">
                <use xlinkHref="#path-1"></use>
              </mask>
              <use fill="#252B39" xlinkHref="#path-1"></use>
              <path
                fill="#F55065"
                d="M0 46s9 7 35.955 7S73 46 73 46L35.955 12 0 46z"
                mask="url(#mask-2)"
                transform="rotate(-180 36.5 32.5)"
              ></path>
            </g>
            <path
              fill="#252B39"
              d="M38.258 34.669c1.33.8 2.573 3.215-.059 5.472l-13.416 8.713a3 3 0 11-3.268-5.032l9.769-6.344-9.828-5.906a3 3 0 113.09-5.143l13.712 8.24zm37.23 0l13.712-8.24a3 3 0 113.09 5.143l-9.828 5.906 9.77 6.344a3 3 0 11-3.269 5.032l-13.416-8.713c-2.632-2.257-1.39-4.673-.06-5.472z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Haha;
