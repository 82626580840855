import React from "react";
import { Link } from "react-router-dom";
import "./AdminCard.scss";

type Props = {
  linkPath: string;
  absolutePath?: boolean;
  title: string;
  description: string;
  illustration?: string;
  comingSoon?: boolean;
  disabled?: boolean;
};

const AdminCard = (props: Props) => {
  if (props.disabled) {
    return (
      <a className={`admin-card-container ${props.disabled && "disabled"} ${props.illustration}`}>
        <div className="disabled-overlay"></div>
        <div>
          <h2>{props.title}</h2>
          <p>{props.description}</p>
        </div>
      </a>
    );
  }
  if (props.comingSoon) {
    return (
      <a className={`admin-card-container coming-soon ${props.illustration}`}>
        <div className="disabled-overlay"></div>
        <div>
          <h2>{props.title}</h2>
          <p>{props.description}</p>
        </div>
      </a>
    );
  }
  if (props.absolutePath) {
    return (
      <a href={`${props.linkPath}`} target="_blank" className={`admin-card-container ${props.illustration}`}>
        <div>
          <h2>{props.title} &gt; </h2>
          <p>{props.description}</p>
        </div>
      </a>
    );
  }
  return (
    <Link to={`${props.linkPath}`} className={`admin-card-container ${props.illustration}`}>
      <div>
        <h2>{props.title} &gt; </h2>
        <p>{props.description}</p>
      </div>
    </Link>
  );
};

export default AdminCard;
