import React from "react";

function Love() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 148 134"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(17 10)">
            <circle cx="57" cy="57" r="57" fill="#F55065"></circle>
            <path
              fill="#FFF"
              d="M23.49 50.189A18.049 18.049 0 0123 46c0-9.941 8.059-18 18-18 6.966 0 13.008 3.957 16 9.746C59.992 31.956 66.034 28 73 28c9.941 0 18 8.059 18 18 0 1.296-.137 2.56-.397 3.777-.02.236-.055.477-.103.723-3.5 18-33 38.5-33 38.5s-33-21.5-34-38.5a5.815 5.815 0 01-.01-.311z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Love;