import React from "react";

function Angry() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      version="1.1"
      viewBox="0 0 148 134"
    >
      <defs>
        <linearGradient
          id="linearGradient-1"
          x1="50%"
          x2="50%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#F65167"></stop>
          <stop offset="100%" stopColor="#FFDA69"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <g transform="translate(17 10)">
            <circle
              cx="57"
              cy="57"
              r="57"
              fill="url(#linearGradient-1)"
            ></circle>
            <path
              fill="#252B39"
              d="M56.5 95.5c10.217 0 18.5-1.118 18.5-3.912C75 90.471 66.717 87 56.5 87S38 91.03 38 91.588c0 2.16 8.283 3.912 18.5 3.912z"
            ></path>
            <path
              fill="#252B39"
              d="M77.124 69.834a6 6 0 109.61-1.584c4.338-1.143 8.603-2.91 13.408-5.56a2.5 2.5 0 00-2.416-4.378c-10.27 5.666-17.749 6.919-30.024 6.791-1.212-.012-1.62-.015-2.202-.015a2.5 2.5 0 000 5c.558 0 .957.003 2.15.015 3.445.036 6.556-.034 9.474-.27zm-49.972-1.468a6 6 0 109.746 1.513c2.748.199 5.672.258 8.886.224 1.193-.012 1.593-.015 2.15-.015a2.5 2.5 0 100-5c-.582 0-.99.003-2.202.015-12.275.128-19.753-1.125-30.024-6.791a2.5 2.5 0 10-2.415 4.377c4.971 2.743 9.364 4.54 13.86 5.677z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Angry;
