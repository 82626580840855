import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { DateTime } from "luxon";

const useTimeTimezoneAdjust = () => {
  const currentBrand = useSelector((state: any) => state.currentBrand.data);

  const brandTimezone = useMemo(() => {
    return currentBrand.domainSettings.timezone;
  }, [currentBrand]);

  const adjustTimezone = useCallback(
    (time: string) => {
      const utcTime = DateTime.fromFormat(time, "cccc, HH:mm", { zone: "utc" });
      const targetTimeInTimezone = utcTime.setZone(brandTimezone);
      const adjustedDate = new Date(targetTimeInTimezone.toFormat("yyyy-MM-dd HH:mm"));
      return adjustedDate;
    },
    [brandTimezone],
  );

  return adjustTimezone;
};

export default useTimeTimezoneAdjust;
