import React, { useMemo, useState } from "react";
import { InkButton } from "../../icons/Icons";
import { saveNotificationAsInked } from "../../store/slices/inked";
import { useAppDispatch } from "../../store";
import { updateTimelineItems } from "../../store/slices/activeFeedItem";

import "./StreamTableInk.scss";

const StreamTableInk = ({ timelineItem }) => {
    const dispatch = useAppDispatch();
    const [otherInkers, setOtherInkers] = useState(false);
    const [inkers, setInkers] = useState([]);

    const statusText = useMemo(() => {
        let text: string|TrustedHTML = "";

        // TODO: handle translations based on inks count 
        if (timelineItem.inked && timelineItem.inks && timelineItem.inks.length > 1) {
            text = timelineItem.inks.length === 2 ? "inked by <strong>you</strong> and <strong>one other</strong>" : `inked by <strong>you</strong> and <strong>${timelineItem.inks.length - 1} others</strong>`;
            setOtherInkers(true);
        } else if (timelineItem.inked) {
            text = "inked by <strong>you</strong>";
            setOtherInkers(false);
        } else if (timelineItem.inks === 0 || timelineItem.inks === null || timelineItem.inks === undefined || timelineItem.inks.length === 0) {
            text = ''; // By nobody
            setOtherInkers(false);
        } else {
            text = timelineItem.inks.length === 1 ? "inked by <strong>one other</strong>" : `inked by <strong>${timelineItem.inks.length} others</strong>`;
            setOtherInkers(true);
        }

        if (timelineItem.inks) {
            setInkers(timelineItem.inks.map(function (ink) {
                return ink['name_first'] + ' ' + ink['name_last'];
            }));
        } else {
            setInkers([]);
        }

        return text;
    }, [timelineItem]);

    const handleToggleInk = (itemId) => {
        dispatch(saveNotificationAsInked(itemId || "", timelineItem.inked ? "delete" : "create", updateTimelineItems));
    };

    return (
        <div className="stream-table-ink-container">
            <div className="stream-table-ink-status">
                <span className="stream-table-ink-status-label" dangerouslySetInnerHTML={{ __html: statusText }}></span>

                {otherInkers && (
                    <ul className="stream-table-ink-inkers">
                        {inkers.map((inker, index) => (
                            <li key={index}>{inker}</li>
                        ))}
                    </ul>
                )}
            </div>

            <div className={"stream-table-ink " + (timelineItem.inked ? 'is-inked' : '')} role="button" onClick={() => { handleToggleInk(timelineItem.id) }}>
                <InkButton width="15px" height="15px" />
            </div>
        </div>
    );
};

export default StreamTableInk;
