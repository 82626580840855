import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";
import { clearUserData } from "./currentUser";
import { clearCurrentBrandInfo } from "./currentBrand";

type State = {
  loading: boolean;
  token: string;
  error: object | null;
};

const INITIAL_STATE = { loading: false, token: null, error: null };

const auth = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    loginStart: () => ({
      loading: true,
      token: null,
      error: null,
    }),
    loginSuccess: (_, action) => ({
      loading: false,
      token: action.payload,
      error: null,
    }),
    loginFail: (_, action) => ({
      loading: false,
      token: null,
      error: action.payload,
    }),
    clearError: () => ({
      loading: false,
      token: null,
      error: null,
    }),
    logout: () => INITIAL_STATE,
  },
});

const { loginStart, loginSuccess, loginFail, clearError, logout } = auth.actions;

const removeCookie = (cookieName: string) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const asyncLogin = (user) => async (dispatch: Function) => {
  try {
    dispatch(loginStart());
    const api = new Api();
    const response = await api.login(user);
    if (response.login_status) {
      dispatch(loginSuccess(response.token));
    } else {
      dispatch(loginFail("Invalid password or email address"));
    }
  } catch (err: any) {
    dispatch(loginFail(err.message));
  }
};

export const loginViaBrandSwith = () => (dispatch: Function) => {
  dispatch(loginSuccess("true"));
};

export const loginViaAuthToken = (token: string) => (dispatch: Function) => {
  dispatch(loginSuccess(token));
};

export const asyncLogout = (navigate?: Function, skipCognito?: boolean) => async (dispatch) => {
  const api = new Api();
  const response = await api.logout();
  dispatch(logout());
  dispatch(clearUserData());
  dispatch(clearCurrentBrandInfo());
  removeCookie("AUTH_COOKIE");

  if (response.isCognitoUser && !skipCognito) {
    let subdomain = window.location.hostname.split(".")[0];
    window.localStorage.setItem("id-auth-replace", subdomain);
    let redirectDomain =
      subdomain.indexOf("staging") !== -1
        ? window.location.origin.replace(subdomain, "id-auth-staging2")
        : window.location.origin.replace(subdomain, "id-auth");
    let redirectUrl = redirectDomain + "/login";

    let hostname = window.location.hostname;
    let splitIndex = hostname.indexOf("id-auth") === 0 ? 7 : hostname.search(/(\.|-)/);
    if (splitIndex < 0) {
      alert("Unsupported Smartocto URL");
    }
    let brandId = hostname.substring(0, splitIndex);

    const setAwsState = (brand_Id) => {
      return btoa(
        JSON.stringify({
          brand_id: brand_Id,
          app: "so",
        }),
      );
    };

    const logoutLink = `https://${process.env.REACT_APP_COGNITO_DOMAIN}/logout?response_type=code&client_id=${
      process.env.REACT_APP_COGNITO_CLIENT_ID
    }&redirect_uri=${redirectUrl}&state=${setAwsState(brandId)}`;
    const logoutCognitoWindow = window.open(logoutLink, "_blank", "nonopener");
    window.setTimeout(()=>{
      logoutCognitoWindow?.close();
    }, 1000)
  } else {
    if (navigate) {
      navigate("/login");
    } else {
      window.location.reload();
    }
  }
};

export const logoutWhenWrongIDPEmail = (brand_Id? : string) => async (dispatch: Function) => {
  const api = new Api();
  const response = await api.logout();
  dispatch(logout());
  dispatch(clearUserData());
  dispatch(clearCurrentBrandInfo());
  removeCookie("AUTH_COOKIE");
  if (response) {
    if (brand_Id) {
      const currentLocation = window.location.origin;
      const newBrandLocation = currentLocation.replace("id-auth", brand_Id);
      window.location.replace(`${newBrandLocation}/login?error=wrongcredentials`);
    } else {
      const currentLocation = window.location.origin;
      window.location.replace(`${currentLocation}/login?error=wrongcredentials`);
    }
  }
};

export const clearErrorState = () => async (dispatch: Function) => {
  dispatch(clearError());
};

export default auth.reducer;
