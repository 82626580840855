import React from "react";
import useTimeTimezoneAdjust from "../../customHooks/useTimeTimezoneAdjust";
import { DateTime } from "luxon";

type SmartoctoAIOutputProps = {
  outputType: string;
  outputRewriterType: string;
  output: any;
  loading: boolean;
};

const SmartoctoAIOutput = ({ outputType, outputRewriterType, output, loading }: SmartoctoAIOutputProps) => {
  const timeTimezoneAdjust = useTimeTimezoneAdjust();

  if (loading) return <></>;

  if (outputType === "headliner") {
    return (
      <div>
        <div className="headline-suggestions">
          <p>
            <b>Headline 1: </b>
            {output.headliner?.alternatives?.alternative01}
          </p>
          <p>
            <b>Headline 2: </b>
            {output.headliner?.alternatives?.alternative02}
          </p>
          <p>
            <b>Headline 3: </b>
            {output.headliner?.alternatives?.alternative03}
          </p>
          <p>
            <b>Headline 4: </b>
            {output.headliner?.alternatives?.alternative04}
          </p>
          <p>
            <b>Headline 5: </b>
            {output.headliner?.alternatives?.alternative05}
          </p>
        </div>
      </div>
    );
  }

  if (outputType === "rephraser") {
    return (
      <div>
        <div className="headline-suggestions">
          <p>
            <b>Paragraph 1: </b>
            {output.rephraser?.alternatives?.paragraph01}
          </p>
          <p>
            <b>Paragraph 2: </b>
            {output.rephraser?.alternatives?.paragraph02}
          </p>
          <p>
            <b>Paragraph 3: </b>
            {output.rephraser?.alternatives?.paragraph03}
          </p>
        </div>
      </div>
    );
  }

  if (outputType === "rewriter") {
    return (
      <div>
        <div className="article-rewriter">
          <p className="response-paragraph">
            <b>Smartocto AI deeply analyzed this story, and this is how it sees it:</b>
          </p>
          <p> {output.rewriter?.analysis} </p>
          <p className="response-paragraph">
            <b>
              To turn this into the <i> {outputRewriterType} </i>
              story you want it to be, these are the steps that you can take:{" "}
            </b>
          </p>
          <p> {output.rewriter?.suggestions} </p>
          <p className="response-paragraph">
            <b>To help you out, let smartocto AI give you some examples here:</b>
          </p>
          <p>
            {output.rewriter?.examples}
            <br />
          </p>
        </div>
      </div>
    );
  }

  if (outputType === "categorizer") {
    return (
      <div>
        <div className="categorizer">
          <p>Always important to have great metadata.</p>
          <p>Smartocto AI analysis found the following categories and entities in this story:</p>
          <p>
            <b>Tags: </b>
            {output.categorizer?.tags}
          </p>
          <p>
            <b>Entities: </b>
            {output.categorizer?.entities?.join(", ")}
          </p>
          <p>
            <b>User need: </b>
            {output.categorizer?.user_need}
          </p>
        </div>
      </div>
    );
  }

  if (outputType === "optimalTime") {
    return (
      <div>
        <div>
          <p>
            According to the smartocto AI, these are the 3 optimal time slots to distribute this story and get the
            attention of the right audience:
          </p>
          <p>
            <b>Time slot 1:</b> {output?.timing?.response?.[0]?.next_24_hours ? null : "Any"}{" "}
            {DateTime.fromJSDate(timeTimezoneAdjust(output?.timing?.response?.[0]?.time || "")).toFormat("cccc, HH:mm")}
          </p>
          <p>
            <b>Time slot 2:</b> {output?.timing?.response?.[1]?.next_24_hours ? null : "Any"}{" "}
            {DateTime.fromJSDate(timeTimezoneAdjust(output?.timing?.response?.[1]?.time || "")).toFormat("cccc, HH:mm")}
          </p>
          <p>
            <b>Time slot 3:</b> {output?.timing?.response?.[2]?.next_24_hours ? null : "Any"}{" "}
            {DateTime.fromJSDate(timeTimezoneAdjust(output?.timing?.response?.[2]?.time || "")).toFormat("cccc, HH:mm")}
          </p>
        </div>
      </div>
    );
  }

  if (outputType === "readability") {
    return (
      <div>
        <div>
          <p>
            The smartocto AI analyzed the readability of this story, and gave it a score of
            <b> {output.readability} </b> out of 100. If you want to make edits, the feature smartocto Tentacles points
            to paragraphs that can be improved (considering read depth and attention time) and smartocto AI can do some
            suggestions.
          </p>
        </div>
      </div>
    );
  }

  if (outputType === "sentiment") {
    return (
      <div>
        <div className="sentiment">
          <p className="response-title">
            Smartocto AI finds the following entities of interest for sentiment analysis:
          </p>
          <ul>
            {output.sentiment.map((item) => {
              return (
                <li style={{ marginBottom: "5px" }} key={item.entity}>
                  <div>
                    <i>entity:</i> <b> {item.entity} </b>
                  </div>
                  <div>
                    <i>score:</i> <b> {item.score.number} </b>
                  </div>
                  <div>
                    <i>explanation:</i> {item.explanation} <br />
                  </div>
                  <div className="horizontal-line"></div>
                </li>
              );
            })}
          </ul>
          <p className="legend">
            <i>
              The system uses the following sentiment scale:
              <br /> -3 (very negative), -2 (negative), -1 (mildly negative), 0 (neutral), 1 (mildly positive), 2
              (positive), 3 (very positive).
            </i>
          </p>
        </div>
      </div>
    );
  }

  if (outputType === "error") {
    return (
      <div>
        <div>
          <p>
            <span className="fa fa-triangle-exclamation output-error-text" title="Output error"></span> Sorry, an error
            occurred. Please try again later.
          </p>
        </div>
      </div>
    );
  }

  return <></>;
};

export default SmartoctoAIOutput;
