import React from "react";
import TableCell from "./TableCell";

type Props = {
  columns: Array<any>;
  data: any;
};

const TableRow = ({ columns, data }: Props) => {
  return (
    <>
      <tr key={`row${data.id}`}>
        {columns.map((e) => {
          return <TableCell key={e.title + data.id}>{data[e.title]}</TableCell>;
        })}
      </tr>
    </>
  );
};

export default TableRow;
