import React from "react";
import { MenuIcon } from "../../icons/Icons";
import { useAppDispatch } from "../../store";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { toggleNavigation } from "../../store/slices/mobile";
import { CloseIcon } from "../../icons/Icons";
import "./MobileNavigation.scss";

type MobileNavigationProps = {
  title: string;
};

const MobileNavigation = ({ title }: MobileNavigationProps) => {
  const dispatch = useAppDispatch();
  const isMobileNavigationShown = useSelector((state: any) => state.mobile.isNavigationOpen);

  return (
    <div className={`mobile-navigation-wrapper ${isMobileNavigationShown ? "menu-shown" : ""}`}>
      <div
        className="mobile-menu-icon"
        onClick={() => {
          dispatch(toggleNavigation());
        }}
      >
        {isMobileNavigationShown ? (
          <div className="close-icon-container">
            <CloseIcon width="25px" height="25px" />
          </div>
        ) : (
          <MenuIcon width="40px" height="40px" />
        )}
      </div>
      <div className="mobile-menu-page-title">{title}</div>
    </div>
  );
};

export default MobileNavigation;
