import React, { useState } from "react";
import "./ToolTip.scss";

const ToolTip = ({ children, message, position = 'bottom', disabled = false }) => {
  const [toolTipShown, setToolTipShown] = useState(false);

  return (
    <span
      className={`tooltip-element tooltip-${position}`}
      onMouseEnter={() => {
        setToolTipShown(true);
      }}
      onMouseLeave={() => {
        setToolTipShown(false);
      }}
    >
      {children}
      {!disabled && toolTipShown && <div className="tooltip-message">{message}</div>}
    </span>
  );
};

export default ToolTip;
