import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import MainNavigation from "../../components/navigation/MainNavigation";
import SideNavigation from "../../components/navigation/SideNavigation";
import Story from "../../components/story/Story";
import { fetchFeedStoriesItems } from "../../store/slices/feed";
import { useParams } from "react-router-dom";
import {
  fetchActiveFeedItem,
  fetchActiveFeedItemCVI,
  fetchActiveFeedItemSegmentCVI,
  fetchActiveFeedItemNumbers,
} from "../../store/slices/activeFeedItem";
import { CircleLoader } from "../../icons/Loaders";
import { getDayNameFromDate } from "../../helpers/dateHelper";
import DataNotAvailable from "../../components/errors/DataNotAvailable";

const StoriesNavigation = ({
  items,
  isLoading,
  searchTerm,
  setSearchTerm,
  selectedFilter,
  setSelectedFilter,
  selectedSections,
  setSelectedSections,
}: {
  items: any;
  isLoading: boolean;
  searchTerm: string;
  setSearchTerm: Function;
  selectedFilter: string;
  setSelectedFilter: Function;
  selectedSections: Array<string>;
  setSelectedSections: Function;
}) => {
  const filters = [
    {
      filter: "Big",
      selected: false,
    },
    {
      filter: "Fast",
      selected: false,
    },
  ];
  const navigate = useNavigate();

  const { id } = useParams();

  const transformedItems = useMemo(() => {
    if (!items) return [];
    let currentDay = "";
    return items.map((e) => {
      const day = getDayNameFromDate(e.pubdate);
      const item = {
        ...e,
        pubDate: e.pubdate,
        postId: e.post_id,
        secondaryPosts: { ...e.secondary_posts },
        type: "secondary",
        paid: e.payedArticle,
      };
      if (day !== currentDay) {
        currentDay = day;
        return {
          ...item,
          day: currentDay,
        };
      }
      return item;
    });
  }, [items]);

  useEffect(() => {
    if (!id && transformedItems[0]?.postId) {
      navigate(`/stories/${transformedItems[0].postId}`);
    }
  }, [id, navigate, transformedItems]);
  return (
    <SideNavigation
      title={"Stories"}
      items={transformedItems}
      filters={filters}
      isLoading={isLoading}
      fetchFunction={fetchFeedStoriesItems}
      hasSearch
      hasSectionFilter
      searchFunction={fetchFeedStoriesItems}
      filtersMessage="Sort stories by"
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      selectedSections={selectedSections}
      setSelectedSections={setSelectedSections}
      page={"stories"}
    />
  );
};

const Stories = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector((state: any) => state.feed.isLoading);
  const items = useSelector((state: any) => state.feed.storiesData?.posts);
  const selectedSegment = useSelector((state: any) => state.activeFeedItem.selectedSegment);
  const [storiesFatchedOnInitialLoad, setStoriesFetchedOnInitialLoad] = useState(false);
  const { dashboardSettings } = useSelector((state: any) => state.currentBrand.data);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedSections, setSelectedSections]: [string[], Function] = useState([]);
  const [searchParams] = useSearchParams();

  const { id } = useParams();

  const filter = searchParams.get("filter");
  const search = searchParams.get("q");
  const rawSections = searchParams.get("sectionFilters")?.split(",") || [];
  const formattedSections = rawSections.map((section) => {
    return "/" + section.replace(" > ", "/");
  });

  // Initial fetching of stories
  useEffect(() => {
    if (!storiesFatchedOnInitialLoad) {
      setSearchTerm(search || "");
      setSelectedFilter(filter || "");
      setSelectedSections(formattedSections);

      dispatch(fetchFeedStoriesItems(filter || "page", search || "", formattedSections));
      setStoriesFetchedOnInitialLoad(true);
    }
  }, [dispatch, search, filter, formattedSections, storiesFatchedOnInitialLoad]);

  // Interval refetching of stories
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchFeedStoriesItems(filter || "page", search || "", formattedSections));
    }, dashboardSettings?.storyListRefreshTime || 60000);

    return () => clearInterval(interval);
  }, [dispatch, dashboardSettings?.storyListRefreshTime, formattedSections, filter, search]);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchActiveFeedItem(id, {}));
    if (selectedSegment.key !== "" && selectedSegment.key !== "No segments selected") {
      dispatch(fetchActiveFeedItemSegmentCVI(id, selectedSegment.key));
    } else {
      dispatch(fetchActiveFeedItemCVI(id));
    }
    dispatch(fetchActiveFeedItemNumbers(id));
  }, [id, dispatch, selectedSegment.key]);

  const pageContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="loader-container">
          <CircleLoader />
        </div>
      );
    }
    if (!items || items === undefined) {
      return <DataNotAvailable pageType="stories" />;
    }
    return <Story type="stories" />;
  }, [isLoading, items]);

  return (
    <div>
      <MainNavigation />
      <div className="container">
        <StoriesNavigation
          items={items}
          isLoading={isLoading}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
        />
        <div className="main-page-container">{pageContent}</div>
      </div>
    </div>
  );
};

export default Stories;
