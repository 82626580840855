import React from "react";

const WeekOfTheYear = () => {
  // Helper function to get week number
  function getWeekNumber(date: Date): number {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const days = Math.floor((date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000));
    return Math.ceil((days + startDate.getDay() + 1) / 7);
  }

  const now: Date = new Date();
  const weekNumber: number = getWeekNumber(now);
  const monthNames: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthName: string = monthNames[now.getMonth()];
  const year: number = now.getFullYear();

  return (
    <span>
      Week {weekNumber - 1}, {monthName} {year}
    </span>
  );
};

export default WeekOfTheYear;
