import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<Object>;
  isLoading: boolean;
  error: Object | null;
};

const aiCredits = createSlice({
  name: "aiCredits",
  initialState: {
    data: [],
    isLoading: true,
    error: null,
  },
  reducers: {
    fetchAICreditsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchAICreditsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchAICreditsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default aiCredits.reducer;

const { fetchAICreditsStart, fetchAICreditsSuccess, fetchAICreditsFailed } = aiCredits.actions;

export const fetchAICredits = () => async (dispatch: Function) => {
  try {
    dispatch(fetchAICreditsStart());
    const api = new Api();
    const result = await api.getAICredits();
    dispatch(fetchAICreditsSuccess(result));
  } catch (err) {
    dispatch(fetchAICreditsFailed(err));
  }
};
