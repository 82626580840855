import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
    isPosting: boolean;
    error: Object | null;
};

const comments = createSlice({
    name: "comments",
    initialState: {
        isPosting: true,
        error: null,
    },
    reducers: {
        createCommentStart: (state: State) => {
            state.isPosting = true;
        },
        createCommentSuccess: (state: State, action) => {
            state.isPosting = false;
        },
        createCommentFailed: (state: State, action) => {
            state.isPosting = false;
            state.error = action.payload;
        },
    },
});

export default comments.reducer;

const { createCommentStart, createCommentSuccess, createCommentFailed } = comments.actions;

export const createComment =
    (postId: string, text: string, dispatchFunction?: Function) => async (dispatch: Function) => {
        try {
            dispatch(createCommentStart());
            const api = new Api();
            const result = await api.postCreateComment(postId, text);
            if (dispatchFunction) {
                dispatch(dispatchFunction(result));
            }
            dispatch(createCommentSuccess(result));
        } catch (err) {
            dispatch(createCommentFailed(err));
        }
    };
