import React, { InputHTMLAttributes } from "react";
import "./Input.scss";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: string;
  className?: string;
  fullWidth?: boolean;
  separateLines?: boolean;
}

const Input: React.FC<InputProps> = ({ id, label, fullWidth = false, separateLines, className, ...restOfProps }) => {
  return (
    <>
      {label ? (
        <label htmlFor={id} style={{ marginRight: 10 }}>
          {label}
        </label>
      ) : null}
      {separateLines ? <div style={{ height: 10 }}></div> : null}
      <input
        id={id}
        className={["so-input", `${fullWidth && "so-input-fullwidth"}`, className].join(" ")}
        {...restOfProps}
      />
      {separateLines ? <div style={{ height: 20 }}></div> : null}
    </>
  );
};

export default Input;
