import React from "react";
import { ArrowIconClosed } from "../../../icons/Icons";

type Props = {
  children: string | number;
  style: any;
  isSortable: boolean;
  onTableSort: Function;
  columnName: string;
  sortBy: string;
};

const TableHeaderCell = ({ children, isSortable, onTableSort, columnName, sortBy, ...restOfProps }: Props) => {
  let linkClass = "order-arrow-none";
  const [sortColumn, order] = sortBy.split(".");
  if (columnName && columnName === sortColumn) {
    if (order === "asc") {
      linkClass = "order-arrow-asc";
    } else {
      linkClass = "order-arrow-desc";
    }
  }
  return (
    <th {...restOfProps}>
      {!isSortable && children}
      {isSortable && (
        <a
          className="header-sortable"
          onClick={(e) => {
            e.preventDefault();
            onTableSort(columnName);
          }}
        >
          <span className="row-title">{children}</span>
          <span className={`dropdown-icon ${linkClass}`}>
            <ArrowIconClosed />
          </span>
        </a>
      )}
    </th>
  );
};

export default TableHeaderCell;
