import React, { useState, useEffect } from "react";
import { useAppDispatch } from "../../../store";
import { useSelector } from "react-redux";
import { createPortal } from "react-dom";
import { v4 as uuidv4 } from "uuid";
import "./Modal.scss";
import { addModalToStack, removeModalFromTheStack } from "../../../store/slices/modalControll";

type Props = {
  isOpen: boolean;
  onClose: Function;
  children: any;
};

const Modal = ({ isOpen, onClose, children }: Props) => {
  const dispatch = useAppDispatch();
  const [domReady, setDomReady] = useState(false);
  const [modalKey, setModalKey] = useState("");

  const modalStack = useSelector((state: any) => state.modalControll.modalStack);

  useEffect(() => {
    setDomReady(true);
  }, []);

  useEffect(() => {
    if (modalKey === "" && isOpen) {
      const key = uuidv4();
      dispatch(addModalToStack(key));
      setModalKey(key);
    }
  }, [isOpen, modalKey, dispatch]);

  useEffect(() => {
    const close = (e) => {
      e.stopPropagation();
      if (e.keyCode === 27 && modalKey === modalStack[modalStack.length - 1]) {
        setModalKey("");
        onClose();
        dispatch(removeModalFromTheStack());
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [modalKey, modalStack, onClose, dispatch]);

  const closeModal = () => {
    dispatch(removeModalFromTheStack());
    setModalKey("");
    onClose();
  };

  return (
    <>
      {isOpen &&
        domReady &&
        createPortal(
          <div className="modal-wrapper" role="dialog">
            <div
              className="modal-header"
              onClick={() => {
                closeModal();
              }}
            >
              X
            </div>
            <div className="modal-body">{children}</div>
          </div>,
          document.getElementById("modal-container") as HTMLElement,
        )}
    </>
  );
};

export default Modal;
