export const timeStampToDate = (timestamp) => {
  const date = new Date(timestamp);
  // const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" });
  return formattedDate;
};

const formatString = (str: string, values: any) => {
  // Check if values are provided
  if (!values || Object.keys(values).length === 0) {
    return str;
  }
  // Format the string
  let formattedStr = str;
  for (const key in values) {
    formattedStr = formattedStr.replace(RegExp(`\\{${key}\\}`, "gi"), values[key]);
  }

  return formattedStr;
};

export const formatDisplayDate = (timestamp: string | number) => {
  const d = new Date(timestamp);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return formatString("{dayName}, {day} {month} {year}, {hour}:{minute}", {
    dayName: d.toLocaleDateString("en-US", { weekday: "long" }),
    month: monthNames[d.getMonth()],
    day: d.getDate(),
    year: d.getFullYear(),
    hour: d.getHours(),
    minute: ("0" + d.getMinutes()).substr(-2),
  });
};

export const getDayNameFromDate = (timestamp: string | number) => {
  const d = new Date(timestamp);
  const dateDay = d.toLocaleDateString("en-US", { weekday: "long" });
  const today = new Date();
  const todaysDay = today.toLocaleDateString("en-US", { weekday: "long" });
  if (todaysDay === dateDay) {
    return "Today";
  } else {
    return dateDay;
  }
};
