import React from "react";

import "./ReportRowWithList.scss";

type ReportRowWithListProps = {
  mainTitle: string;
  title: string;
  items: Array<any>;
  indicators: "arrows" | "dots";
  type: string;
};

const ReportRowWithList = ({ mainTitle, title, items, indicators, type }: ReportRowWithListProps) => {
  return (
    <div className="report-row-with-list-container">
      <div className="subject-title">{mainTitle}</div>
      <div className="report-list-title">{title}</div>

      <div className="report-row-list">
        {items.map((item) => {
          return (
            <div className="list-item">
              <div className="indicators">
                {indicators == "arrows" && <div className="arrow-up green"></div>}
                {indicators == "dots" && <div className="dot green"></div>}
                <span className="green">
                  {item?.number}
                  {indicators == "arrows" ? "%" : " acts"}
                </span>{" "}
                <span className="gray">{type === "daily" ? "yesterday" : "last week"}</span>
              </div>
              <div className="text">{item?.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ReportRowWithList;
